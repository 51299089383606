import React, {useEffect} from 'react'
import {
  Redirect,
  Route as ReactDOMRoute,
  useLocation} from 'react-router-dom'
import {useAuth} from '../hooks/AuthContext'

const Route = ({noAuth = false, isPrivate = false, component: Component, ...rest}) => {
  const {user} = useAuth()

  const { pathname, hash,  } = useLocation();
  
  useEffect(() => {
    // if not a hash link, scroll to top
    // console.log('hash: ',hash)
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname,hash]);
  
  return (
    <ReactDOMRoute 
    {...rest}
    render={()=>{  
      return isPrivate === !!user || noAuth === true ?  (
        <Component />
      ) : (        
        <Redirect to={{pathname: isPrivate ? '/login': '/'}} />
      )
    }}
    />
  )
}

export default Route