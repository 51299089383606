import React from 'react'

import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsappSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

// import brImg from 'assets/img/brazil.png';
// import usImg from 'assets/img/united-states.png';

const InfoBarWrapper = styled.div`
  .navbar {
    background-color: var(--primary-color-10);
    padding: 0 6rem;
    @media only screen and (max-width: 420px) {
      padding: 0 1rem;
    }
    .navbar-nav {
      .navbar-text {
        color: var(--primary-color-7);
        font-size: 14px;
        font-weight: 500;
        margin-right: 2rem;
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
      a.nav-link {
        color: var(--primary-color-7);
        margin-right: 1.25rem;
        img {
          width: 14px;
          height: auto;
        }
      }
    }
  }
`

export default function InfoBar() {
  return (
    <InfoBarWrapper>
      <Navbar className="navbar">
        <Nav className="ml-auto">
          <Navbar.Text>
            (11) 2445-3367
          </Navbar.Text>
          {/* <Navbar.Text>
            31 98877 6655
          </Navbar.Text> */}
          <Navbar.Text>
            contato@crossdo.com.br
          </Navbar.Text>
          {/* <Nav.Link href="#home">
            <img alt="img" src={brImg} />
          </Nav.Link>
          <Nav.Link href="#home">
            <img alt="img" src={usImg} />
          </Nav.Link> */}
          <Nav.Link target="_blank" href="https://api.whatsapp.com/send?phone=5531996323455&text=Entre%20em%20contato%20conosco%20sobre%20o%20CrossDo">
            <FontAwesomeIcon icon={faWhatsappSquare} />
          </Nav.Link>
          <Nav.Link target="_blank" href="https://www.instagram.com/crossdo_/">
            <FontAwesomeIcon icon={faInstagramSquare} />
          </Nav.Link>
          <Nav.Link target="_blank" href="https://www.linkedin.com/company/crossdo/">
            <FontAwesomeIcon icon={faLinkedin} />
          </Nav.Link>
        </Nav>
      </Navbar>
    </InfoBarWrapper>
  )
}
