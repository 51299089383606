import React, {useCallback, useRef} from 'react'
import { MainWraper, Content, Background, Container, Menu, Log, MenuLogin, LoginForm, BContainer, MenuCadastro } from './styles'
import {Link, useHistory} from 'react-router-dom'
import Navbar from './../../patterns/Navbar'
import Input from '../../components/Input'
import {Form} from '@unform/web'
import {useAuth} from '../../hooks/AuthContext'
import {useToast} from '../../hooks/ToastContext'
import getValidationErros from '../../utils/getValidationErrors'

import * as Yup from 'yup'
import Footer from 'components/Footer'


const Login = ()  => {
  const history = useHistory()
  const formRef = useRef(null)
  const {signIn, signOut} = useAuth()
  const { addToast } = useToast()
  
 const handleSubmitLogin = useCallback(async(data) =>{   
    try{
      const schema = Yup.object().shape({
        email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
        password: Yup.string().required('Senha obrigatória'),
      })
      await schema.validate(data,{
        abortEarly:false,
      })
      
      await signIn({
        email: data.email,
        password: data.password
      })   

      addToast({
        type: 'success',
        title: 'Login efetuado com sucesso',        
      })
      
    }catch(err){    

      if(err.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }
      
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro ao fazer login, verifique as credenciais.',
      })
      
    }

 }, [signIn, addToast])



  return (
    <MainWraper>
      <Navbar/>
      <Background>
        <Container>
          <Content>
            <Menu>
              <MenuLogin>
                <Link to="/login">
                <Log >LOGIN</Log>
                </Link>
              </MenuLogin>
              <MenuCadastro>
              <Link to="/cadastrar">
                <Log >CADASTRAR</Log>
              </Link>
              </MenuCadastro>
            </Menu>
            <LoginForm>
              <Form ref={formRef} onSubmit={handleSubmitLogin}>
                <h1>E-mail</h1>
                <Input name="email" placeholder="Informe o e-mail"/>
                <h1>Senha</h1>
                <Input name="password" type="password" placeholder="Informe a senha"/>                
                <Link to="/esqueci-a-senha" style={{color:"#000", width: 120}}>Esqueci a senha</Link>
                <BContainer>
                  <button >Entrar</button>
                  <button onClick={(e)=>{ e.preventDefault(); history.push('/'); signOut() }}>Cancelar</button>
                </BContainer>
              </Form>
            </LoginForm>
            

          </Content>
        </Container>

      </Background>
      <Footer />
    </MainWraper>
  )
}

export default Login;