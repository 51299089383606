import React, { useCallback, useEffect, useState} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import api from '../../../services/api'
import { MainWraper, Container, Title,  SumBox, TagsTitle, FormContainer, Total, Box, BoxTitle, DataContainer, DataContent, BContainer, SubtotalBox } from './styles'
import ParaCima from '../../../assets/icones/EsteLadoParaCima.svg'
import Fragil from '../../../assets/icones/fragil.svg'
import Temperatura from '../../../assets/icones/Temperatura.svg'
import Umbrella from '../../../assets/icones/umbrella.svg'
import AOG from '../../../assets/icones/AOG.svg'
import Footer from '../../../components/Footer'
import {format } from 'date-fns'
import {useAuth} from '../../../hooks/AuthContext'
import useScript from '../../../hooks/useScript'


const Review = (props) =>{
  // useScript("https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js")
  useScript("https://assets.pagar.me/checkout/1.1.0/checkout.js")
  const { token, user } = useAuth() 
  const { go, next } = props.navigation;
  const [isLoading, setIsLoading] = useState(true)

  // const handleCancel = useCallback(async(id) =>{
  //   try{
  //     await api.patch(`/service/checkout-cancelled/${id}`,{},{headers: {Authorization: token}})
  //   }catch(err){

  //   }
  // },[token])

  // const handleSuccess = useCallback(async(id)=>{
  //   try{
  //     await api.patch(`/service/checkout-finished/${id}`,{},{headers: {Authorization: token}})
  //   }catch(err){

  //   }
  // },[token])

  const handlePayment = useCallback(async() =>{
    setIsLoading(true)
    try{
    const data = {
      isValidation: true,
      isBillingThePaymentMethod: false,
      service:{
      clientReference: props.reference,
      checkInDate: props.startDate.toISOString(),
      checkInTime: props.startTime.toISOString(),
      vehicleLicensePlate: props.carPlate,
      driverName: props.driverName,
      dockType: props.dock.value,
      vehicle: props.vehicle.value,
      serviceDuration: Number(props.hiredTime),
      isForkliftIncluded: props.forkLift,
      isPalletTruckIncluded: props.paletTruck,
      plasticPalletsAmount: Number(props.plasticPallet),
      woodPalletsAmount: Number(props.woodenPallet),
      smallCardboardBoxAmount: Number(props.firstBox),
      mediumCardboardBoxAmount: Number(props.secondBox),
      bigCardboardBoxAmount: Number(props.thirdBox),
      dryIceRepositionAmount: Number(props.driedIce),
      upsideTagAmount: Number(props.tagPosition),
      fragileTagAmount: Number(props.tagFragile),
      temperatureTagAmount: Number(props.tagTemperature),
      AOGTagAmount: Number(props.tagAOG),
      umbrellaTagAmount: Number(props.tagUmbrella),
      merchantTag: {
        shipper: {
          name: props.shipperName,
          // contactName: props.contactNameExporter,
          email: props.contactEmailExporter,
          phone: props.contactPhoneExporter,
          country: props.countryExporter,
          cep: props.cepExporter,
          address: props.logradouroExporter,
          neighborhood: props.bairroExporter,
          number: props.numberExporter,
          complement: props.complementExporter,
          // address: props.addressExporter,
          city: props.localidadeExporter,
          state: props.ufExporter
        },
        consignee: {
          name: props.consigneeName,
          // contactName: props.contactNameImporter,
          email: props.contactEmailImporter,
          phone: props.contactPhoneImporter,
          country: props.countryImporter,
          cep: props.cepImporter,
          address: props.logradouroImporter,
          neighborhood: props.bairroImporter,
          number: props.numberImporter,
          complement: props.complementImporter,
          // address: props.addressImporter,
          city: props.localidadeImporter,
          state: props.ufImporter
        },
        amount: Number(props.tagQuantityShip)
      },
      masterAndHouseTag: {
        mawb: ""+ props.mawbNumber,
        hawb: ""+ props.hawbNumber,
        hawbVolume: Number(props.volumeQuantity),
        hawbWeight: Number(props.totalWeight),
        originAirport: props.airportOrigin?.value,
        destinationAirport: props.airportDestiny?.value,
        invoice: ""+ props.invoiceNumber,
        amount: Number(props.tagQuantityMaster)
      }
    },
    }

    const response = await api.post('/service/cross-combo', data, {headers: {Authorization: token}})
    
      const date1 = new Date()
      const date= (new Date(date1.getTime() + 20*60000)).toISOString()
       
      const data2 = data
      data2.isValidation = false
      
      const price = props.prices          
      && (
        Number(props.prices.['Cross Combo'].price)
      + (props.prices.['Cross Combo'].additionalPrice * (props.hiredTime -1))  
      + (props.forkLift && (props.prices.['Forklift'].price * props.hiredTime))  
      + (props.plasticPallet && (props.prices.['Plastic Pallet'].price * Number(props.plasticPallet)))
      + (props.prices.['Wood Pallet'].price * Number(props.woodenPallet))
      + (props.prices.['Small Box'].price * Number(props.firstBox) )
      + (props.prices.['Medium Box'].price * Number(props.secondBox) )
      + (props.prices.['Big Box'].price * Number(props.thirdBox) )
      + (props.prices.['Dry Ice Reposition'].price * Number(props.driedIce) )
      + (props.tagQuantityMaster && props.prices.['Master and House Tag'].price * Number(props.tagQuantityMaster) )
      + (props.prices.['Merchant Tag'].price * Number(props.tagQuantityShip) )
      + (props.prices.['Tags'].price * (Number(props.tagPosition) + Number(props.tagAOG) + Number(props.tagTemperature) + Number(props.tagFragile) + Number(props.tagUmbrella)) )
      ) *100
      
      let payment
      response.data.message === 'IsValidButCantEmitBoleto' ?
       payment = 'pix,credit_card' 
       : payment = 'pix,boleto,credit_card'

      if(response.status === 200){   
        var checkout = await new window.PagarMeCheckout.Checkout({
          encryption_key: 'ek_live_w3AahxYuP9x16rz70lFafuQoVbS1B7',
          payment_methods: payment,
          pix_expiration_date: date,
          success: async(data)=>
            {
              data2.transaction = data
              const resp = await api.post('/service/cross-combo', data2, {headers: {Authorization: token}})
              if(resp.status === 201){              
                props.setTransaction(resp.data.Transaction)
                next()
              }
          }
          ,
          error: (err) => 
            console.log(err)
          ,
          close: function() {
            console.log('The modal has been closed.');
          }
        });   
        checkout.open({
          amount: price,
          createToken: 'false',
          customerData: 'true', 
          pixExpirationDate: date,
          items: [
            {
              id: '1',
              title: 'Cross Combo',
              unit_price: price,
              quantity: 1,
              tangible: false
            },
            
          ]
        })

    }

    // const response = await api.post('/service/cross-combo', data, {headers: {Authorization: token}})
    
    // if(response.status === 201){
    //   // const code = response.data.checkoutCode
    //   // window.PagSeguroLightbox(code, {success: ()=> {handleSuccess(response.data.service.id);next()} ,abort: ()=> handleCancel(response.data.service.id)})
    //   next()
    // }
  }catch(err){

  }finally{
    setTimeout(()=>{        
      setIsLoading(false)
    },3000)
  }

  },[props, token, next, /*handleCancel, handleSuccess*/])
 
  const handleBilling = useCallback(async() =>{
    setIsLoading(true)
    try{
      const data = {
        isBillingThePaymentMethod: true,
        service:{        
        clientReference: props.reference,
        checkInDate: props.startDate.toISOString(),
        checkInTime: props.startTime.toISOString(),
        vehicleLicensePlate: props.carPlate,
        driverName: props.driverName,
        dockType: props.dock.value,
        vehicle: props.vehicle.value,
        serviceDuration: Number(props.hiredTime),
        isForkliftIncluded: props.forkLift,
        isPalletTruckIncluded: props.paletTruck,
        plasticPalletsAmount: Number(props.plasticPallet),
        woodPalletsAmount: Number(props.woodenPallet),
        smallCardboardBoxAmount: Number(props.firstBox),
        mediumCardboardBoxAmount: Number(props.secondBox),
        bigCardboardBoxAmount: Number(props.thirdBox),
        dryIceRepositionAmount: Number(props.driedIce),
        upsideTagAmount: Number(props.tagPosition),
        fragileTagAmount: Number(props.tagFragile),
        temperatureTagAmount: Number(props.tagTemperature),
        AOGTagAmount: Number(props.tagAOG),
        umbrellaTagAmount: Number(props.tagUmbrella),
        merchantTag: {
          shipper: {
            name: props.shipperName,
            // contactName: props.contactNameExporter,
            email: props.contactEmailExporter,
            phone: props.contactPhoneExporter,
            country: props.countryExporter,
            cep: props.cepExporter,
            address: props.logradouroExporter,
            neighborhood: props.bairroExporter,
            number: props.numberExporter,
            complement: props.complementExporter,
            // address: props.addressExporter,
            city: props.localidadeExporter,
            state: props.ufExporter
          },
          consignee: {
            name: props.consigneeName,
            // contactName: props.contactNameImporter,
            email: props.contactEmailImporter,
            phone: props.contactPhoneImporter,
            country: props.countryImporter,
            cep: props.cepImporter,
            address: props.logradouroImporter,
            neighborhood: props.bairroImporter,
            number: props.numberImporter,
            complement: props.complementImporter,
            // address: props.addressImporter,
            city: props.localidadeImporter,
            state: props.ufImporter
          },
          amount: Number(props.tagQuantityShip)
        },
        masterAndHouseTag: {
          mawb: ""+props.mawbNumber,
          hawb: ""+props.hawbNumber,
          hawbVolume: Number(props.volumeQuantity),
          hawbWeight: Number(props.totalWeight),
          originAirport: props.airportOrigin?.value ? props.airportOrigin.value : '',
          destinationAirport: props.airportDestiny?.value ? props.airportDestiny.value : '',
          invoice: ""+props.invoiceNumber,
          amount: Number(props.tagQuantityMaster)
        }
      },
      }

    const response = await api.post('/service/cross-combo', data, {headers: {Authorization: token}})
    
    if(response.status === 201){
      next()
    }
  }catch(err){
  }finally{
    setTimeout(()=>{        
      setIsLoading(false)
    },3000)
  }
  },[props, token, next ])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subtotalTags = useCallback(()=>{

    return props.prices 
    && numberFormat(     
    (props.tagQuantityMaster && props.prices.['Master and House Tag'].price * Number(props.tagQuantityMaster) )
    + (props.prices.['Merchant Tag'].price * Number(props.tagQuantityShip) )
    + (props.prices.['Tags'].price * (Number(props.tagPosition) + Number(props.tagAOG) + Number(props.tagTemperature) + Number(props.tagFragile) + Number(props.tagUmbrella)) )
    )

  },[props])

  const subtotalInformation = useCallback(()=>{

    return props.prices 
    && numberFormat(      
      Number(props.prices.['Cross Combo'].price)
    + (props.prices.['Cross Combo'].additionalPrice * (props.hiredTime -1))  
    
    )

  },[props])

  const subtotalAditional = useCallback(()=>{
    return props.prices 
    && numberFormat(      
    (props.forkLift && (props.prices.['Forklift'].price * props.hiredTime))  
    + (props.plasticPallet && (props.prices.['Plastic Pallet'].price * Number(props.plasticPallet)))
    + (props.prices.['Wood Pallet'].price * Number(props.woodenPallet))
    + (props.prices.['Small Box'].price * Number(props.firstBox) )
    + (props.prices.['Medium Box'].price * Number(props.secondBox) )
    + (props.prices.['Big Box'].price * Number(props.thirdBox) )
    + (props.prices.['Dry Ice Reposition'].price * Number(props.driedIce) )    
    )
  },[props])

  const finalPrice = useCallback(()=>{
    
    return props.prices 
    && numberFormat(
      Number(props.prices.['Cross Combo'].price)
    + (props.prices.['Cross Combo'].additionalPrice * (props.hiredTime -1))  
    + (props.forkLift && (props.prices.['Forklift'].price * props.hiredTime))  
    + (props.plasticPallet && (props.prices.['Plastic Pallet'].price * Number(props.plasticPallet)))
    + (props.prices.['Wood Pallet'].price * Number(props.woodenPallet))
    + (props.prices.['Small Box'].price * Number(props.firstBox) )
    + (props.prices.['Medium Box'].price * Number(props.secondBox) )
    + (props.prices.['Big Box'].price * Number(props.thirdBox) )
    + (props.prices.['Dry Ice Reposition'].price * Number(props.driedIce) )
    + (props.tagQuantityMaster && props.prices.['Master and House Tag'].price * Number(props.tagQuantityMaster) )
    + (props.prices.['Merchant Tag'].price * Number(props.tagQuantityShip) )
    + (props.prices.['Tags'].price * (Number(props.tagPosition) + Number(props.tagAOG) + Number(props.tagTemperature) + Number(props.tagFragile) + Number(props.tagUmbrella)) )
    )
  }, [props])
  
  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Combo"/>
      <Container>
        <Title>Resumo de agendamento</Title>
        {/* <Description>
        Aqui vai algum explicativo, com informações adicionais que julgarem pertinentes. Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
        </Description> */}
        <Total>
          <Title style={{color:'#fff'}}>Valor a Pagar</Title>
          <Title style={{color:'#fff'}}>{finalPrice()}</Title>
        </Total>
        <Title style={{marginTop:20}}>Descrição dos itens agendados</Title>
        <BoxTitle>
          <Title style={{color:'#fff'}}>Informações gerais</Title>
        </BoxTitle>
        <Box>
          <DataContainer>
            <h1>Referência do cliente</h1>
            <h1>{props.reference}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Data de Entrada</h1>
            <h1>{format(props.startDate, 'dd/MM/yyyy')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Horário de entrada</h1>
            <h1>{format(props.startTime, 'HH:mm')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Placa do veículo</h1>
            <h1>{props.carPlate}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nome do motorista</h1>
            <h1>{props.driverName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tipo de doca</h1>
            <h1>{props.dock.label}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Veículo</h1>
            <h1>{props.vehicle.label}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tempo Contratado</h1>
            <h1>{props.hiredTime}</h1>
          </DataContainer>
          <SubtotalBox>
            <h1>Subtotal</h1>
            <h1>{subtotalInformation()}</h1>
          </SubtotalBox> 
          <DataContent/>
          <BContainer>
            <button onClick={()=>go('CCFirstStep')}>Editar</button>
          </BContainer>
        </Box>

        <BoxTitle style={{marginTop:50}}>
          <Title style={{color:'#fff'}}>Adicionais</Title>
        </BoxTitle>
        <Box>
        <DataContainer>
            {props.forkLift && <><h1>Empilhadeira</h1>
             <h1>Até 2500Kg/vol</h1></>}
          </DataContainer>
          <DataContainer>
            {props.paletTruck && <><h1>Paleteira</h1>
              <h1>Até 200Kg/vol</h1></>}
          </DataContainer>  
          <DataContainer style={{alignItems:'self-start'}}>
            <h1>Pallet (envelopado e citado)</h1>
            <h1 style={{display:'flex', flexDirection:'column',alignItems:'flex-end', lineHeight:2}}>
              Plástico - {props.plasticPallet} unidades              
              <span>Madeira fumigada/certificada - {props.woodenPallet} unidades</span>
              </h1>
          </DataContainer>
          <DataContainer style={{alignItems:'self-start'}}>
            <h1>Caixa de papelão</h1>
            <h1 style={{display:'flex', flexDirection:'column',alignItems:'flex-end', lineHeight:2}}>
              32x32x22cm - {props.firstBox} unidades
              <span>50x50x400cm - {props.secondBox} unidades</span>
              <span>60x60x80cm - {props.thirdBox} unidades</span>
            </h1>            
          </DataContainer>
          <DataContainer>
            <h1>Reposição de gelo seco</h1>
            <h1>{props.driedIce} kg</h1>
          </DataContainer>
          <SubtotalBox>
            <h1>Subtotal</h1>
            <h1>{subtotalAditional()}</h1>
          </SubtotalBox>
          <DataContent/>
          <BContainer>
            <button onClick={()=>go('CCSecondStep')}>Editar</button>
          </BContainer>        
        </Box>

        <BoxTitle style={{marginTop:50}}>
          <Title style={{color:'#fff'}}>Etiquetas</Title>
        </BoxTitle>
        <Box>
          <TagsTitle>Manuseio</TagsTitle>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img alt="para cima" src={ParaCima}/>
            <h1 style={{marginBottom:0, marginLeft:8}}>Este lado para cima</h1>
            </div>
            <h1 style={{fontWeight:300}}>{props.tagPosition} unidades</h1>
          </DataContainer>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img alt="para cima" src={Fragil}/>
            <h1 style={{marginBottom:0, marginLeft:8}}>Frágil</h1>
            </div>
            <h1 style={{fontWeight:300}}>{props.tagFragile} unidades</h1>
          </DataContainer>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img alt="para cima" src={Temperatura}/>
            <h1 style={{marginBottom:0, marginLeft:8}}>Temperatura</h1>
            </div>
            <h1 style={{fontWeight:300}}>{props.tagTemperature} unidades</h1>
          </DataContainer>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img style={{height:32,width:32}} alt="evitar umidade" src={AOG}/>
            <h1 style={{marginBottom:0, marginLeft:8, }}>AOG</h1>
            </div>        
            <h1 style={{fontWeight:300}}>{props.tagAOG} unidades</h1>
          </DataContainer>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img style={{height:32,width:32}} alt="evitar umidade" src={Umbrella}/>
            <h1 style={{marginBottom:0, marginLeft:8}}>Evitar umidade</h1>
            </div>
            <h1 style={{fontWeight:300}}>{props.tagUmbrella} unidades</h1>
          </DataContainer>
          <SumBox>
            <h1>Total de etiquetas de manuseio</h1>
            <h1>{parseInt(props.tagPosition) + parseInt(props.tagFragile) + parseInt(props.tagTemperature) + parseInt(props.tagTemperature) + parseInt(props.tagUmbrella)} etiquetas</h1>
          </SumBox>
          
          <TagsTitle>Shipper/Consignee</TagsTitle>
          {/* <DataContainer>
            <h1>Nome do exportador (shipper)</h1>
            <h1>{props.shipperName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>CEP (ZIP Code)</h1>
            <h1>{props.cepExporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Bairro (neighborhood)</h1>
            <h1>{props.bairroExporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Logradouro (street)</h1>
            <h1>{props.logradouroExporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Número (number)</h1>
            <h1>{props.numberExporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Complemento (complement)</h1>
            <h1>{props.complementExporter}</h1>
          </DataContainer>
          
          <DataContainer>
            <h1>Cidade (City)</h1>
            <h1>{props.localidadeExporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>País (Country)</h1>
            <h1>{props.countryExporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>UF (State)</h1>
            <h1>{props.ufExporter}</h1>
          </DataContainer>
          <h1 style={{fontSize:14, fontWeight:'bold', marginTop:14, marginBottom:14}}>Contatos do exportador</h1>
          
          <DataContainer>
            <h1>Telefone</h1>
            <h1>{props.contactPhoneExporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>E-mail</h1>
            <h1>{props.contactEmailExporter}</h1>
          </DataContainer>
          <div style={{marginBottom:40}}/>
          <DataContainer>
            <h1>Nome do importador (consignee)</h1>
            <h1>{props.consigneeName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>CEP (ZIP Code)</h1>
            <h1>{props.cepImporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Bairro (neighborhood)</h1>
            <h1>{props.bairroImporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Logradouro (street)</h1>
            <h1>{props.logradouroImporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Número (number)</h1>
            <h1>{props.numberImporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Complemento (complement)</h1>
            <h1>{props.complementImporter}</h1>
          </DataContainer>
          
          <DataContainer>
            <h1>Cidade (City)</h1>
            <h1>{props.localidadeImporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>País (Country)</h1>
            <h1>{props.countryImporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>UF (State)</h1>
            <h1>{props.ufImporter}</h1>
          </DataContainer>
          <h1 style={{fontSize:14, fontWeight:'bold', marginTop:14, marginBottom:14}}>Contatos do importador</h1>
          
          <DataContainer>
            <h1>Telefone</h1>
            <h1>{props.contactPhoneImporter}</h1>
          </DataContainer>
          <DataContainer>
            <h1>E-mail</h1>
            <h1>{props.contactEmailImporter}</h1>
          </DataContainer> */}
          <DataContainer>
            <div>
              <table style={{borderWidth:1, borderColor:'black', borderStyle:'solid'}}>
                <tr style={{borderWidth:1, borderColor:'black', borderStyle:'solid'}}>
                  <td style={{borderWidth:1, borderColor:'black', borderStyle:'solid', padding:10, fontWeight:'bold'}}>Shipper</td>
                  <td style={{borderWidth:1, borderColor:'black', borderStyle:'solid',padding:10, fontWeight:'bold'}}>Consignee</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{ paddingInline:10}}>{props.shipperName}</td>
                  <td style={{borderLeft:1, borderLeftColor:'black', borderLeftStyle:'solid', paddingInline:10}}>{props.consigneeName}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>PHONE: {props.contactPhoneExporter}</td>
                  <td style={{borderLeft:1, borderLeftColor:'black', borderLeftStyle:'solid', paddingInline:10}}>PHONE: {props.contactPhoneImporter}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>{props.logradouroExporter}</td>
                  <td style={{borderLeft:1, borderLeftColor:'black', borderLeftStyle:'solid', paddingInline:10}}>{props.logradouroImporter}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>{`NRO.: ${props.numberExporter} - COMP.: ${props.complementExporter}`}</td>
                  <td style={{borderLeft:1, borderLeftColor:'black', borderLeftStyle:'solid', paddingInline:10}}>{`NRO.: ${props.numberImporter} - COMP.: ${props.complementImporter}`}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>BAIRRO: {props.bairroExporter}</td>
                  <td style={{borderLeft:1, borderLeftColor:'black', borderLeftStyle:'solid', paddingInline:10}}>BAIRRO: {props.bairroImporter}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>ZIPCODE: {props.cepExporter}</td>
                  <td style={{borderLeft:1, borderLeftColor:'black', borderLeftStyle:'solid', paddingInline:10}}>ZIPCODE: {props.cepImporter}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>{props.localidadeExporter + ', '+props.ufExporter + ' - ' + props.countryExporter }</td>
                  <td style={{borderLeft:1, borderLeftColor:'black', borderLeftStyle:'solid', paddingInline:10}}>{props.localidadeImporter + ', '+props.ufImporter + ' - ' + props.countryImporter }</td>
                </tr>
              </table>
            </div>
          </DataContainer>
          <SumBox>
            <h1>Total de etiquetas de Shipper/Consignee</h1>
            <h1>{parseInt(props.tagQuantityShip)} etiquetas</h1>
          </SumBox>
          <TagsTitle>Master e House</TagsTitle>
          {/* <DataContainer>
            <h1>Nº MAWB</h1>
            <h1>{props.mawbNumber}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nº HAWB</h1>
            <h1>{props.hawbNumber}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Aeroporto de origem</h1>
            <h1>{props.airportOrigin.label}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Aeroporto de destino</h1>
            <h1>{props.airportDestiny.label}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Quantidade de volume final (em unidades do HAWB)</h1>
            <h1>{props.volumeQuantity}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Peso bruto total (Em Kg do HAWB)</h1>
            <h1>{props.totalWeight}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nº da Invoice - INV (opcional)</h1>
            <h1>{props.invoiceNumber}</h1>
          </DataContainer> */}
          <DataContainer>
            <div>
              <table style={{borderWidth:1, borderColor:'black', borderStyle:'solid'}}>
                <tr style={{borderWidth:1, borderColor:'black', borderStyle:'solid'}}>
                  <td style={{borderWidth:1, borderColor:'black', borderStyle:'solid', padding:10, fontWeight:'bold'}}>Master e House</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{ paddingInline:10}}>MAWB: {props.mawbNumber}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>HAWB: {props.hawbNumber}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>ORIGIN: {props.airportOrigin?.label?.substring(props.airportOrigin?.label.lastIndexOf('(') +1,props.airportOrigin?.label.lastIndexOf(')'))}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>DESTINATION: {props.airportDestiny?.label?.substring(props.airportDestiny?.label.lastIndexOf('(') +1,props.airportDestiny?.label.lastIndexOf(')'))}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>GROSS WEIGHT: {props.totalWeight} {props.totalWeight && `Kg`}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>VOLUMS: {props.volumeQuantity}</td>
                </tr>
                <tr style={{ fontSize:12, fontWeight:'bold'}}>
                  <td style={{paddingInline:10}}>INV: {props.invoiceNumber}</td>
                </tr>                
              </table>
            </div>
          </DataContainer>
          <SumBox>
            <h1>Total de etiquetas Master e House</h1>
            <h1>{parseInt(props.tagQuantityMaster)} etiquetas</h1>
          </SumBox>

          <DataContainer style={{paddingInline:32}}>
            <h1>Total geral de etiquetas</h1>
            <h1 
              style={{fontWeight:'bold'}}
            >
              {parseInt(props.tagQuantityShip) 
              + parseInt(props.tagQuantityMaster) 
              + parseInt(props.tagPosition) 
              + parseInt(props.tagFragile) 
              + parseInt(props.tagTemperature)
              + parseInt(props.tagAOG)
              + parseInt(props.tagUmbrella)} etiquetas
            </h1>
          </DataContainer>
          <SubtotalBox>
            <h1>Subtotal</h1>
            <h1>{subtotalTags()}</h1>
          </SubtotalBox>
          <DataContent/>
          <BContainer>
            <button onClick={()=>go('CCThirdStep')}>Editar</button>
          </BContainer>
        </Box>
        <Total style={{height:70, marginTop:40}}>          
          <div style={{display:'flex',flexDirection:'row', alignSelf:'center' }}>
            <input style={{marginTop:2}} onChange={()=>setIsLoading((state)=>!state)} type="checkbox"></input>
            <Title style={{color:'#fff', marginLeft: 8, textAlign:'center'}}>Li e confirmo os itens agendados</Title>
          </div>
          <div>
          {user.isBillingAllowed && <button disabled={isLoading} onClick={()=>handleBilling()}>Faturamento</button>}
          <button style={{marginLeft:10}} disabled={isLoading} onClick={()=>handlePayment()}>Pagamento</button>
          </div>
        </Total>
        <div style={{fontWeight:'bold', display:'flex', justifyContent:'flex-end', marginTop:12}}>
          O agendamento será realizado a partir da confirmação de pagamento, que será enviado um link para o e-mail cadastrado.
        </div>
        <FormContainer>
          
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default Review;