import styled from 'styled-components'
//import { shade } from 'polished'

export const MainWraper = styled.div`
  height: 100vh;
  width:100vw;
  /* min-width: 100vw; */
  margin: 0;
  padding: 0;  
`
export const Container = styled.div`
  display:flex; 
  flex-direction: column;
  flex:1;
  /* width: 100vw; */
  /* height:100vh; */
  padding: 10.5rem 10.5rem 4.5rem 10.5rem;
  @media only screen and (max-width: 922px) {
      padding: 10.5rem 1rem;     
  }
`
export const Box = styled.div`
  display:flex;
  flex-direction:column;
  border: 1px solid #D4D1DA;
  border-radius: 10px;
  align-self: center; 
  width:45vw;  
  align-items: center;

`
export const Icon = styled.div`
  margin-top: 42px;
`

export const Title = styled.div`
  margin-top: 24px;

  h1{
    font-size: 24px;
    font-weight: bold;
  }

  h2{
    font-size: 16px;
    font-weight: bold;
  }

`

export const Description = styled.div`
  margin-top: 8px;
  margin-bottom: 42px;
  

  h1{
    text-align: center;
    font-size: 16px;
    font-weight: 300;
  }
`