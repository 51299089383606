import React, {useState, useEffect} from 'react'
import { useStep } from 'react-hooks-helper'
import FirstStep from './FirstStep/index'
import SecondStep from './SecondStep'
import Review from './Review'
import SchedulingConfirmation from './SchedulingConfirmation'
import ThirdStep from './ThirdStep'
import api from 'services/api'


const steps = [
  { id: 'CCFirstStep'},
  { id: 'CCSecondStep'},
  { id: 'CCThirdStep'},
  { id: 'CCReview'},
  { id: 'CCConfirmation'}  
]

const CComboMultiStep = ({images}) =>{
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate()+1)

  const [prices, setPrices] = useState()

  // function roundToHour(date) {
  //   const p = 60 * 60 * 1000; // milliseconds in an hour
  //   return new Date(Math.round(date.getTime() / p ) * p);
  // }

  useEffect(()=>{
    async function fetchPrices(){
      try{        
        const {data:{data}} = await api.get('/prices')
        let a = data.reduce((obj, item) => ({...obj, [Object.keys(item)[1]]: item.[Object.keys(item)[1]]}), {})
        if(data)
        setPrices(a)
      }catch(err){}
    }
    fetchPrices()
  },[])
  
  const [reference, setReference] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  // const [startTime, setStartTime] = useState(roundToHour(new Date()))
  const [carPlate, setCarPlate] = useState('')
  const [driverName, setDiverName] = useState('')
  const [hiredTime, setHiredTime] = useState('')
  const [dock, setDock] = useState('')
  const [vehicle, setVehicle] = useState('')

  const [forkLift, setForkLift] = useState(false)
  const [paletTruck, setPaletTruck] = useState(false)
  const [plasticPallet, setPlasticPallet] = useState('0')
  const [woodenPallet, setWoodenPallet] = useState('0')
  const [firstBox, setFirstBox] = useState('0')
  const [secondBox, setSecondBox] = useState('0')
  const [thirdBox, setThirdBox] = useState('0')
  const [driedIce, setDriedIce] = useState('0')

  const [tagPosition, setTagPosition] = useState('0')
  const [tagFragile, setTagFragile] = useState('0')
  const [tagTemperature, setTagTemperature] = useState('0')
  const [tagAOG, setTagAOG] = useState('0')
  const [tagUmbrella, setTagUmbrella] = useState('0')


  const [shipperName, setShipperName] = useState('')
  const [cepExporter, setCepExporter] = useState('')
  const [logradouroExporter, setLogradouroExporter] = useState('')
  const [bairroExporter, setBairroExporter] = useState('')
  const [numberExporter, setNumberExporter] = useState('')
  const [complementExporter, setComplementExporter] = useState('')
  const [addressExporter, setAddressExporter] = useState('')
  const [localidadeExporter, setLocalidadeExporter] = useState('')
  const [ufExporter,setUfExporter] = useState('')
  const [countryExporter, setCountryExporter] = useState('')
  const [contactNameExporter, setContactNameExporter] = useState('')
  const [contactPhoneExporter, setContactPhoneExporter] = useState('')
  const [contactEmailExporter, setContactEmailExporter] = useState('')

  const [consigneeName, setConsigneeName] = useState('')
  const [cepImporter, setCepImporter] = useState('')
  const [logradouroImporter, setLogradouroImporter] = useState('')
  const [bairroImporter, setBairroImporter] = useState('')
  const [numberImporter, setNumberImporter] = useState('')
  const [complementImporter, setComplementImporter] = useState('')
  const [localidadeImporter, setLocalidadeImporter] = useState('')
  const [ufImporter,setUfImporter] = useState('')
  const [addressImporter, setAddressImporter] = useState('')
  const [countryImporter, setCountryImporter] = useState('')
  const [contactNameImporter, setContactNameImporter] = useState('')
  const [contactPhoneImporter, setContactPhoneImporter] = useState('')
  const [contactEmailImporter, setContactEmailImporter] = useState('')
  const [tagQuantityShip, setTagQuantityShip] = useState('0')

  const [mawbNumber, setMawbNumber] = useState('')
  const [hawbNumber, setHawbNumber] = useState('')
  const [airportOrigin, setAirportOrigin] = useState('')
  const [airportDestiny, setAirportDestiny] = useState('')
  const [volumeQuantity, setVolumeQuantity] = useState('')
  const [totalWeight, setTotalWeight] = useState('')
  const [invoiceNumber,setInvoiceNumber] = useState('')
  const [tagQuantityMaster, setTagQuantityMaster] = useState('0')
  const [openColapse, setOpenColapse] = useState('')

  const [transaction, setTransaction] = useState({})

  const {step, navigation} = useStep({ initialStep: 0, steps })
  const {id} = step
  
  const props = {  
    navigation,
    prices,
    reference, setReference,
    startDate, setStartDate,
    startTime, setStartTime,
    carPlate, setCarPlate,
    driverName, setDiverName,
    hiredTime, setHiredTime,
    vehicle, setVehicle,
    dock, setDock,
    forkLift, setForkLift,
    paletTruck, setPaletTruck,
    plasticPallet, setPlasticPallet,
    woodenPallet, setWoodenPallet,
    firstBox, setFirstBox,
    secondBox, setSecondBox,
    thirdBox, setThirdBox,
    driedIce, setDriedIce,
    
    tagPosition, setTagPosition,
    tagFragile, setTagFragile,
    tagTemperature, setTagTemperature,
    tagAOG, setTagAOG,
    tagUmbrella, setTagUmbrella,

    shipperName, setShipperName,
    cepExporter, setCepExporter,
    logradouroExporter, setLogradouroExporter,
    bairroExporter, setBairroExporter,
    numberExporter, setNumberExporter,
    complementExporter, setComplementExporter,
    addressExporter, setAddressExporter,
    localidadeExporter, setLocalidadeExporter,
    ufExporter,setUfExporter,
    countryExporter, setCountryExporter,
    contactNameExporter, setContactNameExporter,
    contactPhoneExporter, setContactPhoneExporter,
    contactEmailExporter, setContactEmailExporter,

    consigneeName, setConsigneeName,
    cepImporter, setCepImporter,
    logradouroImporter, setLogradouroImporter,
    bairroImporter, setBairroImporter,
    numberImporter, setNumberImporter,
    complementImporter, setComplementImporter,
    localidadeImporter, setLocalidadeImporter,
    ufImporter,setUfImporter,
    addressImporter, setAddressImporter,
    countryImporter, setCountryImporter,
    contactNameImporter, setContactNameImporter,
    contactPhoneImporter, setContactPhoneImporter,
    contactEmailImporter, setContactEmailImporter,
    tagQuantityShip, setTagQuantityShip,

    mawbNumber, setMawbNumber,
    hawbNumber, setHawbNumber,
    airportOrigin, setAirportOrigin,
    airportDestiny, setAirportDestiny,
    volumeQuantity, setVolumeQuantity,
    totalWeight, setTotalWeight,
    invoiceNumber,setInvoiceNumber,
    tagQuantityMaster, setTagQuantityMaster,

    openColapse, setOpenColapse,

    transaction, setTransaction

  }

  switch(id){
    case 'CCFirstStep':
      return <FirstStep {...props}/>
    case 'CCSecondStep':
      return <SecondStep {...props}/>
    case 'CCThirdStep':
      return <ThirdStep {...props}/>
    case 'CCReview':
      return <Review {...props} />
    case 'CCConfirmation':
      return <SchedulingConfirmation {...props} />
    default:
      return null
  }
}
export default CComboMultiStep