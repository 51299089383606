import styled from 'styled-components'

export const MainWraper = styled.div`  
  width: 100vw;
  .navbar {
    background-color: var(--primary-color-5) !important;
    top: 65px;
    padding: .45rem 10.5rem;
    max-width: 100vw;
    box-shadow: 0px 2px 12px #00000029;
    z-index:1;
    @media only screen and (max-width: 600px) {
      padding: .25rem 1rem;
    }
  }
  #basic-navbar-nav {
    @media only screen and (max-width: 992px) {
      // border-bottom: 1px solid white;
      * {
        font-size: 1rem;
      }
    }
  }
`

export const TitleBar = styled.div`
  display:flex;
  height: 40px;
  
  top:40px;
  width: 100vw;
  flex:1;
  background-color: orange;
`