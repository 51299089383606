import React from 'react'
import styled from 'styled-components'

import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

import rightContentImage from './../assets/img/Banner-img.jpg';
import leftContentImage from './../assets/img/Logo-banner.png';
import { useHistory } from 'react-router-dom';

const Section1Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-7);
  margin: 0;
  align-items: center;

  .left-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--primary-color-10);
    padding: 2rem 5vw 1rem 5vw;
    @media only screen and (max-width: 992px) {
      align-items: center;
    }
    img {
      @media only screen and (max-width: 600px) {
        max-width: 200px;
      }
    }
    span {
      font-size: 20px;
      font-weight: 300;
      padding: 32px 0;
      max-width: 450px;
      text-align: justify;
      @media only screen and (max-width: 992px) {
        max-width: 90vw;
      }
      @media only screen and (max-width: 600px) {
        text-align: center;
        font-size: 16px;
      }
    }
    button {
      background-color: var(--primary-color-5);
      padding: .5rem 3rem;
      border-color: var(--primary-color-5);
      transition: .25s ease-in-out;
      &:hover {
        color: var(--primary-color-10);
        border-color: var(--primary-color-10);
      }
    }
  }
  .right-content {
    background-image: url('./../assets/img/Banner-img.jpg');
    background-size: cover;
    padding: 0;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

`

export default function Section1() {
  const history = useHistory()
  return (
    <Section1Wrapper className="row" data-aos="slide-down">
      <div className="col-lg-5 col-sm-12 left-content">
        <Image src={leftContentImage} />
        <span>Somos a primeira plataforma de CO-WORKING para compartilhamento de armazém do Brasil, para operações de manuseio de carga e Cross Docking, o que facilita a logística das exportações.</span>
        <Button onClick={()=>history.push('/planos')} variant="outline-light">Saiba mais</Button>
      </div>
      <div className="col-lg-7 col-sm-12 right-content">
        <Image src={rightContentImage} fluid />
      </div>
    </Section1Wrapper>
  )
}
