import React, {useRef, useCallback, useEffect} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import {Form} from '@unform/web'
import Input from '../../../components/Input'
// import DateInput from '../../../components/DatePicker/index'
// import SelectInput from '../../../components/SelectInput/index'
import { MainWraper, Container, Title, FormContainer, Group, Individual, BContainer, GroupB } from './styles'
import Footer from '../../../components/Footer'
import getValidationErros from '../../../utils/getValidationErrors'
// import {useHistory} from 'react-router-dom'

import * as Yup from 'yup'




const SecondStep = (props) =>{
  
  // const history = useHistory()
  const formRef = useRef(null)
  
  
  // eslint-disable-next-line 
  const { next, previous } = props.navigation;

  const handleSubmitEtapa2 = useCallback(async(data) =>{    
    try{     
      
      const schema = Yup.object().shape({
        forkLift: Yup.bool(),
        paletTruck: Yup.bool(),
      })
      
      await schema.validate(data,{
        abortEarly:false,
      })      
      next()

    }catch(err){         
      if(err.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }      
    }
  },[next])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Park"/>
      <Container>
        <Title> Etapa 2 de 2 - Adicionais </Title>
        {/* <Description>
          Os equipamentos e materiais necessários deverão ser previamente reservados no seu kit. Sendo assim, façam de forma planejada para não haver problemas. Todos os campos são obrigatórios, exceto aqueles informados como opcionais.
        </Description> */}
        <Title style={{marginTop:20}}>Selecione abaixo os itens que deseja adicionar para compor o seu agendamento:</Title>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmitEtapa2}>
            <h1><strong>Empilhadeira</strong> ({numberFormat(props.prices.['Forklift'].price)}/hora)</h1>
            <Group>
              <h1>Até 2500kg/vol</h1>
              <Input name="forkLift" value={props.forkLift} checked={props.forkLift} onChange={()=>props.setForkLift(!props.forkLift)} type="checkbox" />            
            </Group>
            <h1><strong>Paleteira</strong> (cortesia)</h1>
            <Group>
              <h1>Até 200kg/vol</h1>
              <Input name="paletTruck" checked={props.paletTruck} value={props.paletTruck} onChange={()=>props.setPaletTruck(!props.paletTruck)}   type="checkbox" />            
            </Group>
            <GroupB>
                <Individual>

                </Individual>
                <BContainer>
                  <button onClick={(e)=>{e.preventDefault();previous()}}>Voltar</button>
                  <button >Avançar</button>
                </BContainer>
              </GroupB>
          </Form>
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default SecondStep;