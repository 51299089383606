import React from 'react'

import styled from 'styled-components';
import Navbar from './../patterns/Navbar'
import Section1 from './../components/Section1';
import Section2 from './../components/Section2';
import Section3 from './../components/Section3';
import Section4 from './../components/Section4';
import Section5 from './../components/Section5';
import Section6 from './../components/Section6';
import Footer from './../components/Footer';
// import {useAuth} from '../hooks/AuthContext'
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  `
  const MainContent = styled.main`
  margin-top: 105px;
`
export default function Home() {
 
  return (
    <MainWrapper>
      <Navbar />
    
      <MainContent>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
      </MainContent>
      <Footer />
    </MainWrapper>
  )
}
