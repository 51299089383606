import React from 'react'

import styled from 'styled-components';

import Navbar from './../patterns/Navbar'
import Section7 from './../components/Section7';
import Section8 from './../components/Section8';
import Footer from './../components/Footer';

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`

const MainContent = styled.main`
  margin-top: 105px;
`
export default function Plans() {
  return (
    <MainWrapper>
      <Navbar />
      <MainContent>
        <Section7 />
        <Section8 />
      </MainContent>
      <Footer />
    </MainWrapper>
  )
}
