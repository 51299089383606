import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import GruAirport from '../assets/img/GruAirport.png'
import goldMedal from './../assets/img/gold-medal.png'
import { Link } from 'react-router-dom';


const Section8Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-10);
  margin: 0;
  align-items: center;
`

const SectionContent = styled.section`
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-10);
  margin: 0;
  align-items: center;
  padding: 2.5vh 0 5vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (max-width: 992px) {
    padding: 2rem;
  }
`
const MainTitle = styled.span`
  width: 100%;
  font-size: 40px;
  color: var(--primary-color-7);
  font-weight: 700;
  text-align: center;
  padding: 7.5vh 1rem 5vh;
  line-height: 1;
  @media only screen and (max-width: 992px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
`

const PlanWrapper = styled.div`
  background-color: var(--primary-color-9);
  height: 100%;
  padding: 0;
  margin: 1rem;
  max-width: 500px;
  @media only screen and (max-width: 768px) {
    align-items: center;
    max-width: 90%;
    margin: 1rem 0;
  }
  .header {
    background-color: var(--primary-color-4);
    color: var(--primary-color-10);
    padding: .75rem;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    span {
      font-size: 32px;
      font-weight: 400;
    }
  }
  .better {
    background-color: var(--primary-color-1);
    color: var(--primary-color-10);
    text-align: center;
    padding: .25rem;
    span {
      font-size: 14px;
      font-weight: 300;
    }
    img {
      position: absolute;
      top: -1rem;
      right: .1rem;
      width: 48px;
      height: auto;
    }
  }
  .body1 {
    padding: 1rem 2rem;
  }
  .body2 {
    border-top: 1px solid #E3E1E7;
    border-bottom: 1px solid #E3E1E7;
    margin: 0 2rem;
    padding: 1rem;
    text-align: center;
    .title {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .body3 {
    /* border-top: 1px solid #E3E1E7; */
    border-bottom: 1px solid #E3E1E7;
    margin: 0 2rem;
    padding: 0 1rem;
    text-align: center;
  }
  .footer {
    padding: 2rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    button {
      background-color: var(--primary-color-5);
      padding: .5rem 3rem;
      border-color: var(--primary-color-5);
      transition: .25s ease-in-out;
      &:hover {
        color: var(--primary-color-10);
        border-color: var(--primary-color-10);
      }
    }
  }
  .gruWarper{
    display: flex;
    width:'100%';
    background-color: '#000';
    justify-content: center;
    align-items: center;
  }
`

const PlanList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    color: var(--primary-color-3);
    padding-bottom: .25rem;
    span {
      color: var(--primary-color-11);
      margin-left: .5rem;
      font-size: 14px;
      font-weight: 300;
    }
}`


const PlanDetails = styled.ul`
  padding: 1rem 0 0;
  list-style-type: none;
  margin: 0;
  li {
    color: var(--primary-color-11);
    padding-bottom: .25rem;
    text-align: center;
    span {
      font-size: 14px;
      font-weight: 300;
    }
}`

const listItems = [
  [
    "Compartilhamento da estrutura física CrossDo.",
    "Contratação por tempo de permanência.",
    "Disponibilidade de 2 tipos de docas: Doca alta e rampa.",
    "Serviço de empilhadeira disponível.",
    "Total controle das informações do embarque.",
  ],
  [
    "Compartilhamento da estrutura física CrossDo.",
    "Contratação por tempo de permanência.",
    "Disponibilidade de 2 tipos de docas: Doca alta e rampa.",
    "Serviço de empilhadeira disponível.",
    "Total controle das informações do embarque."
  ],
  [
    "Compartilhamento da estrutura física CrossDo.",
    "Contratação de serviço completo Cross Care - padrão OEA.",
    "Entrega no TECA Exportação para embarque.",
    "2 horários de agendamento garantidos junto à GRU AIRPORT.",
  ],
  [
    "Canal direto Teca Exportação GRU Airport.",
    "Presença de Carga CrossDo.",
    "Infra Estrutura CrossDo."
  ]  
]

const listDetails = [
  [],
  [
    "- Pallet de plástico ou madeira tratada e certificada.",
    "- Caixas de papelão.",
    "- Reposição de gelo seco.",
    "- Impressão de etiquetas.",
    "- Equipamento para cintamento.",
    "- Equipamento e plástico filme para envelopamento."
  ],  [
    "- Manuseio.",
    "- Etiquetagem.",
    "- Paletização.",
    "- Repesagem.",
    "- Separação.",
    "- Relatórios personalizados com fotos do serviço iniciado e finalizado."
  ]
]

export default function Section8() {

  return (
    <Section8Wrapper data-aos="fade-up">
      <SectionContent className="row">
        <MainTitle>Escolha o melhor plano para você</MainTitle>

        <PlanWrapper className="col-md-12 col-lg-3" data-aos="slide-up" data-aos-duration="1000">
          <div className="header">
            <span>Cross PARK</span>
          </div>
          {/* <div className="better">
            <span>MAIS CONTRATADO</span>
            <img src={goldMedal} />
          </div> */}
          <div className="body1">
            <PlanList>
              { listItems[0]?.map(item => (
                <li key={uuidv4()}>
                  <FontAwesomeIcon size="1x" icon={faCheckCircle} />
                  <span>{item}</span>
                </li>
              ))}
            </PlanList>
          </div>
          {/* <div className="body2">
            <span className="title">Materiais disponíveis</span>
            <PlanDetails>
              { listDetails[0]?.map(item => (
                <li key={uuidv4()}>
                  <span>{item}</span>
                </li>
              ))}
            </PlanDetails>
          </div> */}
          <div className="footer">
            <Link to="/contratar/cross-park">
              <Button variant="outline-light">Contratar</Button>
            </Link>
          </div>
        </PlanWrapper>

        <PlanWrapper className="col-md-12 col-lg-3" data-aos="zoom-in-up" data-aos-duration="1500" data-aos-delay="500">
          <div className="header">
            <span>Cross COMBO</span>
          </div>
          <div className="better">
            <span>MAIS CONTRATADO</span>
            <img alt="crossdo" src={goldMedal} />
          </div>
          <div className="body1">
            <PlanList>
              { listItems[1]?.map(item => (
                <li key={uuidv4()}>
                  <FontAwesomeIcon size="1x" icon={faCheckCircle} />
                  <span>{item}</span>
                </li>
              ))}
            </PlanList>
          </div>
          <div className="body2">
            <span className="title">Materiais disponíveis</span>
            <PlanDetails>
              { listDetails[1]?.map(item => (
                <li key={uuidv4()}>
                  <span>{item}</span>
                </li>
              ))}
            </PlanDetails>
          </div>
          <div className="footer">
            <Link to="/contratar/cross-combo" >
              <Button variant="outline-light">Contratar</Button>
            </Link>
          </div>
        </PlanWrapper>


        
        <PlanWrapper className="col-md-12 col-lg-3" data-aos="slide-up" data-aos-duration="2000">
          <div className="header">
            <span>Cross CARE</span>
          </div>
          {/* <div className="better">
            <span>MAIS CONTRATADO</span>
            <img src={goldMedal} />
          </div> */}
          <div className="body1">
            <PlanList>
              { listItems[2]?.map(item => (
                <li key={uuidv4()}>
                  <FontAwesomeIcon size="1x" icon={faCheckCircle} />
                  <span>{item}</span>
                </li>
              ))}
            </PlanList>
          </div>
          <div className="body2">
            <span className="title">Serviços oferecidos</span>
            <PlanDetails>
              { listDetails[2]?.map(item => (
                <li key={uuidv4()}>
                  <span>{item}</span>
                </li>
              ))}
            </PlanDetails>
          </div>
          <div className="footer">
            <Link to="/contratar/cross-full-care">
              <Button variant="outline-light">Contratar</Button>
            </Link>
          </div>
        </PlanWrapper>        
      </SectionContent>
      {/* <div style={{display:'flex', justifyContent:'center', paddingTop:20, paddingBottom:20, backgroundColor: '#11274A'}} className="gruWarper">
        <PlanWrapper style={{borderRadius:5}} className="col-md-12 col-lg-3" data-aos="slide-up" data-aos-duration="1000">
          <div className="header" style={{position: 'relative', backgroundColor:'white',  borderColor:'#11274A', borderBottom:3, borderBottomStyle: 'solid'}}>
            <span style={{color:'#11274A', fontWeight:'bold'}}>GRU Express</span>
            <img style={{width:100, height:'auto', position:'absolute', marginTop:'2.7rem', right:0 }} alt="gru" src={GruAirport}/>
          </div>
          
          <div style={{ borderColor:'#11274A', borderTop:3, borderTopStyle: 'solid'}} className="body1">
            <PlanList style={{marginTop:10}}>
              { listItems[3]?.map(item => (
                <li key={uuidv4()}>
                  <FontAwesomeIcon size="1x" icon={faCheckCircle} />
                  <span>{item}</span>
                </li>
              ))}
            </PlanList>
          </div>
          <div className="body3">
            
          </div>
          <div className="footer">
            <Link to="/contratar/cross-gru-express">
              <Button variant="outline-light">Contratar</Button>
            </Link>
          </div>
        </PlanWrapper>
        </div> */}

    </Section8Wrapper>
  )
}
