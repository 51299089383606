import styled from 'styled-components'
// import BackgImg from '../../assets/cadastrar/Park.jpg'
// import { shade } from 'polished'


export const MainWraper = styled.div`
  height:100hv;
  width: 100vw;
`
export const Container = styled.div`
  display: flex;  
  width: 100vw;
  background-color: #fff;
  padding: 6.5rem 6rem;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div` 
  background-color: white;
  border: 1px solid #DCDCDC;
  padding: 10px 0px;
  border-radius: 10px;
  flex:1;
  flex-direction: column;
`
export const Menu = styled.div`  
  align-items:center;
  flex:1;
  display: flex;    
  flex-direction: row;  
  padding: 25px 54px; 

  h1{
    font-size: 14px;
    font-weight: bold;
    padding: 0;
  }
  
`

export const MenuTitles = styled.div`   
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  background-color: #F8FAFD;
  flex: 1;
  height:58px;
  display: flex;
  padding: 18px 74px;
  align-items: center;
  /* justify-content: space-between; */


  h1{
    font-size: 14px;
    font-weight: bold;
    text-align: start;
    min-width:190px;
    /* max-width:200px; */
    /* padding-inline:50px; */
    margin:0px;

   
  }
  @media only screen and (max-width: 600px) {
      flex-direction: column;
        
        height: auto;
      h1{
        margin-top: 10px;
        text-align: center;        
      }
      button{
        margin-top: 20px;
      }
    }
  
`
export const MenuTitlesB = styled.div`   
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  background-color: #F8FAFD;
  flex: 1;
  height:58px;
  display: flex;
  padding: 18px 74px;
  align-items: center;
  /* justify-content: space-between; */


  h1{
    font-size: 14px;
    font-weight: bold;
    text-align: start;
    min-width:190px;
    /* max-width:150px; */
    /* padding-inline:50px; */
    margin:0px;

   
  }
  @media only screen and (max-width: 600px) {
      flex-direction: column;
        
        height: auto;
      h1{
        margin-top: 10px;
        text-align: center;        
      }
      button{
        margin-top: 20px;
      }
    }
  
`
export const Details = styled.div`
  width: 200px;
  button{
    height:32px;
    width:92px;
    border:none;
    background-color: #F05035;
    border-radius: 5px;
    color:#fff;
  }


`



