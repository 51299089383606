import React, { useEffect} from 'react'
import NavBarSolo from '../../../../components/NavBarSolo'
import {TitleBar} from '../../../../components/TitleBar/index'
import { MainWraper, Container, Title, FormContainer, Total, Box, BoxTitle, DataContainer, DataContent } from './styles'
import Footer from '../../../../components/Footer'
import {format } from 'date-fns'

import { useLocation } from 'react-router-dom'

const DetalheCGE = (props) =>{
  const { state: {location} } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const handleDock = (dockType) => {
    switch(dockType){
      case 'Ramp':
        return 'Rampa'
      case 'High':
        return 'Alta'
      default: 
        return null
    }
  }

  const handleVehicle = (dockType) => {
    switch(dockType){
      case 'Truck 3/4':
        return 'Caminhão 3/4'
      case 'Stump truck':
        return 'Caminhão toco'
      case 'Simple cart':
        return 'Caminhão carreta Simples'
      case 'Extended axle cart':
        return 'Caminhão carreta eixo extendido'
      case 'Van trunk':
        return 'Van Baú'
      case 'Utility':
        return 'Veículo utilitário'
      case 'Van':
        return 'Furgão (Van)'
      default: 
        return null
    }
  }

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
  
  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Care"/>
      <Container>
        <Title>Resumo de agendamento</Title>
        {/* <Description>
          Aqui vai algum explicativo, com informações adicionais que julgarem pertinentes. Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
        </Description> */}
        <Total>
          <Title style={{color:'#fff'}}>Valor a Pagar</Title>
          <Title style={{color:'#fff'}}>{numberFormat(location.totalPrice)}</Title>
        </Total>
        <Title style={{marginTop:20}}>Descrição dos itens agendados</Title>
        <BoxTitle>
          <Title style={{color:'#fff'}}>Informações gerais</Title>
        </BoxTitle>
        <Box>
          <DataContainer>
            <h1>Referência do cliente</h1>
            <h1>{location.clientReference}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Data de Entrada</h1>
            <h1>{format(new Date(location.checkInDatetime), 'dd/MM/yyyy')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Horário de entrada</h1>
            <h1>{format(new Date(location.checkInDatetime), 'HH:mm')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Placa do veículo</h1>
            <h1>{location.vehicleLicensePlate}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nome do motorista</h1>
            <h1>{location.driverName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tipo de doca</h1>
            <h1>{handleDock(location.dockType)}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Veículo</h1>
            <h1>{handleVehicle(location.vehicle)}</h1>
          </DataContainer>          
          <DataContent/>
          <h1 style={{fontSize:16, marginTop:16, marginBottom:18}}>Deadline da companhia aérea</h1>
          <DataContainer>
            <h1>Data</h1>
            <h1>{location.airlineDeadlineDatetime ? format(new Date(location.airlineDeadlineDatetime), 'dd/MM/yyyy'): `Sem data`}</h1>            
          </DataContainer>
          <DataContainer>
            <h1>Horário</h1>
            <h1>{location.airlineDeadlineDatetime ? format(new Date(location.airlineDeadlineDatetime), 'HH:mm') : `Sem horário`}</h1>
          </DataContainer>
          
        </Box>
       
        <FormContainer>
          
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default DetalheCGE;