import React, { useState } from 'react'
import styled from 'styled-components'

import Image from 'react-bootstrap/Image'
import rightContentImage from './../assets/img/Banner-img.jpg';
import ModalVideo from './ModalVideo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

const Section2Wrapper = styled.section`
  height: 100%;
  min-height: 462px;
  width: 100%;
  background-color: var(--primary-color-5);
  margin: 0;
  align-items: center;
  padding-bottom: 5vh;

  .left-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--primary-color-10);
    padding: 1rem 18px 1rem 10vw;
    @media only screen and (max-width: 992px) {
      align-items: center;
      padding: 2rem 5vw;
    }
    span {
      &.title {
        color: var(--primary-color-7);
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 20px;
        line-height: 1;
        @media only screen and (max-width: 420px) {
          text-align: center;
        }
      }
      &.subtitle {
        color:  var(--primary-color-10);
        font-size: 16px;
        font-weight: 300;
        max-width: 450px;
        text-align: justify;
        text-justify: inter-word;
        padding-bottom: 12px;
      }
    }
  }
  .right-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-width: 992px) {
      justify-content: center;
    }
    
    img {
      max-width: 420px;
      height: auto;
      &:hover {
        cursor: pointer;
      }
      @media only screen and (max-width: 600px) {
        max-width: 80vw;
      }
    }
    .play-icon {
      position: absolute;
      top: 120px;
      left: 200px;
      color: white;
      width: 75px;
      cursor: pointer;
      transition: .5s ease-in-out;
      &:hover {
        transform: scale(1.5);
      }

      @media only screen and (max-width: 992px) {
        top: unset;
        left: unset;
      }
    }
  }
`

export default function Section2() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Section2Wrapper className="row" data-aos="fade-up" data-aos-duration="3000">
      <div className="col-lg-7 col-sm-12 left-content">
        <span className="title">O que é Cross Docking?</span>
        <span className="subtitle">É uma <span className="font-weight-bold">estratégia de gestão logística</span> na qual as mercadorias são transportadas diretamente para um armazém, onde é feito o correto manuseio e tratamento de seus volumes tornando-os seguros para embarque.</span>
        <span className="subtitle">Quer saber mais? Assista ao vídeo.</span>
      </div>
      <div className="col-lg-5 col-sm-12 right-content">
        <Image src={rightContentImage} onClick={() => setModalShow(true)} thumbnail />
        <div className="play-icon" onClick={() => setModalShow(true)}>
          <FontAwesomeIcon size="4x" icon={faPlayCircle} />
        </div>
      </div>
      <ModalVideo
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Section2Wrapper>
  )
}
