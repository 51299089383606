import React, {useCallback, useRef, useState} from 'react'
import { MainWraper, Content, Background, Container, Menu, Log, MenuLogin, LoginForm, BContainer, MenuCadastro, Persona } from './styles'
import {Link, useHistory} from 'react-router-dom'
import Navbar from './../../patterns/Navbar'
import Input from '../../components/Input'
import {Form} from '@unform/web'
import api from '../../services/api'
import {useAuth} from '../../hooks/AuthContext'
import {cpfMask, cnpjMask} from '../../utils/cpfmask'
import getValidationErros from '../../utils/getValidationErrors'
import * as Yup from 'yup'
import Footer from 'components/Footer'
import { validateCNPJ, validateCPF } from "validations-br";
import {useToast} from '../../hooks/ToastContext'


const Register = ()  => {
  const { addToast } = useToast()
  const [persona, setPersona] = useState(true)
  const history = useHistory()
  const formRef = useRef(null)
  const {signIn} = useAuth()

 const handleSubmitRegisterJ = useCallback(async(data) =>{
    try{

      data.cnpj= data.cnpj.split(".").join("");
      data.cnpj = data.cnpj.split("-").join("");
      data.cnpj = data.cnpj.split("/").join("");

      const schema = Yup.object().shape({
        razao_social: Yup.string().required('Nome Obrigatório'),
        cnpj: Yup.string().required('CNPJ obrigatório').min(14,'Número de dígitos Incorreto').test(
          'is-cnpj',
          "CNPJ inválido",
          (value) => validateCNPJ(value)
        ),
        email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
        emailConfirmation: Yup.string().oneOf([Yup.ref('email'),null],'E-mails não coincidem, verifique novamente.').required('Confirme o E-mail'),
        password: Yup.string().required('Senha obrigatória').min(6,'Mínimo de 6 dígitos'),
      })
      await schema.validate(data,{
        abortEarly:false,
      })

      await api.post('/client', {
        type:'PJ',
        basicInformation: {
          name: data.razao_social,
          email: data.email,
          document: data.cnpj,
          password: data.password, 
        }
      })      

      await signIn({
        email: data.email,
        password: data.password
      })   
      
    }catch(err){    

      if(err.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }
      
      if(err.response.data.message === "EmailAlreadyExists"){
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'E-mail já existente, por favor, escolha um novo email ou recupere sua senha.',
        })
      }
    }

 }, [signIn,addToast]) 

 const handleSubmitRegisterF = useCallback(async(data) =>{
  try{

      data.cpf= data.cpf.split(".").join("");
      data.cpf = data.cpf.split("-").join("");

    const schema = Yup.object().shape({
      name: Yup.string().required('Nome Obrigatório'),
      cpf: Yup.string().required('CNPJ obrigatório').min(10,'Número de dígitos Incorreto').test(
        'is-cpf',
        "CPF inválido",
        (value) => validateCPF(value)
      ),
      email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
      emailConfirmation: Yup.string().oneOf([Yup.ref('email'),null]).required('Confirme o E-mail'),
      password: Yup.string().required('Senha obrigatória').min(6,'Mínimo de 6 dígitos'),
    })
    await schema.validate(data,{
      abortEarly:false,
    })

    await api.post('/client', {
      type:'PF',
      basicInformation: {
        name: data.name,
        email: data.email,
        document: data.cpf,
        password: data.password, 
      }
    })    

    await signIn({
      email: data.email,
      password: data.password
    })   
    
  }catch(err){    

    if(err.name=== 'ValidationError'){
      const errors = getValidationErros(err)
      formRef.current?.setErrors(errors)
    }
    
      if(err.response.data.message === "EmailAlreadyExists"){
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'E-mail já existente, por favor, escolha um novo email ou recupere sua senha.',
        })
      }
  }

}, [signIn,addToast]) 

 const [value, setValue] = useState('') 
 const [valueCNPJ, setValueCNPJ] = useState('')

  return (
    <MainWraper>
      <Navbar/>
      <Background>
        <Container>
          <Content>
            <Menu>
              <MenuLogin>
                <Link to="/login">
                <Log >LOGIN</Log>
                </Link>
              </MenuLogin>
              <MenuCadastro>
              <Link to="/cadastrar">
                <Log >CADASTRAR</Log>
              </Link>
              </MenuCadastro>
            </Menu>    
            <Persona>
              <button style={persona === true ? {color: '#000'} : {color: '#a2a2a2'}} onClick={()=>setPersona(true)}>Pessoa Jurídica</button>
              <button style={persona === false ? {color: '#000'} : {color: '#a2a2a2'}} onClick={()=>setPersona(false)}>Pessoa Física</button>
            </Persona>        
            {persona && <LoginForm>
              <Form ref={formRef} onSubmit={handleSubmitRegisterJ}>
                <h1>Razão Social</h1>
                <Input name="razao_social" placeholder="Informe a Razão Social"/>
                <h1>CNPJ</h1>
                <Input maxLength={18} name="cnpj" value={valueCNPJ} onChange={(e)=>setValueCNPJ(cnpjMask(e.target.value))}  placeholder="Informe o CNPJ"/>
                <h1>E-mail</h1>
                <Input name="email" placeholder="Informe o e-mail"/>
                <h1>Confirmar E-mail</h1>
                <Input name="emailConfirmation" onPaste={(e)=>e.preventDefault()} placeholder="Repita o e-mail"/>
                <h1>Senha</h1>
                <Input name="password" type="password" placeholder="Informe a senha"/>
                <Link to="esqueci-a-senha" style={{color:"#000", width: 120}}>Esqueci a senha</Link>
                <BContainer>
                  <button >Entrar</button>
                  <button onClick={(e)=>{e.preventDefault(); history.push('/')}}>Cancelar</button>
                </BContainer>
              </Form>
            </LoginForm>}
            {!persona && <LoginForm>
              <Form ref={formRef} onSubmit={handleSubmitRegisterF}>
                <h1>Nome</h1>
                <Input name="name" placeholder="Informe o nome"/>
                <h1>CPF</h1>
                <Input maxLength={14} value={value} onChange={(e)=>setValue(cpfMask(e.target.value))}  name="cpf" placeholder="Informe o CPF"/>
                <h1>E-mail</h1>
                <Input name="email" placeholder="Informe o e-mail"/>
                <h1>Confirmar E-mail</h1>
                <Input name="emailConfirmation" onPaste={(e)=>e.preventDefault()} placeholder="Repita o e-mail"/>
                <h1>Senha</h1>
                <Input name="password" type="password" placeholder="Informe a senha"/>
                <Link style={{color:"#000", width: 120}}>Esqueci a senha</Link>
                <BContainer>
                  <button >Entrar</button>
                  <button onClick={(e)=>{e.preventDefault(); history.push('/')}}>Cancelar</button>
                </BContainer>
              </Form>
            </LoginForm>}
            

          </Content>
        </Container>

      </Background>
      <Footer />
    </MainWraper>
  )
}

export default Register;