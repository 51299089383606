import React from 'react'
import styled from 'styled-components'
import img from './../assets/img/Section3-img-col-esq.jpg';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Section4Wrapper = styled.section`
  width: 100%;
  height: 100%;
  min-height: 704px;
  background-color: var(--primary-color-10);
  margin: 0;
  align-items: center;
  @media only screen and (max-width: 992px) {
    min-height: unset;
  }
  .right-content {
    height: 100%;
    padding: 1rem 5%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--primary-color-10);
    @media only screen and (max-width: 768px) {
      padding: 1rem 7.5vw;
    }
    @media only screen and (max-width: 600px) {

    }
    span {
      &.title {
        color: var(--primary-color-7);
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 32px;
        line-height: 1.2;
        @media only screen and (max-width: 768px) {
          font-size: 32px;
        }
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        color: var(--primary-color-7);
        padding-bottom: .75rem;
        span {
          margin-left: 12px;
          font-size: 20px;
          font-weight: 600;
        }
        @media only screen and (max-width: 600px) {
          padding-bottom: .25rem;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
`
const ImageContent = styled.div`
  background-image: url(${img});
  background-size: cover;
  height: 704px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const listItems = [
  "Etiquetagem de volumes",
  "Checagem das condições das embalagens",
  "Cintamento",
  "Reembalagem",
  "Agendamento e entrega no TECA Exportação GRU",
  "Repesagem",
  "Consolidação",
  "Paletização",
  "Envelopamento filme stretch",
  "Direcionamento para fumigação",
  "Padrão OEA"
]

export default function Section4() {

  return (
    <Section4Wrapper className="row" data-aos="fade-up">
      <ImageContent className="col-md-6 col-sm-12"></ImageContent>

      <div className="col-md-6 col-sm-12 right-content">
        <span className="title">A gente se preocupa por você</span>
        <ul>
          { listItems?.map(item => (
            <li key={uuidv4()}>
              <FontAwesomeIcon size="1x" icon={faCheck} />
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </Section4Wrapper>
  )
}
