import React, {createContext, useCallback, useState, useContext} from 'react'
import api from '../services/api'
import {useHistory} from 'react-router-dom'

const AuthContext = createContext()

export const AuthProvider = ({children}) =>{
  const history = useHistory()
  const [data, setData] = useState(()=> {

  const token = localStorage.getItem('@CrossDo:token')
  const client = localStorage.getItem('@CrossDo:user')

  if(token && client){
    return { token , client: JSON.parse(client)}
  }
  
  return {}

  })

  const signIn = useCallback(async({email, password})=>{
    console.log('err')
      const response = await api.post('/client/login',{
        email,
        password,
      })
      const { token, client } = response.data
      localStorage.setItem('@CrossDo:token', token)
      localStorage.setItem('@CrossDo:user', JSON.stringify(client))

      setData({ token, client })   
      
      history.push('/')
   
  }, [history])

  const signOut = useCallback(()=>{

    localStorage.removeItem('@CrossDo:token')
    localStorage.removeItem('@CrossDo:user')

    setData({})

  },[])

  return(
    <AuthContext.Provider value={{user: data.client, token: data.token, signIn, signOut}}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(){
  const context = useContext(AuthContext)

  if(!context){
    throw new Error('useAuth must be use within an AuthProvider')
  }
  return context

}

