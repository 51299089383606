/* eslint-disable */
import React, {useState, useRef, useCallback, useEffect} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import {Form} from '@unform/web'
import { 
  MainWraper,
  Container, 
  ShipperForm, 
  HAWBForm, 
  Title, 
  Description, 
  FormContainer, 
  GroupB, Individual, 
  BContainer, 
  GroupBox, 
  Box, 
  InputBox, 
  IndividualInput, 
  DoubleInput 
} from './styles'
import Footer from '../../../components/Footer'
import getValidationErros from '../../../utils/getValidationErrors'
import {useHistory} from 'react-router-dom'
import { Collapse } from 'antd'
import ParaCima from '../../../assets/icones/EsteLadoParaCima.svg'
import Fragil from '../../../assets/icones/fragil.svg'
import Temperatura from '../../../assets/icones/Temperatura.svg'
import Umbrella from '../../../assets/icones/umbrella.svg'
import Aog from '../../../assets/icones/AOG.svg'
import Input from '../../../components/Input'
import SelectInput from '../../../components/SelectInput/index'
import CepApi from '../../../services/cepApi'
import api from '../../../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd'

import 'antd/dist/antd.css';

import * as Yup from 'yup'
// import FormItem from '../../../components/FormItem'



const { Panel } = Collapse;


const ThirdStep = (props) =>{
  const history = useHistory()
  const formRef = useRef(null)  
  

  const [address, setAddress] = useState('')
  
  const handleCep = async(CEP) => {
    if(CEP.length === 8){
      const { data} = await CepApi.get(`/${CEP}/json`)
      console.log(data.cep)
      // props.setCepExporter(data.cep)
      props.setLogradouroExporter(data.logradouro)
      props.setBairroExporter(data.bairro)
      props.setLocalidadeExporter(data.localidade)
      props.setUfExporter(data.uf)  
      //props.setAddressExporter( data.logradouro && data.bairro && (data.logradouro + ', ' + data.bairro ) || 'Endereço não encontrado' )
    }
      
  }
  const [airports, setAirports] = useState()
  const { next, previous } = props.navigation;
  const [testQuantity, setTestQuantity] = useState()
  
  const handleSubmitEtapa1 = useCallback(async(data) =>{    
    try{ 
      const quantityShip = formRef.current.getData().tagQuantityShip
      const quantityMaster = formRef.current.getData().tagQuantityMaster
      const schema = Yup.object().shape({
        tagPosition: Yup.string().required('Campo Obrigatório'),
        tagFragile: Yup.string().required('Campo Obrigatório'),
        tagTemperature: Yup.string().required('Campo Obrigatório'),
        tagAOG: Yup.string().required('Campo Obrigatório'),
        tagUmbrella: Yup.string().required('Campo Obrigatório'),
       
        tagQuantityShip: Yup.string().required('Campo Obrigatório'),
        shipperName: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        CEP: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        neighborhoodExporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        streetExporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        numberExporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        // complementExporter: quantityShip > 0 ? Yup.string() : Yup.string(),
        city: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        uf: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        countryExporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        phoneExporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        emailExporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        consigneeName: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        CEPImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        neighborhoodImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        streetImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        numberImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        // complementImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        cityImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        ufImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        countryImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        phoneImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        emailImporter: quantityShip > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        
      
        tagQuantityMaster: Yup.string().required('Campo Obrigatório'),        
        mawbNumber: quantityMaster > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        hawbNumber: quantityMaster > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        airportOrigin: quantityMaster > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        airportDestiny: quantityMaster > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        volumeQuantity: quantityMaster > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        totalWeight: quantityMaster > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
        invoiceNumber: quantityMaster > 0 ? Yup.string().required('Campo Obrigatório') : Yup.string(),
      })
      // console.log('Data',data)
      await schema.validate(data,{
        abortEarly:false,
      })
      props.setOpenColapse('1')
      next()      
    }catch(err){   
      //  console.log(err)
      if(err?.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }      
    }
  },[history])
  
  // const handleTagChange = (e) =>{
  //   setTestQuantity(e)
    
  // }
  // useEffect(()=>{
    
  //   formRef.current.setData({tagQuantityShip: testQuantity})
  //   console.log('tagQuantityMaster',formRef.current.getData().tagQuantityMaster)
  //   console.log('tagQuantityShip',formRef.current.getData().tagQuantityShip)
  // },[testQuantity])

  useEffect(async()=>{
    window.scrollTo(0, 0);
    const { data } = await api.get('/airport')
    const arr  = data.map((data) => { return { value: data.id.toString(), label: data.name }})
    setAirports(arr)
  },[])

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Combo"/>
      <Container>
        <Title> Etapa 3 de 3 - Etiquetas </Title>
        <Description>
          As etiquetas de manuseio, master e de house e também de “ shipper” e “consignee” deverão ser selecionadas e preenchidas com o seu padrão.
        </Description>
        <Description>
          As etiquetas disponibilizadas no seu kit serão as quantidades contratadas.
        </Description>
        <Description>
          Fique atento para o número de volumes compatíveis para que sejam etiquetados de acordo.
        </Description>
        <Description>
          Todos os campos são obrigatórios, exceto aqueles informados como opcionais.
        </Description>
        
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmitEtapa1}>

          <Collapse 
            defaultActiveKey={[props.openColapse]}
            expandIconPosition="right" 
            style={{backgroundColor:'#fff', borderRadius:5}}
          >
            <Panel header="Etiquetas de Manuseio" key="1" style={{paddingLeft: 20, paddingRight:10, borderRadius:5}} >
              <p>{props.prices && numberFormat(props?.prices.['Tags']?.price)} por etiqueta</p>
              <p>Informe a(s) quantidade(s) desejada(s)</p>
              <GroupBox>
                <Box>
                  <img src={ParaCima}/>
                  <h1>Este lado para cima</h1>
                  <InputBox>
                    <Input 
                      name="tagPosition" 
                      placeholder="0" 
                      type="number"                      
                      min={0}
                      value={props.tagPosition}
                      onChange={(e)=>props.setTagPosition(e.target.value)}
                    />
                  </InputBox>

                </Box>
                <Box>
                <img src={Fragil}/>
                  <h1>Frágil</h1>
                  <InputBox>
                    <Input 
                      name="tagFragile" 
                      placeholder="0" 
                      type="number"                      
                      min={0}
                      value={props.tagFragile}
                      onChange={(e)=>props.setTagFragile(e.target.value)}
                    />
                  </InputBox>
                </Box>
                <Box>
                <img src={Temperatura}/>
                  <h1>Temperatura</h1>
                  <InputBox>
                    <Input 
                      name="tagTemperature" 
                      placeholder="0" 
                      type="number"                      
                      min={0}
                      value={props.tagTemperature}
                      onChange={(e)=>props.setTagTemperature(e.target.value)}
                    />
                  </InputBox>
                </Box>

                <Box>
                <img src={Aog}/>
                <h1>AOG</h1>
                  <InputBox>
                    <Input 
                      name="tagAOG" 
                      placeholder="0" 
                      type="number"                      
                      min={0}
                      value={props.tagAOG}
                      onChange={(e)=>props.setTagAOG(e.target.value)}
                    />
                  </InputBox>

                </Box>
                
              </GroupBox>
              <Box>
                <img style={{height:30,width:30}} src={Umbrella}/>
                <h1>Evitar Umidade</h1>
                  <InputBox>
                    <Input 
                      name="tagUmbrella" 
                      placeholder="0" 
                      type="number"                      
                      min={0}
                      value={props.tagUmbrella}
                      onChange={(e)=>props.setTagUmbrella(e.target.value)}
                    />
                  </InputBox>

                </Box>
            </Panel>                       
          </Collapse>

          <Collapse 
            defaultActiveKey={[props.openColapse]}
            expandIconPosition="right" 
            style={{backgroundColor:'#fff', marginTop:20, borderRadius:5}}                  
          >
            <Panel header="Etiquetas Shipper/Consignee" key="1" style={{paddingLeft: 20, paddingRight:10, borderRadius:5}} >
              <p>{props.prices && numberFormat(props?.prices.['Merchant Tag']?.price)} por etiqueta</p>
              <p>Informe a(s) quantidade(s) desejada(s)</p>

              <ShipperForm>
                <IndividualInput>                
                  <h1>Nome do exportador (shipper)</h1>
                  <Input 
                    name="shipperName"
                    value={props.shipperName}
                    onChange={(e)=>props.setShipperName(e.target.value)}            
                  />
                </IndividualInput>
                <IndividualInput>
                  <h1>CEP (Zip Code)</h1>
                  <Input 
                    name="CEP"
                    placeholder="31000000"
                    value={props.cepExporter}
                    type="number"
                    min={0}
                    onChange={(e)=>{props.setCepExporter(e.target.value);handleCep(e.target.value)}}
                  />
                </IndividualInput>
                <DoubleInput>

                <IndividualInput>
                <h1>Bairro (neighborhood)</h1>
                <Input 
                  name="neighborhoodExporter"
                  value={props.bairroExporter}
                  onChange={(e)=>props.setBairroExporter(e.target.value)}                                
                  min={0}                                   
                  />
                 </IndividualInput>
                <IndividualInput>
                <h1>Logradouro (street)</h1>
                <Input 
                  name="streetExporter"
                  value={props.logradouroExporter}
                  onChange={(e)=>props.setLogradouroExporter(e.target.value)}                                
                  min={0}                                   
                />
                </IndividualInput>
                </DoubleInput>
                <DoubleInput>
                <IndividualInput>
                <h1>Número (number)</h1>
                <Input 
                  name="numberExporter"
                  value={props.numberExporter}
                  onChange={(e)=>props.setNumberExporter(e.target.value)}
                  min={0}
                />
                </IndividualInput>
                <IndividualInput>
                  <h1>Complemento (complement))</h1>
                  <Input 
                    name="complementExporter"
                    value={props.complementExporter}
                    onChange={(e)=>props.setComplementExporter(e.target.value)}                                
                    min={0}                                   
                  />
                </IndividualInput>
                </DoubleInput>
                <DoubleInput>
                  <IndividualInput>
                    <h1>Cidade (City)</h1>
                    <Input 
                      name="city"
                      value={props.localidadeExporter}                                 
                      min={0}
                      onChange={(e)=>props.setLocalidadeExporter(e.target.value)}
                    />
                  </IndividualInput>
                  <IndividualInput>
                    <h1>UF (State)</h1>
                    <Input
                      name="uf"
                      value={props.ufExporter}
                      onChange={(e)=>props.setUfExporter(e.target.value)}
                    />
                  </IndividualInput>
                </DoubleInput>
                <IndividualInput>
                  <h1>País (Country)</h1>
                  <Input
                    name="countryExporter"
                    value={props.countryExporter}
                    onChange={(e)=>props.setCountryExporter(e.target.value)}
                    min={0}
                  />
                </IndividualInput>
                <IndividualInput>
                  <strong>Contatos do exportador</strong>
                </IndividualInput>
                {/* <IndividualInput>
                  <h1>Nome</h1>
                  <Input
                    name="nameExporter"
                    value={props.contactNameExporter}
                    onChange={(e)=>props.setContactNameExporter(e.target.value)}
                    style={{width:'100%'}}
                  />
                </IndividualInput> */}
                <DoubleInput>
                  <IndividualInput>
                    <h1>Telefone</h1>
                    <Input 
                      name="phoneExporter"
                      value={props.contactPhoneExporter}
                      onChange={(e)=>props.setContactPhoneExporter(e.target.value)}
                      min={0}
                    />
                  </IndividualInput>
                  <IndividualInput>
                    <h1>E-mail</h1>
                    <Input 
                      name="emailExporter"                                                   
                      min={0}
                      value={props.contactEmailExporter}
                      onChange={(e)=>props.setContactEmailExporter(e.target.value)}
                    />
                  </IndividualInput>
                </DoubleInput>
                <div style={{marginTop:40}}/>
                <IndividualInput>                
                  <h1>Nome do Importador (consignee)</h1>
                  <Input 
                    name="consigneeName"
                    value={props.consigneeName}
                    onChange={(e)=>props.setConsigneeName(e.target.value)}
                  />
                </IndividualInput>
                <IndividualInput>
                  <h1>CEP (Zip Code)</h1>
                  <Input 
                    name="CEPImporter"
                    placeholder="31000000"
                    type="number"                
                    min={0}  
                    value={props.cepImporter}
                    onChange={(e)=>props.setCepImporter(e.target.value)}
                  />
                </IndividualInput>
                <DoubleInput>

                <IndividualInput>
                <h1>Bairro (district)</h1>
                <Input 
                  name="neighborhoodImporter"
                  value={props.bairroImporter}
                  onChange={(e)=>props.setBairroImporter(e.target.value)}                                
                  min={0}                                   
                  />
                 </IndividualInput>
                <IndividualInput>
                <h1>Logradouro (street)</h1>
                <Input 
                  name="streetImporter"
                  value={props.logradouroImporter}
                  onChange={(e)=>props.setLogradouroImporter(e.target.value)}                                
                  min={0}                                   
                />
                </IndividualInput>
                </DoubleInput>
                <DoubleInput>
                <IndividualInput>
                <h1>Número (number)</h1>
                <Input 
                  name="numberImporter"
                  value={props.numberImporter}
                  onChange={(e)=>props.setNumberImporter(e.target.value)}
                  min={0}
                />
                </IndividualInput>
                <IndividualInput>
                  <h1>Complemento (complement))</h1>
                  <Input 
                    name="complementImporter"
                    value={props.complementImporter}
                    onChange={(e)=>props.setComplementImporter(e.target.value)}                                
                    min={0}                                   
                  />
                </IndividualInput>
                </DoubleInput>
                <DoubleInput>
                  <IndividualInput>
                    <h1>Cidade (City)</h1>
                    <Input 
                      name="cityImporter"
                      value={props.localidadeImporter}                                 
                      min={0}
                      onChange={(e)=>props.setLocalidadeImporter(e.target.value)}              
                    />
                  </IndividualInput>
                  
                  <IndividualInput>
                    <h1>UF (State)</h1>
                    <Input 
                      name="ufImporter"
                      value={props.ufImporter}  
                      onChange={(e)=>props.setUfImporter(e.target.value)}           
                    />
                  </IndividualInput>
                </DoubleInput>
                <IndividualInput>
                  <h1>País (Country)</h1>
                  <Input 
                    name="countryImporter"                                                   
                    value={props.countryImporter}
                    onChange={(e)=>props.setCountryImporter(e.target.value)}
                  />
                </IndividualInput>
                <IndividualInput>
                  <strong>Contatos do Importador</strong>   
                </IndividualInput>
                {/* <IndividualInput>
                  <h1>Nome</h1>
                  <Input 
                      name="nameImporter"                                                   
                      value={props.contactNameImporter}
                      onChange={(e)=> props.setContactNameImporter(e.target.value)}
                      style={{width:'100%'}}
                  />
                </IndividualInput> */}
                <DoubleInput>
                  <IndividualInput>
                    <h1>Telefone</h1>
                    <Input 
                      name="phoneImporter" 
                      value={props.contactPhoneImporter}
                      onChange={(e)=>props.setContactPhoneImporter(e.target.value)}
                      
                    />
                  </IndividualInput>
                  <IndividualInput>
                    <h1>E-mail</h1>
                    <Input 
                      name="emailImporter"
                      value={props.contactEmailImporter}
                      onChange={(e)=>props.setContactEmailImporter(e.target.value)}                                                  
                      
                    />
                  </IndividualInput>
                </DoubleInput>
                <div style={{marginTop:50}}/>
                <strong>Informe a quantidade de etiquetas</strong>
                <Box style={{marginBottom:0}}>
                  <InputBox style={{marginTop:20, alignItems:'center'}}>                    
                    <h1 style={{marginTop:14}}>Quantidade</h1>
                    <Input 
                      name="tagQuantityShip" 
                      placeholder="0" 
                      type="number"                      
                      min={0}
                      style={{width:80}}
                      value={props.tagQuantityShip}
                      onChange={(e)=>{props.setTagQuantityShip(e.target.value);}}
                    />
                  </InputBox>
                </Box>
              </ShipperForm>

            </Panel>                       
          </Collapse>

          <Collapse 
            defaultActiveKey={[props.openColapse]}
            expandIconPosition="right" 
            style={{backgroundColor:'#fff', marginTop:20, marginBottom:40, borderRadius:5}}                  
          >
            <Panel header="Etiquetas Master e House" key="1" style={{paddingLeft: 20, paddingRight:10, borderRadius:5}} >
              <p>{props.prices && numberFormat(props?.prices.['Master and House Tag']?.price)} por etiqueta</p>
              <p>Informe a(s) quantidade(s) desejada(s)</p>
              <HAWBForm>
                <DoubleInput>
                  <IndividualInput>
                    <h1>Nº MAWB</h1>
                    <Input
                      name="mawbNumber"
                      type="number"
                      min={0}
                      value={props.mawbNumber}
                      onChange={(e)=>props.setMawbNumber(e.target.value)}
                    />
                  </IndividualInput>
                  <IndividualInput>
                    <h1>Nº HAWB</h1>
                    <Input
                      name="hawbNumber"
                      type="number"
                      min={0}
                      value={props.hawbNumber}
                      onChange={(e)=>props.setHawbNumber(e.target.value)}
                    />
                  </IndividualInput>
                </DoubleInput>
                <DoubleInput>
                  <IndividualInput>
                  <h1>Aeroporto de origem</h1>
                    <SelectInput 
                      placeholder="Escolher" 
                      name="airportOrigin" 
                      options={airports} 
                      value={props.airportOrigin}
                      onChange={(e)=>props.setAirportOrigin(e)}/>
                  </IndividualInput>
                  <IndividualInput>
                    <h1>Aeroporto de destino</h1>
                    <SelectInput 
                      placeholder="Escolher" 
                      name="airportDestiny" 
                      options={airports}
                      value={props.airportDestiny}
                      onChange={(e)=>props.setAirportDestiny(e)}
                      />
                  </IndividualInput>
                </DoubleInput>
                <DoubleInput>
                  <IndividualInput>
                    <div style={{flexDirection:'row', display:'flex', justifyContent:'space-between'}}>
                      <h1>Quantidade de volume final (em unidades do HAWB)</h1>
                      <Tooltip placement="top" title={"Texto aqui"}>
                        <FontAwesomeIcon style={{height:16, width:16}} icon={faQuestionCircle}/>
                      </Tooltip>
                    </div>
                    <Input
                      name="volumeQuantity"
                      type="number"
                      min={0}
                      value={props.volumeQuantity}
                      onChange={(e)=>props.setVolumeQuantity(e.target.value)}
                    />
                  </IndividualInput>
                  <IndividualInput>
                    <h1>Peso bruto total (Em Kg do HAWB)</h1>
                    <Input 
                      placeholder="Escolher" 
                      type="number"
                      min={0}
                      name="totalWeight" 
                      value={props.totalWeight}
                      onChange={(e)=>props.setTotalWeight(e.target.value)}
                      />
                  </IndividualInput>
                </DoubleInput>
                <IndividualInput>
                  <h1>Nº da Invoice - INV (opcional)</h1>
                  <Input
                    name="invoiceNumber"                    
                    value={props.invoiceNumber}
                    onChange={(e)=>props.setInvoiceNumber(e.target.value)}
                  />
                </IndividualInput>
                <div style={{marginTop:50}}/>
                <strong>Informe a quantidade de etiquetas</strong>
                <Box style={{marginBottom:0}}>
                  <InputBox style={{marginTop:20, alignItems:'center'}}>                    
                    <h1 style={{marginTop:14}}>Quantidade</h1>
                    <Input 
                      name="tagQuantityMaster" 
                      placeholder="0" 
                      type="number"                      
                      min={0}
                      style={{width:80}}
                      value={props.tagQuantityMaster}
                      onChange={(e)=>props.setTagQuantityMaster(e.target.value)}
                    />
                  </InputBox>
                </Box>
              </HAWBForm>
            </Panel>                       
          </Collapse>          

          <GroupB>
                <Individual style={{paddingHorizontal:0}}>

                </Individual>
                <BContainer>
                  <button onClick={(e)=>{e.preventDefault();previous()}}>Voltar</button>
                  <button>Avançar</button>
                </BContainer>
            </GroupB>

          </Form>
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default ThirdStep;