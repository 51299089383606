/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import styled from 'styled-components'
import logoFooter from './../assets/img/Logo-bottom-menu.png';
import Image from 'react-bootstrap/Image'
import ModalInfo from './ModalInfo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const FooterWrapper = styled.footer`
  height: 170px;
  background-color: var(--primary-color-7);
  color: var(--primary-color-10);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 992px) {
    padding: 2rem 1rem;
    height: 100%;
    max-height: 330px;
  }
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding: 1rem;
      font-weight: 300;
      font-size: 14px;
    }
    .brand-icon {
      margin: 0 2rem;
    }

    @media only screen and (max-width: 992px) {
      flex-wrap: wrap;
      margin: 1rem 0;
    }
  }
`

export default function Footer() {
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("politicas");

  const onOpenModal = (type) => {
    setModalType(type)
    setModalShow(true)
  }

  return (
  <FooterWrapper className="row">
    <div className="footer-content col-sm-12 col-md-4">
      <a onClick={() => onOpenModal('politicas')} >
        <span>Políticas de Privacidade</span>
      </a>
      <a onClick={() => onOpenModal('termos')} >
        <span>Termos de Uso</span>
      </a>
    </div>
    <div className="footer-content col-sm-12 col-md-4">
      <Image src={logoFooter} />
    </div>
    <div className="footer-content col-sm-12 col-md-4">
      <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5531996323455&text=Entre%20em%20contato%20conosco%20sobre%20o%20CrossDo">
        <FontAwesomeIcon className="brand-icon" size="1x" icon={faWhatsapp} style={{ color: "white" }} />        
      </a>
      <a rel="noreferrer" target="_blank" href="https://www.instagram.com/crossdo_/">
        <FontAwesomeIcon className="brand-icon" size="1x" icon={faInstagram} style={{ color: "white" }} />        
      </a>
      <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/crossdo/">
        <FontAwesomeIcon className="brand-icon" size="1x" icon={faLinkedinIn} style={{ color: "white" }} />        
      </a>
    </div>

      <ModalInfo
        show={modalShow}
        onHide={() => setModalShow(false)}
        infoType={modalType}
      />

      <ModalInfo
        show={modalShow}
        onHide={() => setModalShow(false)}
        infoType={modalType}
      />
  </FooterWrapper>
  )
}