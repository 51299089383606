import styled from 'styled-components'
import { shade } from 'polished'

export const MainWraper = styled.div`
  height: 100vh;
  /* min-width: 100vw; */
  margin: 0;
  padding: 0;  
`
export const Container = styled.div`
  display:flex; 
  flex-direction: column;
  flex:1;
  /* width: 100vw; */
  padding: 10.5rem 10.5rem 4.5rem 10.5rem;
  @media only screen and (max-width: 922px) {
      padding: 10.5rem 1rem;     
  }
`

export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color:black;
`
export const Description = styled.h3`
  max-width: 400px ;
  line-height: 22px;
  font-weight: 300;
  margin-top: 15px;
  font-size: 14px;
`
export const Group = styled.div`
  display: flex;
  flex-direction: row;  
  align-items: center;
  
  @media only screen and (max-width: 1200px) {
    flex-direction: column;    
  }
`
export const GroupB = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;    
  }
`
export const Total = styled.div`
  display: flex;
  flex-direction: row;
  height:50px;
  padding:6px 30px 0 30px;
  justify-content: space-between;
  align-items: center;  
  background-color: #0F2649;
  margin-top: 10px;

  button{    
    padding: 8px 40px 8px 40px;
    border:none;          
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color:#F05035;
    border-radius: 4px;
    margin-top: -6px;

    &:hover{
      background:${shade(0.2,'#F05035' )};
      color: #fff;
    }
  }

`
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 0 0 5px 5px;
  border-color: #D4D1DA;
  /* height: 200px; */
  padding:30px 56px ;
`
export const DataContent = styled.div`
  display:flex;
  border-top: 1px   solid;
  margin-top: 10px;
  border-color: #D4D1DA;
`
export const DataContainer = styled.div`
  display:flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  
  h1{
    font-size: 14px;
    font-weight: bold;

    & + h1{
      font-size: 14px;
      font-weight: 300;

    }
  }

`
export const BoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  height:50px;
  padding:6px 56px 0 56px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  background-color:#F05035;  
`

export const BContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
  
  button{    
    padding: 8px 50px 8px 50px;
    border:none;
    margin-left:20px;      
    font-size: 14px;
    font-weight: bold;
    color: #1E75BA;
    background-color:#fff;
    border-radius: 4px;

    &:hover{
      background:${shade(0.2,'#1E75BA' )};
      color: #fff;
    }
    
    & + button{
      color: #fff;
      background-color:#1E75BA;
    }

  }

  
`

export const FormContainer = styled.div`
  margin-top: 20px;  
  input{
      width: 18px;
      height: 18px;
      margin-left: 14px;
      @media only screen and (max-width: 600px) {
        width:90vw ;
      }     
    }    
    h1{
      margin-top: 18px;
      font-size: 14px;
      font-weight: 300;
    }
    strong{
      font-weight: bold;
    }
`