import React from 'react'
import styled from 'styled-components'

const Section6Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-10);
  margin: 0;
  align-items: center;

  .info-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--primary-color-7);
    padding: 1rem 5%;

    @media only screen and (max-width: 992px) {
      padding: 2rem;
    }

    div.subinfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 2rem;
      span {
        font-weight: 500;
        font-size: 16px;
      }
      @media only screen and (max-width: 992px) {
        width: 100%;
        align-items: center;
      }
    }
    span {
      &.title {
        color: var(--primary-color-7);
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 20px;
        line-height: 1;
        @media only screen and (max-width: 992px) {
          width: 100%;
          text-align: center;
        }
      }
    }

  }
  .map-content {
    overflow:hidden;
    padding-bottom: 27.5%;
    position:relative;
    height:0;
    iframe {
      border:0;
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
    @media only screen and (max-width: 992px) {
      padding-bottom: 45%;
    }
  }

`

export default function Section6() {

  return (
    <Section6Wrapper id="contact" className="row" data-aos="fade-up">
      <div className="col-lg-6 col-sm-12 map-content">
        <iframe title="video" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.3648221252056!2d-46.49531828507512!3d-23.44730146327505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce60224c835c25%3A0x752f424027d13d84!2sSP-019%2C%20Guarulhos%20-%20SP!5e0!3m2!1sen!2sbr!4v1621724150510!5m2!1sen!2sbr" width="600" height="450" allowFullScreen="" loading="lazy"></iframe>
      </div>
      <div className="col-lg-6 col-sm-12 info-content">
        <span className="title">Entre em contato</span>
        <div className="subinfo">
          <span>Rodovia Hélio Smidt S/N</span>
          <span>AISP - Terminal de carga</span>
          <span>Guarulhos - SP</span>
        </div>
        <div className="subinfo">
          <span>(11) 2445-3367</span>
          <span>contato@crossdo.com.br</span>
        </div>
      </div>
    </Section6Wrapper>
  )
}
