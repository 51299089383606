import styled from 'styled-components'
import BackgImg from '../../assets/login/Login-bg.jpg'
import { shade } from 'polished'


export const MainWraper = styled.div`
  height:100hv;
  width: 100vw;
`
export const Container = styled.div`
  display: flex;  
  height: 100vh;
  width: 100vw;
  background-color: rgba(17, 39, 74, 0.7);
  /* align-items: stretch; */
  /* place-content: center; */
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div`
  height: 320px;
  max-width: 420px;
  margin-top: 100px;
  background-color: white;
  border-radius: 10px;
  flex:1;
  flex-direction: column;

`
export const Title = styled.h1`
  font-size: 14px;
  display: flex;  
  max-width: 300px;
  padding-top:40px;
  font-weight: bold;
  padding-left: 50px ;
  justify-content: flex-start;
  align-items: center;

`

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  input{
    width: 320px;
    height: 48px;
    padding: 14px;
    border: 2px solid #dcdcdc;
    border-radius: 5px;
    /* opacity:1; */
    
    margin-bottom: 20px;   
    

    & + h1 {
      margin-top: 14px;

    }    
    &::-webkit-input-placeholder {
      color: #a2a2a2;
      font-size: 14px;      
    }
    &:focus{
      outline-color: #F05035;
    }
  }
  h1{
    font-size: 14px;
  }
  button{
    background-color: #F05035;
    border-radius: 4px;
    color:#fff;
    height:40px;    
    border: none;
    width: 140px;
    font-size: bold;
    transition: background-color 0.2s;
    &:hover {
      background:${shade(0.2,'#F05035' )};
    }
    & + button{
      background-color: #fff;
      color: #F05035;
      font-size: bold;
      &:hover {
      background:#F05035;
      color:#fff;
    }
    }

    
  }
  

`
export const BContainer = styled.div`
  display: flex;
  padding:10px;
  flex-direction: row;
  justify-content: space-between;

` 

export const Background = styled.div`
  flex:1;  
  background: url(${BackgImg}) no-repeat center;
  background-size: cover;
    
` 