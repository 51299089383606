/* eslint-disable */
import React, {useState, useRef, useCallback,useEffect} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import {Form} from '@unform/web'
import Input from '../../../components/Input'
import DateInput from '../../../components/DatePicker/index'
import api from 'services/api'
import SelectInput from '../../../components/SelectInput/index'
import { MainWraper, Container, Title, Description, FormContainer, Group, Individual, BContainer } from './styles'
import Footer from '../../../components/Footer'
import getValidationErros from '../../../utils/getValidationErrors'
import {useHistory} from 'react-router-dom'
import { carPlateMask } from '../../../utils/cpfmask'
import { useAuth } from 'hooks/AuthContext'
import * as Yup from 'yup'
// import FormItem from '../../../components/FormItem'


const FirstStep = (props) =>{
  const history = useHistory()
  const formRef = useRef(null)
  const theDate = new Date()
  // theDate.setDate(new Date().getDate() +1)
  

  const {token, user} = useAuth

  const [blockDate, setBlockDate] = useState()
  const [blockAirlineDate, setBlockAirlineDate] = useState()
  const [isDisabledDate, setIsDisabledDate] = useState(true)
  const [isDisabledTime, setIsDisabledTime] = useState(true)
  
  const dockOptions = [
    { value: 'Ramp', label: 'Rampa' },
    { value: 'High', label: 'Alta' },    
  ]
  const vehicleOptionsRamp = [
    { value: 'Utility', label: 'Veículo utilitário' },
    { value: 'Van', label: 'Furgão (Van)' },    
  ]
  const vehicleOptionsHigh = [
    { value: 'Truck 3/4', label: 'Caminhão 3/4' },
    { value: 'Stump truck', label: 'Caminhão toco' },
    { value: 'Simple cart', label: 'Caminhão carreta Simples' },
    { value: 'Extended axle cart', label: 'Caminhão carreta eixo extendido' },
    { value: 'Van trunk', label: 'Van Baú' },    
  ]
  const handleDock = (dockType) => {
    switch(dockType){
      case 'Rampa':
        return 'Ramp'
      case 'Alta':
        return 'High'
      default: 
        return null
    }
  }

  const getClosedTimes = (openHour, closeHour, date) => {
    const blockedDatetimes = [];
    for(let hour = 0; hour < openHour; hour++) {
      // date = new Date();
      date.setHours(hour);
      blockedDatetimes.push(new Date(date));
    }
  
    for(let hour = closeHour; hour <= 23; hour++) {
      // const date = new Date();
      date.setHours(hour);
      blockedDatetimes.push(new Date(date));
    }
    return blockedDatetimes;
  }
  const filterPastTime = (time)=>{
    const initialTime = new Date(props.startTime)    
    const selectedDate = new Date(time)
    
    return initialTime.getTime() < selectedDate.getTime();
    
  }
  const handleAirlineTime = (time) => {
    new Date(time.setDate(props.startDate.getDate()))
    new Date(time.setMonth(props.startDate.getMonth()))
    new Date(time.setYear(props.startDate.getFullYear()))
    // console.log('time',time)
    // console.log('date',props.startDate)
    const thisDate = new Date(time)    
    thisDate.setHours(thisDate.getHours() + 1)
    setBlockAirlineDate(thisDate)
  }

  const handleFreeTime = async(date) => {
    try{    
      setIsDisabledTime(false)
      const {data} = await api.get('opening-time')
      // console.log(getClosedTimes(data.openHour, data.closeHour, date))
      setBlockDate(getClosedTimes(data.openHour, data.closeHour, date))
      
    }catch(err){}
  }

  const handleOption = (e) =>{
    // console.log(e)
    if( e === 'Ramp' ){
      return vehicleOptionsRamp
    }
    if( e === 'High'){
      return vehicleOptionsHigh
    }
    return null
  }

  useEffect(() => {
    
    // console.log(user?.basicInformation)
    window.scrollTo(0, 0);
  }, []);  

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  const { next } = props.navigation;
  const handleSubmitEtapa1 = useCallback(async(data) =>{    
    try{ 
      const schema = Yup.object().shape({
        reference: Yup.string().required('Referência obrigatória'),
        mail:Yup.string().required('Email obrigatório').email('Digite um email válido'),        
        date: Yup.string().required('Data obrigatória'),
        time: Yup.string().required('Tempo obrigatório'),
        deadlineDate: Yup.string().required('Data obrigatória'),
        // deadlineTime: Yup.string().required('Tempo obrigatório'),
        // carPlate: Yup.string().matches(/[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}|[A-Z]{3}[0-9]{4}/ || null,'Placa fora do padrão'),
        carPlate: Yup.lazy(value => !!value ? Yup.string().matches(/[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}|[A-Z]{3}[0-9]{4}/ || null,'Placa fora do padrão'): Yup.string()),
        // driverName: Yup.string().required('Nome do motorista obigatório'),
        dock: Yup.string().required('Tipo de doca obrigatória'),
        vehicle: Yup.string().required('Tipo de veículo obrigatório'),
        volumes: Yup.string().required('Numero de volumes obrigatório'),
      })
      
      await schema.validate(data,{
        abortEarly:false,
      })
      next()      

    }catch(err){   
      console.log(err)
      if(err?.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }      
    }
  },[history])


  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Gru Express"/>
      <Container>
        <Title> Etapa 1 de 2 - Informações gerais </Title>
        <Description>
          Selecione a data e horário de sua conveniência. Caso precise alterar por algum imprevisto, você poderá fazer aqui mesmo. Basta entrar no histórico e buscar horário disponível. Todos os campos são obrigatórios, exceto aqueles informados como opcionais.
        </Description>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmitEtapa1}>
            <h1><strong>Referência do cliente</strong></h1>
            <Input name="reference" value={props.reference} onChange={(e)=> props.setReference(e.target.value)}/>
            <Group>
              <Individual>
                <h1><strong>E-mail</strong></h1>
                <Input name="mail" value={props.mail} onChange={(e)=>props.setMail(e.target.value)}/>
              </Individual>
              
              <Individual>                
              </Individual>            
            </Group>
            
            {/* <FormItem name="reference" value={reference} onChange={setFormData} /> */}
            <Group>
              <Individual>
                <h1><strong>Tipo de doca</strong></h1>
                <SelectInput name="dock" placeholder="Escolher" value={props.dock} options={dockOptions} onChange={(e)=> {props.setDock({value: e.value, label: e.label}); setIsDisabledDate(false);}}/>
                {/* <SelectInput name="dock" placeholder="Escolher" value={props.dock} options={dockOptions} onChange={(e)=> {props.setDock({value: e.value, label: e.label}); setIsDisabledHt(false)}}/> */}
              </Individual>
              <Individual>
                <h1><strong>Veículo</strong></h1>
                <SelectInput name="vehicle" placeholder="Escolher" value={props.vehicle} options={handleOption(props.dock.value)} onChange={(e)=> props.setVehicle({value: e.value, label: e.label})}/>
              </Individual>
            </Group>
            <Group>
              <Individual>
                <h1><strong>Placa do veículo</strong> - Opcional</h1>
                <Input name="carPlate" placeholder="AAA0A00" value={props.carPlate} maxlength={8} onChange={(e)=> props.setCarPlate(e.target.value)}/>
              </Individual>
              <Individual>
                <h1><strong>Nome do motorista</strong> - Opcional</h1>
                <Input name="driverName" value={props.driverName} onChange={(e)=> props.setDiverName(e.target.value)}/>
              </Individual>
            </Group>
            <Group>
              <Individual>
                <h1><strong>Data de entrada</strong></h1>                
                <DateInput 
                  name="date"                  
                  minDate={theDate}
                  selected={props.startDate}
                  dateFormat="dd/MM/yyyy"                  
                  onChange={date => {props.setStartDate(date); handleFreeTime(date); props.setDeadlineDate('')}} 
                  disabled={isDisabledDate}   
                  // showTimeSelect   
                />
              </Individual>
              <Individual>
                <h1><strong>Horário de entrada</strong></h1>
                <DateInput
                  name="time"                   
                  dateFormat="HH:mm"               
                  timeFormat="HH:mm"
                  minDate={props.startDate}
                  selected={props.startTime}
                  onChange={date => {props.setStartTime(date); handleAirlineTime(date)}}
                  excludeTimes={blockDate}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Horário"
                  disabled={isDisabledTime}
                />
              </Individual>
            </Group>
            <Group>
              <Individual>
                  <h1><strong>Nº de Volumes</strong></h1>
                  <Input type="number" name="volumes" value={props.volumes} onChange={(e)=> props.setVolumes(e.target.value)}/>
              </Individual>
            </Group>
            <h1 style={{marginTop:38, marginBottom:16}}><strong>Deadline da companhia aérea</strong></h1>
            <Group style={{marginTop:26}}>
              <Individual>
                <h1><strong>Data</strong></h1>
                <DateInput 
                  name="deadlineDate"                  
                  minDate={props.startDate}
                  selected={props.deadlineDate} 
                  excludeTimes={blockDate}
                  filterTime={filterPastTime}  
                  showTimeSelect
                  timeIntervals={60}           
                  dateFormat="dd/MM/yyyy HH:mm"
                  timeFormat="HH:mm"
                  onChange={date => props.setDeadlineDate(date)}                                           
                />
              </Individual>
              
              <Individual style={{justifyContent:'center'}}>
                <div style={{width:450}}>
                  <h1><strong>Valor por volume - {props.prices && numberFormat(props.prices?.['Gru Express'].price)}</strong></h1>
                  
                  
                </div>                
              </Individual>
              
              {/* <Individual>
                <h1><strong>Hora</strong></h1>
                <DateInput
                  name="deadlineTime"                   
                  dateFormat="HH:mm"               
                  timeFormat="HH:mm"
                  minDate={props.startDate}
                  selected={props.deadlineTime}                  
                  onChange={date => props.setDeadlineTime(date)}
                  // includeTimes={[blockAirlineDate]}
                  filterTime={filterPastTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Horário"
                />  
              </Individual> */}
            </Group>              
              <Group>
                <Individual>
                </Individual>
                <BContainer>
                  <button>Avançar</button>
                </BContainer>
              </Group>
          </Form>
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default FirstStep;