import React, { useCallback, useEffect, useRef, useState} from 'react'
import NavBarSolo from '../../../../components/NavBarSolo'
import {TitleBar} from '../../../../components/TitleBar/index'
// import api from '../../../services/api'
import { MainWraper, Container, Title, FormContainer, Total, Box, BoxTitle, DataContainer, DataContent, BContainer } from './styles'
import Footer from '../../../../components/Footer'
import {format } from 'date-fns'
import { useLocation, useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import DateInput from '../../../../components/DatePicker/index'
import {Form} from '@unform/web'
import api from 'services/api'
import { useAuth } from 'hooks/AuthContext'
import { useToast } from 'hooks/ToastContext'




const DetalheCP = (props) =>{
  const formRef = useRef(null)
  const {token} = useAuth()
  const history = useHistory()
  const {addToast}= useToast()
  const { state: {location} } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const handleDock = (dockType) => {
    switch(dockType){
      case 'Ramp':
        return 'Rampa'
      case 'High':
        return 'Alta'
      default: 
        return null
    }
  }

  const handleVehicle = (dockType) => {
    switch(dockType){
      case 'Truck 3/4':
        return 'Caminhão 3/4'
      case 'Stump truck':
        return 'Caminhão toco'
      case 'Simple cart':
        return 'Caminhão carreta Simples'
      case 'Extended axle cart':
        return 'Caminhão carreta eixo extendido'
      case 'Van trunk':
        return 'Van Baú'
      case 'Utility':
        return 'Veículo utilitário'
      case 'Van':
        return 'Furgão (Van)'
      default: 
        return null
    }
  }

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
  console.log(location)

  const [blockedDate,setBlockedDate] = useState(null)
  const [newDate, setNewDate] = useState(new Date(location.checkInDatetime))

 
  const handleFreeTime = useCallback(async()=> {
    try{
      const {data} = await api.post('/schedule/unavailable',{
        date: newDate ,
        serviceDuration: location.serviceDuration,
        dockType: location.dockType,
      },{
        headers:{ Authorization: token}
      }) 
      const arr = data.map((date)=> new Date(date).setMinutes(0,0,0))
        
      setBlockedDate(arr)
    }catch(err){}
  },[location, token, newDate])

  const handleDateChange = async(item,onClose) =>{
    
    if(item.status ==='Scheduled'){
      try{
        await api.put(`/schedule-table/${item.id}`,
        {checkInDatetime:newDate.toISOString()},
        {headers: {Authorization: token}}) 
        // item.status = 'Cancelled'       
        
        addToast({
          type:'success',
          title:'Horário alterado',
          description: 'Seu horário foi alterado com sucesso.'
        })
        history.push('/historico')
        onClose()        
        setVisible(false)
      }catch(err){
        console.log(err)
      addToast({
        type:'error',
        title:'Erro',
        description:'Não foi possível alterar o horário, entre em contato com a equipe CrossDo'
      })
      onClose()
      setVisible(false)
    }
  }
}
useEffect(()=>{
  handleFreeTime()
},[handleFreeTime])

  const handleAlert = ()=>{
    
    confirmAlert({  
      customUI: ({ onClose }) => {
        
        return (
          <div style={{display:'flex',flex:1, alignItems:'center', flexDirection:'column'}} >
            <h1 style={{fontSize:24}}>Alterar Data/hora</h1>
            <FormContainer>
            <Form ref={formRef} onSubmit={()=>handleDateChange(location,onClose)}  >
            
              <DateInput 
                name="checkinDatatime"                  
                minDate={new Date()}
                selected={newDate} 
                excludeTimes={blockedDate}
                showTimeSelect                
                timeIntervals={60}           
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                onChange={date => setNewDate(date)}              
             />
             
              <BContainer style={{display:'flex', marginTop:30 }}>
              <button onClick={()=>{onClose();setVisible(false)}}>Não</button>
              <button
                onClick={(e) => {  
                }}
              >
              Sim, Alterar!
            </button>
            </BContainer>
            
            </Form>
            </FormContainer>
          </div>
        );
      }
    });
  }
  const [visible,setVisible] = useState(false)


  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Detalhes > Cross Park"/>
      <Container>
        <Title>Detalhe de agendamento</Title>
        {/* <Description>
        Aqui vai algum explicativo, com informações adicionais que julgarem pertinentes. Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
        </Description> */}
        <Total>
          <Title style={{color:'#fff'}}>Valor do pedido</Title>
          <Title style={{color:'#fff'}}>{numberFormat(location.totalPrice)}</Title>
        </Total>
        <Title style={{marginTop:20}}>Descrição dos itens agendados</Title>
        <BoxTitle>
          <Title style={{color:'#fff'}}>Informações gerais</Title>
        </BoxTitle>
        <Box>
        <DataContainer>
            <h1>ID</h1>
            <h1>{location.id}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Referência do cliente</h1>
            <h1>{location.clientReference}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Data de Entrada</h1>
            <h1>{format(new Date(location.checkInDatetime), 'dd/MM/yyyy')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Horário de entrada</h1>
            <h1>{format(new Date(location.checkInDatetime), 'HH:mm')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Placa do veículo</h1>
            <h1>{location.vehicleLicensePlate}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nome do motorista</h1>
            <h1>{location.driverName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tipo de doca</h1>
            <h1>{handleDock(location.dockType)}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Veículo</h1>
            <h1>{handleVehicle(location.vehicle)}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tempo Contratado</h1>
            <h1>{location.serviceDuration}</h1>
          </DataContainer> 
          <DataContent/>
          <BContainer>           
          </BContainer>
        </Box>

        <BoxTitle style={{marginTop:50}}>
          <Title style={{color:'#fff'}}>Adicionais</Title>
        </BoxTitle>
        <Box>
          <DataContainer>
            <h1>Empilhadeira</h1>
            <h1>Até 100Kg/vol</h1>
          </DataContainer>
          <DataContainer>
            <h1>Paleteira</h1>
            <h1>Até 100Kg/vol</h1>
          </DataContainer>  
          <DataContent/>
                  
        </Box>
        {location.status === 'Scheduled' &&<BContainer>
          <button onClick={()=> {setVisible(true)}}>Alterar Data/Hora</button>
          {visible && handleAlert()}
        </BContainer>}
        <FormContainer>
          
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default DetalheCP;