import styled from 'styled-components'
import BackgImg from '../../assets/cadastrar/Park.jpg'
import { shade } from 'polished'


export const MainWraper = styled.div`
  height:100hv;
  width: 100vw;
`
export const Container = styled.div`
  display: flex;  
  /* height: 100vh; */
  width: 100vw;
  background-color: rgba(17, 39, 74, 0.7);
  /* align-items: stretch; */
  /* place-content: center; */
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div`
  height: 620px;
  max-width: 420px;
  margin-top: 150px;
  margin-bottom: 100px;
  background-color: white;
  padding: 0 50px;
  border-radius: 10px;
  flex:1;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
        max-width:350px ;
      }

`
export const Menu = styled.div`  
  /* padding: 20px; */
  align-items:center;
  flex:1;
  display: flex;    
  flex-direction: row;  
  justify-content: space-around;
  
`
export const MenuLogin = styled.div`
  justify-content:center;
  padding-bottom: 20px;
  margin-bottom: 10px;
  /* border-bottom: 1px solid rgba(162, 162, 162, 0.4); */
  /* padding-top:25px; */
  margin-top: 40px;
  font-weight: bold;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  
`
export const MenuCadastro = styled.div`
  padding-bottom: 20px;
  padding-top:14px;
  border-left: 1px solid rgba(162, 162, 162, 0.4);
  flex: 1;
  display: flex;
  justify-content: center;
  
`

export const Log = styled.button`    
  font-weight: bold;
  font-size: 16px;  
  border:none;
  background-color: Transparent; 
` 
export const Persona = styled.div`
  display:flex;
  flex: 1;
  font-weight: bold;
  justify-content: space-between;
  flex-direction: row;
  font-size: 14px; 
  
  button{
    border:none;
    background-color: Transparent; 
    font-weight: bold;
    color:#a2a2a2;
    
  }

`

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  input{
    width: 320px;
    height: 48px;
    padding: 14px;
    border: 2px solid #dcdcdc;
    border-radius: 5px;
    /* opacity:1; */    
    margin-bottom: 30px;     

    & + h1 {
      margin-top: 14px;

    }    
    &::-webkit-input-placeholder {
      color: #a2a2a2;
      font-size: 14px;      
    }
    &:focus{
      outline-color: #F05035;
    }
  }
  h1{
    font-size: 14px;
  }
  button{
    background-color: #F05035;
    border-radius: 4px;
    color:#fff;
    height:40px;    
    border: none;
    width: 140px;
    font-size: bold;
    transition: background-color 0.2s;
    &:hover {
      background:${shade(0.2,'#F05035' )};
    }
    & + button{
      background-color: #fff;
      color: #F05035;
      font-size: bold;
      &:hover {
      background:#F05035;
      color:#fff;
    }
  }
    
  }  

`
export const BContainer = styled.div`
  display: flex;
  /* padding:10px; */
  flex:1;
  margin-top: 20px;
  flex-direction: row;
  justify-content: flex-start;

` 
export const Background = styled.div`
  flex:1;  
  background: url(${BackgImg}) no-repeat center;
  background-size: cover;
    
` 