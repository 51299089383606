import React from 'react'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'
import img from './../assets/img/Section3-img-col-dir.jpg';
import img2 from './../assets/img/Section3-img-col-esq.jpg';

const Section3Wrapper = styled.section`
  height: 100%;
  min-height: 704px;
  width: 100%;
  background-color: var(--primary-color-10);
  margin: 0;
  align-items: center;

  @media only screen and (max-width: 992px) {
    min-height: unset;
  }
  .left-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--primary-color-10);
    padding: 1rem 7.5%;
    @media only screen and (max-width: 768px) {
      padding: 2rem 7.5vw;
    }
    span {
      &.title {
        color: var(--primary-color-7);
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 20px;
        line-height: 1.2;
        @media only screen and (max-width: 768px) {
          font-size: 32px;
        }
      }
      &.subtitle {
        color:  var(--primary-color-11);
        font-size: 16px;
        font-weight: 300;
        max-width: 450px;
        text-align: justify;
        text-justify: inter-word;
        padding-bottom: 12px;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }
`
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
    max-height: 250px;
    max-width: 45vw
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

const ImageContent = styled.div`
  background-image: url(${img});
  background-size: cover;
  height: 704px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default function Section3() {

  return (
    <Section3Wrapper className="row" data-aos="fade-up">
      <div className="col-md-6 col-sm-12 left-content">
        <span className="title">Sua carga de exportação em boas mãos</span>
        <span className="subtitle">
          Sua carga para exportação merece <span className="font-weight-bold">atenção especial</span>.
        </span>
        <span className="subtitle">
          Foi pensando assim que criamos a <span className="font-weight-bold">CrossDo</span>, uma infraestrutura completa para receber sua carga com toda segurança, agilidade e cuidado que ela merece.
        </span>
        <span className="subtitle">
          Localizada dentro do sítio aeroportuário da <span className="font-weight-bold">GRU AIRPORT</span>, ao lado do Terminal 2 de exportação e a 400 metros do Terminal 1 de exportação nossa empresa lhe dará todo apoio, consultoria e transporte.
        </span>
        <span className="subtitle">
          Sabemos o quanto é importante cumprir todas as exigências do mercado internacional, no que se refere à legislação vigente, por isso, operamos dentro dos padrões da <span className="font-weight-bold">OMA</span> (Organização Mundial das Aduanas) e do programa <span className="font-weight-bold">OEA</span> (Operador Econômico Autorizado).
        </span>
      </div>
      <ImageContent className="col-md-6 col-sm-12"></ImageContent>
      <ImageContainer>
        <Image src={img} thumbnail />
        <Image src={img2} thumbnail />
      </ImageContainer>
    </Section3Wrapper>
  )
}
