import React, { useEffect} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainWraper, Container, Box, Title, Icon, Description, } from './styles'
import Footer from '../../../components/Footer'
import {useAuth} from '../../../hooks/AuthContext'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const SchedulingConfirmation = (props) =>{
    const { user } = useAuth() 
  // const { go } = props.navigation; 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainWraper>
      <NavBarSolo/>   
      <Container>
        <Box>
          <Icon>
            <FontAwesomeIcon size="3x" color="#028E1E" icon={faCheckCircle}/>
          </Icon>
          <Title>
            <h1>Confirmação de agendamento</h1>
          </Title>
          <Title>
            <h2>Olá {user.basicInformation.name}</h2>
          </Title>
          <Description>
            <h1>Seu agendamento foi confirmado.*</h1>
            <h1>Aguarde nosso contato para finalização do processo.</h1>
            <h1 style={{fontSize:12, fontStyle:'oblique', color:'red'}}>(*) Você receberá um e-mail de confirmação, caso não receba verifique sua caixa de spam.</h1>
          </Description>

        </Box>
      </Container>
      <Footer />  
    </MainWraper>
  )
}
export default SchedulingConfirmation;