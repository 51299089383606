import React, {useState, useEffect} from 'react'
import { useStep } from 'react-hooks-helper'

import FirstStep from './FirstStep/index'
import Review from './Review'
import SchedulingConfirmation from './SchedulingConfirmation'
import api from 'services/api'
const steps = [
  { id: 'CFCFirstStep'},
  { id: 'CFCReview'},
  { id: 'CFCConfirmation'}  
]

// const defaultData = {
//   reference,
//   date,
//   time,
//   carPlate,
//   driverName,
//   dock,
//   vehicle,
//   hiredTime,
//   forkLift,
//   paletTruck,
// }

const CFCareMultiStep = ({images}) =>{
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate()+1)
  // function roundToHour(date) {
  //   const p = 60 * 60 * 1000; // milliseconds in an hour
  //   return new Date(Math.round(date.getTime() / p ) * p);
  // }
  const [prices, setPrices] = useState()

  useEffect(()=>{
    async function fetchPrices(){
      try{
        
        const {data:{data}} = await api.get('/prices')
        let a = data.reduce((obj, item) => ({...obj, [Object.keys(item)[1]]: item.[Object.keys(item)[1]]}), {})
        if(data)
        setPrices(a)
      }catch(err){}
    }
    fetchPrices()
  },[])


  const [reference, setReference] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [carPlate, setCarPlate] = useState('')
  const [driverName, setDiverName] = useState('')
  const [seal, setSeal] = useState('')
  const [hiredTime, setHiredTime] = useState('')
  const [dock, setDock] = useState('')
  const [vehicle, setVehicle] = useState('')
  const [deadlineDate, setDeadlineDate] = useState(null)
  const [deadlineTime, setDeadlineTime] = useState(null)

  const {step, navigation} = useStep({ initialStep: 0, steps })
  const {id} = step
  
  const props = {  
    navigation,
    prices, setPrices,
    reference, setReference,
    startDate, setStartDate,
    startTime, setStartTime,
    carPlate, setCarPlate,
    driverName, setDiverName,
    seal, setSeal, 
    hiredTime, setHiredTime,
    vehicle, setVehicle, 
    dock, setDock,
    deadlineDate, setDeadlineDate,
    deadlineTime, setDeadlineTime    
  }

  switch(id){
    case 'CFCFirstStep':
      return <FirstStep {...props}/>
    case 'CFCReview':
      return <Review {...props} />
    case 'CFCConfirmation':
      return <SchedulingConfirmation {...props} />
    default:
      return null
  }

}
export default CFCareMultiStep