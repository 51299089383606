import React, {useCallback, useRef} from 'react'
import { MainWraper, Content, Background, Container, LoginForm, BContainer, Title,  } from './styles'

import Navbar from './../../patterns/Navbar'
import Input from '../../components/Input'
import {Form} from '@unform/web'
import {  useHistory, useLocation  } from "react-router-dom";
import {useAuth} from '../../hooks/AuthContext'
import getValidationErros from '../../utils/getValidationErrors'
import * as Yup from 'yup'
import { useToast } from 'hooks/ToastContext'



const PasswordChange = (props)  => {
  const location = useLocation();
  const {signIn} = useAuth()
  const history = useHistory()
  const { addToast } = useToast()
    
  
  const formRef = useRef(null)
 const handleSubmitLogin = useCallback(async(data) =>{
    try{
      const schema = Yup.object().shape({          
        password: Yup.string().required('Senha obrigatória').min(6,'Mínimo de 6 dígitos'),   
      })
      await schema.validate(data,{
        abortEarly:false,
      })

          
      await signIn({
        email:location.state.state,
        password: data.password
      })
      addToast({
        type: 'success',
        title: 'Login efetuado com sucesso',
        
      })
      
    }catch(err){    

      if(err.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro ao fazer login, verifique as credenciais.',
      })
    }

 }, [signIn, location, addToast])

 
 

  return (
    <MainWraper>
      <Navbar/>
      <Background>
        <Container>
          <Content>
            <Title>
              RECUPERAÇÃO DE SENHA
            </Title>
            <Title style={{fontWeight:300, paddingTop:10}}>
            Verifique a caixa de entrada do seu e-mail. Você receberá uma senha provisória para acessar a sua conta. Para trocar a senha provisória por uma definitiva, basta acessar o seu perfil.
            </Title>
            <LoginForm>
              <Form ref={formRef} onSubmit={handleSubmitLogin}>                
                <h1>Senha</h1>
                <Input name="password" type="password" placeholder="Informe a senha"/>                
                <BContainer>
                  <button >Entrar</button>
                  <button onClick={(e)=>{e.preventDefault(); history.push('/')}}>Cancelar</button>
                </BContainer>
              </Form>
            </LoginForm>
          </Content>
        </Container>

      </Background>
    </MainWraper>
  )
}

export default PasswordChange;