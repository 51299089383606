import React, { useCallback, useEffect, useState} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import api from '../../../services/api'
import { MainWraper, Container, Title,  FormContainer, Total, Box, BoxTitle, DataContainer, DataContent, BContainer, SumBox } from './styles'
import Footer from '../../../components/Footer'
import {format } from 'date-fns'
import {useAuth} from '../../../hooks/AuthContext'
import useScript from '../../../hooks/useScript'

// import getValidationErros from '../../../utils/getValidationErrors'
// import * as Yup from 'yup'

const Review = (props) =>{
  // useScript("https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js")
  useScript("https://assets.pagar.me/checkout/1.1.0/checkout.js")
  // console.log(window.PagarMeCheckout)
  const { token, user } = useAuth() 
  const { go, next } = props.navigation;
  const [isLoading, setIsLoading] = useState(true)
  // const handleCancel = useCallback(async(id) =>{
  //   try{
  //     await api.patch(`/service/checkout-cancelled/${id}`,{},{headers: {Authorization: token}})
  //   }catch(err){

  //   }
  // },[token])

  // const handleSuccess = useCallback(async(id)=>{
  //   try{
  //     await api.patch(`/service/checkout-finished/${id}`,{},{headers: {Authorization: token}})
  //   }catch(err){

  //   }
  // },[token])
  
  const handlePayment = useCallback(async() =>{
    setIsLoading(true)
    try{
      const data = {
        isValidation: true,
        isBillingThePaymentMethod: false,
        service:{
          clientReference: props.reference,
          checkInDate: props.startDate.toISOString(),
          checkInTime: props.startTime.toISOString(),
          vehicleLicensePlate: props.carPlate,
          driverName: props.driverName,
          dockType: props.dock.value,
          vehicle: props.vehicle.value,
          serviceDuration: Number(props.hiredTime),
          isForkliftIncluded: props.forkLift,
          isPalletTruckIncluded: props.paletTruck,
        },
      }

      const response = await api.post('/service/cross-park', data, {headers: {Authorization: token}})
      
      const date1 = new Date()
      const date= (new Date(date1.getTime() + 20*60000)).toISOString()
      
      const data2 = data
      data2.isValidation = false
      let payment
      response.data.message === 'IsValidButCantEmitBoleto' ?
       payment = 'pix,credit_card' 
       : payment = 'pix,boleto,credit_card'
      if(response.status === 200){      
        var checkout = await new window.PagarMeCheckout.Checkout({
          encryption_key: 'ek_live_w3AahxYuP9x16rz70lFafuQoVbS1B7',
          payment_methods: payment,
          pix_expiration_date: date,
          success: async(data)=>
            {
              
              data2.transaction = data
              const resp = await api.post('/service/cross-park', data2, {headers: {Authorization: token}})
              if(resp.status === 201){              
                props.setTransaction(resp.data.Transaction)
                next()
              }
              
          }
          ,
          error: (err) => 
            console.log(err)
          ,
          close: function() {
            console.log('The modal has been closed.');
          }
        });   
        console.log('here')
        checkout.open({
          amount: props?.prices && (Number(props?.prices?.['Cross Park']?.price) + (props?.prices?.['Cross Park']?.additionalPrice * (props.hiredTime -1)) + (props.forkLift && (props?.prices?.['Forklift']?.price * props?.hiredTime)) )*100,
          createToken: 'false',
          customerData: 'true', 
          pixExpirationDate: date,
          items: [
            {
              id: '1',
              title: 'Cross Park',
              unit_price: props?.prices && (Number(props?.prices?.['Cross Park']?.price) + (props?.prices?.['Cross Park']?.additionalPrice * (props.hiredTime -1)) + (props.forkLift && (props?.prices?.['Forklift']?.price * props?.hiredTime)) )*100,
              quantity: 1,
              tangible: false
            },
            
          ]
        })

    }
      
    //const response = await api.post('/service/cross-park', data, {headers: {Authorization: token}})
    // console.log('Response',response)
    //if(response.status === 201){
      // const code = response.data.checkoutCode
      // window.PagSeguroLightbox(code, {success: ()=> {handleSuccess(response.data.service.id);next()} ,abort: ()=> handleCancel(response.data.service.id)})
     // next()
   // }
  }catch(err){

  }finally{
    setTimeout(()=>{        
      setIsLoading(false)
    },3000)
  }
  },[props, token, next])

  const handleBilling = useCallback(async() =>{
    setIsLoading(true)
    try{
    const data = {
      isBillingThePaymentMethod: true,
      service:{
        clientReference: props.reference,
        checkInDate: props.startDate.toISOString(),
        checkInTime: props.startTime.toISOString(),
        vehicleLicensePlate: props.carPlate,
        driverName: props.driverName,
        dockType: props.dock.value,
        vehicle: props.vehicle.value,
        serviceDuration: Number(props.hiredTime),
        isForkliftIncluded: props.forkLift,
        isPalletTruckIncluded: props.paletTruck,
      },
    }    
    
    const response = await api.post('/service/cross-park', data, {headers: {Authorization: token}})
    // console.log('Response',response)
    if(response.status === 201){
      next()
    }
  }catch(err){

  }finally{
    setTimeout(()=>{
      setIsLoading(false)
    },3000)
  }
  },[props, token, next])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const finalPrice = useCallback(()=>{     
    try{
      const price = props?.prices && numberFormat(Number(props?.prices?.['Cross Park']?.price) + (props?.prices?.['Cross Park']?.additionalPrice * (props.hiredTime -1)) + (props.forkLift && (props?.prices?.['Forklift']?.price * props?.hiredTime)) )
      return price
    } catch(err){}
  
  }, [props.hiredTime, props.prices, props.forkLift])

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Park"/>
      <Container>
        <Title>Resumo de agendamento</Title>
        {/* <Description>
        Aqui vai algum explicativo, com informações adicionais que julgarem pertinentes. Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
        </Description> */}
        <Total>
          <Title style={{color:'#fff'}}>Valor a Pagar</Title>
          <Title style={{color:'#fff'}}>{finalPrice()}</Title>
        </Total>
        <Title style={{marginTop:20}}>Descrição dos itens agendados</Title>
        <BoxTitle>
          <Title style={{color:'#fff'}}>Informações gerais</Title>
        </BoxTitle>
        <Box>
          <DataContainer>
            <h1>Referência do cliente</h1>
            <h1>{props.reference}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Data de Entrada</h1>
            <h1>{format(props.startDate, 'dd/MM/yyyy')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Horário de entrada</h1>
            <h1>{format(props.startTime, 'HH:mm')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Placa do veículo</h1>
            <h1>{props.carPlate}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nome do motorista</h1>
            <h1>{props.driverName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tipo de doca</h1>
            <h1>{props.dock.label}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Veículo</h1>
            <h1>{props.vehicle.label}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tempo Contratado</h1>
            <h1>{props.hiredTime} h</h1>
          </DataContainer> 
          <SumBox>
            <h1>Subtotal</h1>
            <h1>{numberFormat(Number(props?.prices?.['Cross Park']?.price) + (props?.prices?.['Cross Park']?.additionalPrice * (props.hiredTime -1)))}</h1>
          </SumBox>
          <DataContent/>
          <BContainer>
            <button onClick={()=>go('CPFirstStep')}>Editar</button>
          </BContainer>
        </Box>

        <BoxTitle style={{marginTop:50}}>
          <Title style={{color:'#fff'}}>Adicionais</Title>
        </BoxTitle>
        <Box>
          <DataContainer>
            {props.forkLift && <><h1>Empilhadeira</h1>
             <h1>Até 2500Kg/vol</h1></>}
          </DataContainer>
          <DataContainer>
            {props.paletTruck && <><h1>Paleteira</h1>
              <h1>Até 200Kg/vol</h1></>}
          </DataContainer>  
          <SumBox>
            <h1>Subtotal</h1>
            <h1>{numberFormat(props.forkLift && (props?.prices?.['Forklift']?.price * props?.hiredTime))}</h1>
          </SumBox>
          <DataContent/>
          <BContainer>
            <button onClick={()=>go('CPSecondStep')}>Editar</button>
          </BContainer>        
        </Box>

        <Total style={{height:70, marginTop:40}}>
          <div style={{display:'flex',flexDirection:'row', alignSelf:'center' }}>
          <input style={{marginTop:2}} onChange={()=>setIsLoading((state)=>!state)} type="checkbox"></input>
          <Title style={{color:'#fff', marginLeft: 8, textAlign:'center'}}>Li e confirmo os itens agendados</Title>
          </div>
          <div>
          {user.isBillingAllowed && <button disabled={isLoading} onClick={()=>handleBilling()}>Faturamento</button>}
          <button style={{marginLeft:10}} disabled={isLoading} onClick={()=>handlePayment()}>Pagamento</button>
          </div>
        </Total>
        <div style={{fontWeight:'bold', display:'flex', justifyContent:'flex-end', marginTop:12}}>
          O agendamento será realizado a partir da confirmação de pagamento, que será enviado um link para o e-mail cadastrado.
        </div>
        <FormContainer>
          
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default Review;