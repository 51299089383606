import React, {useCallback, useRef, useState} from 'react'
import { MainWraper, Content, Background, Container, Menu, MenuLogin, LoginForm, BContainer, Persona } from './styles'
import { useHistory} from 'react-router-dom'
import NavbarSolo from './../../components/NavBarSolo'
import Input from '../../components/Input'
import {Form} from '@unform/web'
import api from '../../services/api'
import {useAuth} from '../../hooks/AuthContext'
import {cpfMask, cnpjMask} from '../../utils/cpfmask'
import * as Yup from 'yup'
import getValidationErros from '../../utils/getValidationErrors'
import {useToast} from '../../hooks/ToastContext'

const MyData = ()  => {

  const {signIn,user, token} = useAuth()  
  const history = useHistory()
  const formRef = useRef(null)
  const {addToast} = useToast()

 const handleSubmitRegisterJ = useCallback(async(data) =>{
    try{

      data.cnpj= data.cnpj.split(".").join("");
      data.cnpj = data.cnpj.split("-").join("");
      data.cnpj = data.cnpj.split("/").join("");

      const schema = Yup.object().shape({
        razao_social: Yup.string().required(),
        cnpj: Yup.string().required('CNPJ obrigatório').min(14),
        email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
        password: Yup.string(),
      })
      await schema.validate(data,{
        abortEarly:false,
      })

      await api.put(`/client/${user.id}`, {        
        basicInformation: {
          name: data.razao_social,
          email: data.email,
          document: data.cnpj,
          password: data.password, 
        }
      },
        {headers: {Authorization: token}},
      )    

      setTimeout(()=>{
        signIn({
          email: data.email,
          password: data.password
        })      
      },1000)

      addToast({
        type: 'success',
        title: 'Dados Alterados com sucesso',
      })
  
      history.push('/meus-dados')
      
    }catch(err){
      if(err.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }

      addToast({
        type: 'error',
        title: 'Erro ao altear dados',
        description: 'Ocorreu um erro ao alterar os dados, verifique os campos novamente.',
      })

    }

 }, [user?.id, token, addToast, history, signIn]) 

 const handleSubmitRegisterF = useCallback(async(data) =>{
  try{

      data.cpf= data.cpf.split(".").join("");
      data.cpf = data.cpf.split("-").join("");

    const schema = Yup.object().shape({
      name: Yup.string().required('Nome obrigatório'),
      cpf: Yup.string().required('CPF obrigatório').min(10),
      email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
      password: Yup.string().required('Senha obrigatória').min(6,'Minimo de 6 digitos'),
    })
    await schema.validate(data,{
      abortEarly:false,
    })

    await api.put(`/client/${user.id}`, {      
      basicInformation: {
        name: data.name,
        email: data.email,
        document: data.cpf,
        password: data.password, 
      }
    },
    {headers: {Authorization: token}}
    )    

    setTimeout(()=>{
      signIn({
        email: data.email,
        password: data.password
      })      
    },1000)

    addToast({
      type: 'success',
      title: 'Dados Alterados com sucesso',
    })

    history.push('/meus-dados')
    
  }catch(err){

    if(err.name=== 'ValidationError'){
      const errors = getValidationErros(err)
      formRef.current?.setErrors(errors)
    }

    addToast({
      type: 'error',
      title: 'Erro ao altear dados',
      description: 'Ocorreu um erro ao alterar os dados, verifique os campos novamente.',
    })

  }

}, [user?.id, signIn, history, token, addToast]) 

 const [value, setValue] = useState(cpfMask(user?.basicInformation.document)) 
 const [valueCNPJ, setValueCNPJ] = useState(cnpjMask(user?.basicInformation.document))

  return (
    <MainWraper>
      <NavbarSolo/>
      <Background>
        <Container>
          <Content>
            <Menu>
              <MenuLogin>
                MEUS DADOS
              </MenuLogin>
            </Menu>    
            <Persona>
              {user?.type === 'PJ' ?<span>Pessoa jurídica</span>
               :<span>Pessoa física</span>}
            </Persona>        
            {user && user?.type === 'PJ' && <LoginForm>
              <Form ref={formRef} onSubmit={handleSubmitRegisterJ}>
                <h1>Razão Social</h1>
                <Input name="razao_social" defaultValue={user.basicInformation.name} placeholder="Informe a Razão Social"/>
                <h1>CNPJ</h1>
                <Input maxLength={18} name="cnpj" value={valueCNPJ} onChange={(e)=>setValueCNPJ(cnpjMask(e.target.value))}  placeholder="Informe o CNPJ"/>
                <h1>E-mail</h1>
                <Input name="email" defaultValue={user.basicInformation.email} placeholder="Informe o e-mail"/>
                <h1>Senha</h1>
                <Input name="password" type="password" placeholder="Informe a senha"/>
                
                <BContainer>
                  <button >Salvar alterações</button>
                </BContainer>
              </Form>
            </LoginForm>}
            {user.type==='PF' && <LoginForm>
              <Form ref={formRef} onSubmit={handleSubmitRegisterF}>
                <h1>Nome</h1>
                <Input defaultValue={user.basicInformation.name} name="name" placeholder="Informe o nome"/>
                <h1>CPF</h1>
                <Input maxLength={14} value={value} onChange={(e)=>setValue(cpfMask(e.target.value))}  name="cpf" placeholder="Informe o CPF"/>
                <h1>E-mail</h1>
                <Input name="email" defaultValue={user.basicInformation.email} placeholder="Informe o e-mail"/>
                <h1>Senha</h1>
                <Input name="password" type="password" placeholder="Informe a senha"/>
                
                <BContainer>
                  <button >Salvar alterações</button>
                  
                </BContainer>
              </Form>
            </LoginForm>}
            

          </Content>
        </Container>

      </Background>
    </MainWraper>
  )
}

export default MyData;