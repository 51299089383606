import React, {useEffect} from 'react'
import { useAuth } from "hooks/AuthContext"
import api from 'services/api'
export const CheckAuth = () =>{
  const { token, user, signOut} = useAuth()
  useEffect(()=>{
    async function checkAuth (){
      try{
        await api.get(`/client/${user.id}`, {headers: {Authorization: token}})        
      }catch(err){
        signOut()
      }
    }
    
    setTimeout(()=>{
      checkAuth()
    },2000)
  },[signOut, token, user])
  return (<></>)  
}
