import Modal from 'react-bootstrap/Modal'

export default function ModalVideo(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/4OuXqH30w74" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Modal.Body>
    </Modal>
  );
}