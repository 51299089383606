import React, { useCallback, useEffect, useState} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import api from '../../../services/api'
import { MainWraper, Container, Title, GroupB, FormContainer, Box, BoxTitle, DataContainer, DataContent, BContainer } from './styles'
import Footer from '../../../components/Footer'
import {format } from 'date-fns'
import {useAuth} from '../../../hooks/AuthContext'
import useScript from '../../../hooks/useScript'

// import getValidationErros from '../../../utils/getValidationErrors'
// import * as Yup from 'yup'

const Review = (props) =>{
  useScript("https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js")
  const { token, user } = useAuth() 
  const { next, previous } = props.navigation;
  const [isLoading, setIsLoading] = useState(false)
  
  const handleBilling = useCallback(async()=>{
    setIsLoading(true)
    try{
    
      const data = {
        isBillingThePaymentMethod: true,
        service: {
        clientReference: props.reference,
        checkInDate: props.startDate.toISOString(),
        checkInTime: props.startTime.toISOString(),
        vehicle: props.vehicle.value,
        vehicleLicensePlate: props.carPlate,
        driverName: props.driverName,
        dockType: props.dock.value,
        airlineDealineDate: props.deadlineDate ? props.deadlineDate?.toISOString(): null,
        airlineDeadlineTime: props.deadlineDate ? props.deadlineDate?.toISOString(): null,
        seal: props.seal,}
      }

    const response = await api.post('/service/cross-full-care', data, {headers: {Authorization: token}})

    if(response.status === 201){
      next()
    }
  }catch(err){

  }finally{
    setTimeout(()=>{        
      setIsLoading(false)
    },3000)
  } 

  },[props, token, next])



  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, []);

  // const numberFormat = (value) =>
  // new Intl.NumberFormat('pt-BR', {
  //   style: 'currency',
  //   currency: 'BRL'
  // }).format(value);
  
  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Care"/>
      <Container>
        {console.log('user:',user)}
        <Title>Resumo de agendamento</Title>
        {/* <Description>
          Aqui vai algum explicativo, com informações adicionais que julgarem pertinentes. Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
        </Description> */}
        {/* <Total>
          <Title style={{color:'#fff'}}>Valor a Pagar</Title>
          <Title style={{color:'#fff'}}>{props.prices && numberFormat(props?.prices.['Cross Full Care'].price)}</Title>
        </Total> */}
        <Title style={{marginTop:20}}>Descrição dos itens agendados</Title>
        <BoxTitle>
          <Title style={{color:'#fff'}}>Informações gerais</Title>
        </BoxTitle>
        <Box>
          <DataContainer>
            <h1>Referência do cliente</h1>
            <h1>{props.reference}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Data de Entrada</h1>
            <h1>{format(props.startDate, 'dd/MM/yyyy')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Horário de entrada</h1>
            <h1>{format(props.startTime, 'HH:mm')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Placa do veículo</h1>
            <h1>{props.carPlate}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nome do motorista</h1>
            <h1>{props.driverName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tipo de doca</h1>
            <h1>{props.dock.label}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Veículo</h1>
            <h1>{props.vehicle.label}</h1>
          </DataContainer>          
          <DataContent/>
          <h1 style={{fontSize:16, marginTop:16, marginBottom:18}}>Deadline da companhia aérea</h1>
          <DataContainer>
            <h1>Data</h1>
            <h1>{props.deadlineDate ? format(props.deadlineDate, 'dd/MM/yyyy'): `Sem data`}</h1> 
          </DataContainer>
          <DataContainer>
            <h1>Horário</h1>
            <h1>{props.deadlineDate ? format(props.deadlineDate, 'HH:mm') : `Sem horário`}</h1>
          </DataContainer>
          
        </Box>        
        <GroupB>
          <div/>
          <BContainer>
            <button onClick={(e)=>{e.preventDefault();previous()}}>Voltar</button>
            <button disabled={isLoading} onClick={()=>{handleBilling()}}>Solicitar</button>
            {/* <button disabled={isLoading} onClick={()=>{handlePayment()}}>Pagamento</button> */}
          </BContainer>
        </GroupB>
        <FormContainer>
          
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default Review;