import React, { useEffect, useState} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainWraper, Container, Box, Title, Icon, Description, } from './styles'
import Footer from '../../../components/Footer'
import {useAuth} from '../../../hooks/AuthContext'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {Button, Input} from 'antd'
import QRCode from 'react-qr-code'
// import { set } from 'date-fns';
const {Search} = Input
const SchedulingConfirmation = (props) =>{
    const { user } = useAuth() 
  // const { go } = props.navigation; 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBoleto = ()=>{
    window.open(props.transaction.boleto_url)
  }

  const [isClicked, setIsClicked] = useState(false)

  return (
    <MainWraper>
      <NavBarSolo/> 
      {console.log(props.transaction.status === 'waiting_payment')}  
      { props.transaction.status === 'waiting_payment' && props.transaction.payment_method === 'pix' && <Container>
        <Box>
          <Icon>
            <FontAwesomeIcon size="3x" color="#028E1E" icon={faCheckCircle}/>
          </Icon>
          <Title>
            <h1>Confirmação de agendamento</h1>
          </Title>
          <Title>
            <h2>Olá {user.basicInformation.name}</h2>
          </Title>
          <Description>
            <h1>Agora só falta finalizar seu pagamento!</h1>
            <h1>Após aprovado aguarde nosso contato para finalização do processo.</h1>
            <h1 style={{fontSize:12, fontStyle:'oblique', color:'red'}}>(*) Você receberá um e-mail de confirmação, caso não receba verifique sua caixa de spam.</h1>
            <h1 style={{fontWeight:'bold', marginTop:40}}><strong >Leia o QRcode abaixo ou copie o código para pagar o com PIX.</strong></h1>
          </Description>
          <Search
            value={props.transaction.pix_qr_code}
            style={{width:400, marginBottom:30}}
            enterButton={(isClicked ? 'Copiado!': 'Copiar')}
            contentEditable={false}
            size="large"
            
            onSearch={() => {navigator.clipboard.writeText(props.transaction.pix_qr_code); setIsClicked(true); setTimeout(()=> {setIsClicked(false)},3000)}}
          />
          <QRCode width={50} value={props.transaction.pix_qr_code} />

        </Box>
      </Container>}
       { props.transaction.status === 'processing' && <Container>
        <Box>
          <Icon>
            <FontAwesomeIcon size="3x" color="#028E1E" icon={faCheckCircle}/>
          </Icon>
          <Title>
            <h1>Confirmação de agendamento</h1>
          </Title>
          <Title>
            <h2>Olá {user.basicInformation.name}</h2>
          </Title>
          <Description>
            <h1>Assim que confirmarmos o pagamento o agendamento será confirmado.*</h1>
            <h1>Aguarde nosso contato para finalização do processo.</h1>
            <h1 style={{fontSize:12, fontStyle:'oblique', color:'red'}}>(*) Você receberá um e-mail de confirmação, caso não receba verifique sua caixa de spam.</h1>
          </Description>
          { props.transaction.payment_method === 'boleto' && <Button  onClick={()=> handleBoleto()} type="primary">Imprimir Boleto</Button>}

        </Box>
      </Container>}

      { Object.keys(props.transaction).length === 0 && <Container>
        <Box>
          <Icon>
            <FontAwesomeIcon size="3x" color="#028E1E" icon={faCheckCircle}/>
          </Icon>
          <Title>
            <h1>Confirmação de agendamento</h1>
          </Title>
          <Title>
            <h2>Olá {user.basicInformation.name}</h2>
          </Title>
          <Description>
            <h1>Seu agendamento foi confirmado.*</h1>
            <h1>Aguarde nosso contato para finalização do processo.</h1>
            <h1 style={{fontSize:12, fontStyle:'oblique', color:'red'}}>(*) Você receberá um e-mail de confirmação, caso não receba verifique sua caixa de spam.</h1>
          </Description>         

        </Box>
      </Container>}
      <Footer />  
    </MainWraper>
  )
}
export default SchedulingConfirmation;