import React from 'react'
import {
  Link,
  useHistory,
} from "react-router-dom";
import {useAuth} from '../hooks/AuthContext'
import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from './../assets/img/Logo-menu.svg'
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const NavBarWrapper = styled.div`
  .navbar {
    background-color: var(--primary-color-7) !important;
    //top: 40px;
    padding: .25rem 6rem;
    max-width: 100vw;
    @media only screen and (max-width: 600px) {
      padding: .25rem 1rem;
    }
  }
  #basic-navbar-nav {
    @media only screen and (max-width: 992px) {
      // border-bottom: 1px solid white;
      * {
        font-size: 1rem;
      }
    }
  }
  .nav-link {
    color: var(--primary-color-10) !important;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    margin: 0 2rem;
    @media only screen and (max-width: 992px) {
      margin: 0;
      text-align: right;
    }
    &.with-border {
      border: 1px solid var(--primary-color-10);
      border-radius: 5px;
      padding: .5rem 1rem;
      min-width: 140px;
      @media only screen and (max-width: 992px) {
        border: none;
        padding: .5rem 0;
      }
    }
  }
  .nav-dropdown{    
      /* border: 1px solid var(--primary-color-10);
      border-radius: 5px;
      padding: .1rem .1rem;
      min-width: 120px;
      @media only screen and (max-width: 992px) {
        padding: .1rem .1rem;
        min-width:160px;
        max-width: 160px;
        margin-left:100;
        align-self: flex-end;
        
      } */
     
  }
  .brand-icon{
    display: flex;    
    align-self: center;
    height: 25px;
    margin-right: -30px;
    @media only screen and (max-width: 992px) {
      height: 60px;
      margin-right:0;
        
      }

  }
  .navdrop{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid var(--primary-color-10);
    border-radius: 5px;
    padding: .1rem 0rem;
    padding-left: 40px;
    min-width: 100px;
    //max-width: 150px;
    @media only screen and (max-width: 992px) {
      padding-left: 0px;
      min-width: 100px;
      /* max-width: 170px; */
      padding: .0rem .0rem;
      justify-content: space-around;
      align-items: center;
      }
    
  }
  
`

export default function NavBar() {
  const { user, signOut } = useAuth()
  const history = useHistory()
  return (
    <NavBarWrapper>
      <Navbar expand="lg" fixed="top" className="navbar navbar-dark bg-dark">
        <Link to="/">
          <Navbar.Brand href="#home">
            <img
              src={logo}
              width="150"
              height="auto"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link className="nav-link" to="/">
              {/* <Nav.Link href="#home">início</Nav.Link> */}
              início
            </Link>
            <Link className="nav-link" to="/planos">
              {/* <Nav.Link href="#link">soluções</Nav.Link> */}
              soluções
            </Link>
            <Link className="nav-link" to="/#contact">
              {/* <Nav.Link href="#link">contato</Nav.Link> */}
              contato
            </Link>
          </Nav>
          {!user && <Nav className="ml-auto">
            <Nav.Link href="login" className="with-border">área do cliente</Nav.Link>
            <Link className="nav-link" to="/cadastrar">
              {/* <Nav.Link href="#link">cadastrar</Nav.Link> */}
              cadastrar
            </Link>
          </Nav>}
          { user && <Nav className="sl-auto navdrop">
              <FontAwesomeIcon className="brand-icon" size="2x" icon={faUserCircle} style={{ color: "white" }} /> 
              <NavDropdown title={user.basicInformation.name} className="nav-dropdown" >                
                <NavDropdown.Item onClick={()=>history.push('/meus-dados')} >Meus Dados</NavDropdown.Item>                
                <NavDropdown.Item onClick={()=>history.push('/historico')}>Histórico</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>{signOut();history.push('/')}}>Sair</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          }
        </Navbar.Collapse>
      </Navbar>
    </NavBarWrapper>
  )
}
