//eslint-disable
import React, {  useEffect, useState, useCallback } from 'react'
import { MainWraper, Content, Container, Menu, MenuTitles, Details, MenuTitlesB} from './styles'
import NavbarSolo from './../../components/NavBarSolo'
import api from '../../services/api'
import {useAuth} from '../../hooks/AuthContext'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { useToast } from 'hooks/ToastContext'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert'; // Import



const Records = ()  => {
  const { token,user } = useAuth()
  const [data,setData] = useState()
  const history = useHistory()
  const {addToast} = useToast()
  
  

  const handleAlert = (item) => {
    confirmAlert({
      title:'Confirmar cancelamento',
      message: 'Tem certeza que quer fazer isso?',
      buttons: [{
        label:'Yes',
        onClick: () => handleCancel(item),
      },{
        label:'No',
        onClick: () => {},
      }]
    })
  }

  const handleCancel = useCallback(async(item) => { 
    // let resp;
    if(item.status !=='Scheduled'){
      addToast({
        type:'info',
        title:'Aviso',
        description:'Apenas status agendado pode ser cancelado.'
      })
    }
    if(item.status ==='Scheduled'){
      try{
        await api.put(`/schedule-table/${item.id}`,{status:'Cancelled'},{headers: {Authorization: token}}) 
        // item.status = 'Cancelled'
        const newData = data.filter((thisData)=> {
          if(thisData.id === item.id){
            thisData.status = 'Cancelled'
          }
          return thisData
        })
        setData(newData)
        addToast({
          type:'success',
          title:'Agendamento cancelado',
          description: 'Seu agendamento foi cancelado com sucesso.'
        })
      
      }catch(err){
        console.log(err)
      addToast({
        type:'error',
        title:'Erro',
        description:'Não foi possível cancelar o agendamento, entre em contato com a equipe CrossDo'
      })
      
    }
  }
    
    
    console.log(data)
  },[addToast, data,token])
  

  useEffect(()=>{
    try{
    const fetchData = (async()=>{
      const {data:{data}} = await api.get(`/service/${user.id}?field=id&order=DESC`,{headers: {Authorization: token}})     
      setData(data)
    })
    fetchData()
    }catch(err){}
  },[token, user.id ])

  return (
    <MainWraper>
      <NavbarSolo/>      
        <Container>
          <Content>
            <Menu>
              <h1>MINHAS COMPRAS</h1>
            </Menu>
            <MenuTitles>
              <h1> ID </h1>
              <h1>Ref.</h1>
              <h1>Data da contratação</h1>
              <h1>Serviço contratado</h1>
              <h1>Status</h1>
              <div/>
              <div/>
            </MenuTitles>
            {data && data.map((item) =>
              <MenuTitlesB style={{backgroundColor:'#fff'}} key={item.id}>
                <h1>{item.id}</h1>
                <h1>{item.clientReference}</h1>
                <h1>{format(new Date(item.createdAt), 'dd/MM/yyyy')}</h1>
                <h1>{item.serviceType}</h1>
                <h1>{(item.status==='Scheduled' && `Agendado`) 
                || (item.status==='Finished' && `Finalizado`) 
                || (item.status==='Cancelled' && `Cancelado`) 
                || (item.status==='Waiting for payment confirmation' && `Aguardando Pagamento`) }</h1>                
                <Details>
                  <button onClick={()=> 
                    (item.serviceType === 'Cross Park' 
                    && history.push("/detalhes/cross-park", {location: item}))
                    || (item.serviceType === 'Cross Combo' 
                    && history.push("/detalhes/cross-combo", {location: item}))
                    || (item.serviceType === 'Cross Care' 
                    && history.push("/detalhes/cross-care", {location: item}))
                    || (item.serviceType === 'Gru Express' 
                    && history.push("/detalhes/cross-gru-express", {location: item}))
                  }>
                    Detalhes
                  </button>
                  <button style={{marginLeft:10}} onClick={()=>handleAlert(item)}>Cancelar</button>
                </Details>
              </MenuTitlesB>              
             )}
          </Content>
        </Container>
    </MainWraper>
  )
}

export default Records;