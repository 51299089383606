import React, {useCallback, useRef, useState} from 'react'
import { MainWraper, Content, Background, Container, LoginForm, BContainer, Title,  } from './styles'

import Navbar from './../../patterns/Navbar'
import Input from '../../components/Input'
import {Form} from '@unform/web'
import { useHistory } from "react-router-dom";
import api from '../../services/api'
import getValidationErros from '../../utils/getValidationErrors'
import * as Yup from 'yup'
import { useToast } from 'hooks/ToastContext'



const PasswordRecovery = ()  => {
  const history = useHistory();
  const formRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToast()
  
 const handleSubmitLogin = useCallback(async(data) =>{    
    setIsLoading(true)
    try{
      
      const schema = Yup.object().shape({
        email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),        
      })
      await schema.validate(data,{
        abortEarly:false,
      })

      await api.patch('/client/forgot-password',{
        email:data.email,
      })
      addToast({
        type: 'success',
        title: 'Email de recuperação enviado',
        
      })
      // console.log('middle',isLoading)
      history.push('/esqueci-a-senha-login',{state: data.email})
      
    }catch(err){
      if(err.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }
      addToast({
        type: 'error',
        title: 'Erro na recuperação',
        description: 'Ocorreu um erro, verifique o email inserido .',
      })
    }finally{
      
      setTimeout(()=>{        
        setIsLoading(false)
      },3000)

    }

 }, [history, addToast])

  return (
    <MainWraper>
      <Navbar/>
      <Background>
        <Container>
          <Content>
            <Title>
              RECUPERAÇÃO DE SENHA
            </Title>
            <Title style={{fontWeight:300, paddingTop:10}}>
              Informe um e-mail válido para receber as instruções para seu acesso
            </Title>
            <LoginForm>
              <Form ref={formRef} onSubmit={handleSubmitLogin} >                
                <h1>E-mail</h1>
                <Input name="email" placeholder="Informe a e-mail"/>                
                <BContainer>
                  {console.log(isLoading)}
                  <button disabled={isLoading} >Entrar</button>
                  <button onClick={(e)=>{e.preventDefault(); history.push('/') }}>Cancelar</button>
                </BContainer>
              </Form>
            </LoginForm>
          </Content>
        </Container>

      </Background>
    </MainWraper>
  )
}

export default PasswordRecovery;