import React, {useEffect}  from "react";
import {
  BrowserRouter as Router, 
  Switch,
 
} from "react-router-dom";
import ScrollToTop from './utils/ScrollToTop'
import Route from './utils/Route'

import Aos from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from "./pages/Home";
import Plans from "./pages/Plans";
import Login from "./pages/login/Login"
import Register from "./pages/register/Register"
import PasswordRecovery from "./pages/PasswordRecovery/PassordRecovery"
import PasswordChange from './pages/PasswordChange/PassordChange'
import MyData from './pages/MyData/MyData'
import Records from './pages/Records'
import CParkMultiStep from './pages/CrossPark/CParkMultiStep'
import CComboMultiStep from "pages/CrossCombo/CComboMultiStep"
import CFCareMultiStep from './pages/CrossFullCare/CFCareMultiStep'
import DetalheCP from './pages/Records/Details/CrossPark'
import DetalheCC from './pages/Records/Details/CrossCombo'
import DetalheCFC from "pages/Records/Details/CrossFullCare"
import DetalheCGE from './pages/Records/Details/CrossGruExpress'
import AppProvider from './hooks'
import ReactGA from 'react-ga';
import {CheckAuth} from './utils/CheckAuth'
import CGExpressMultiStep from "pages/CrossGruExpress/CGExpressMultiStep";
import { createBrowserHistory } from 'history';

ReactGA.initialize('UA-204024915-1');
const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  // const { token, user } = useAuth()
  useEffect(() => {
    Aos.init({ duration: 1000 })
    
  }, [])

  return (
    <Router>  
      <Switch>
        <AppProvider >  
          <CheckAuth />
          <Route exact path="/" >
            <ScrollToTop/>
            <Home />
          </Route>
          <Route path="/planos" >
            <ScrollToTop/>
            <Plans />
          </Route>        
          <Route path="/login" component={Login}/>            
          <Route path="/cadastrar" component={Register} />            
          <Route path="/esqueci-a-senha" component={PasswordRecovery}/>            
          <Route path="/esqueci-a-senha-login" component={PasswordChange}/>            
          <Route path="/meus-dados" isPrivate={true} component={MyData}/>            
          <Route path="/contratar/cross-park" isPrivate={true} component={CParkMultiStep}/>   
          <Route path="/contratar/cross-combo" isPrivate={true} component={CComboMultiStep}/>  
          <Route path="/contratar/cross-full-care" isPrivate={true} component={CFCareMultiStep}/>
          <Route path="/contratar/cross-gru-express" noAuth={true} component={CGExpressMultiStep}/>          
          <Route path="/historico" isPrivate={true} component={Records}/>
          <Route path="/detalhes/cross-park" isPrivate={true} component={DetalheCP}/>
          <Route path="/detalhes/cross-combo" isPrivate={true} component={DetalheCC}/>
          <Route path="/detalhes/cross-care" isPrivate={true} component={DetalheCFC}/>
          <Route path="/detalhes/cross-gru-express" isPrivate={true} component={DetalheCGE}/>       
        </AppProvider>
      </Switch>
    </Router>
  );
}

export default App;

