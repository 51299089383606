import styled from 'styled-components'

export const MainWraper = styled.div`
  height: 100vh;
  /* min-width: 100vw; */
  margin: 0;
  padding: 0;  
`
export const Container = styled.div`
  display:flex; 
  flex-direction: column;
  flex:1;
  /* width: 100vw; */
  padding: 10.5rem 10.5rem 5.5rem 10.5rem;
  @media only screen and (max-width: 922px) {
      padding: 10.5rem 1rem;     
  }
`
export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color:black;
`
export const Description = styled.h3`
  max-width: 400px ;
  line-height: 22px;
  font-weight: 300;
  margin-top: 15px;
  font-size: 14px;
`
export const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;    
  }
`
export const Individual = styled.div`
  display: flex;
  flex-direction: column;  
`
export const BContainer = styled.div`
  display: flex;
  flex-direction: column;
  & button{
      padding: 8px 50px 8px 50px;
      border:none;
      color: #fff;
      font-size: 14px;
      background-color:#1E75BA;
      border-radius: 4px;      
    }
`

export const FormContainer = styled.div`
  margin-top: 40px;

  .css-2b097c-container{
    display: inline-block;    
    height: 48px;             
    margin-bottom: 10px;
    width: 450px;
    
    @media only screen and (max-width: 600px) {
      width:90vw ;
    }
    .react-select__control{
      /* width: 450px; */
      height: 48px;      
      border: 2px solid #dcdcdc;
      border-radius: 5px;           
      margin-bottom: 20px;
      
      
    }
    .react-select__value-container{
      /* width: 450px; */
      height: 48px;  
      

      .react-select__single-value{
        margin-top: 5px;
      }
      
      .react-select__placeholder{
        /* margin-top: 4px; */
        border:none;
      }
    }
    .react-select__indicators{
      margin-top: -6px;
    }
    &:focus{
        outline-color: #F05035;
      }
  }

  input{
      width: 450px;
      height: 48px;
      padding: 14px;
      border: 2px solid #dcdcdc;
      border-radius: 5px;
      /* opacity:1; */      
      margin-bottom: 20px;   
      @media only screen and (max-width: 600px) {
        width:90vw ;
      }

      & + h1 {
        margin-top: 14px;

      }    
      &::-webkit-input-placeholder {
        color: #a2a2a2;
        font-size: 14px;      
      }
      &:focus{
        outline-color: #F05035;
      }
    }
    
    h1{
      font-size: 14px;
      font-weight: 300;
    }
    strong{
      font-weight: bold;
    }
`