import React, {useRef, useCallback, useEffect} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import {Form} from '@unform/web'
import Input from '../../../components/Input'
import { MainWraper, Container, Title, Description, FormContainer, Group, GroupSelect, Individual, BContainer, GroupB } from './styles'
import Footer from '../../../components/Footer'
import getValidationErros from '../../../utils/getValidationErrors'
import * as Yup from 'yup'

const SecondStep = (props) =>{  
  // const history = useHistory()
  const formRef = useRef(null)  
  // eslint-disable-next-line 
  const { next, previous } = props.navigation;

  const handleSubmitEtapa2 = useCallback(async(data) =>{    
    try{     
      
      const schema = Yup.object().shape({
        forkLift: Yup.bool(),
        paletTruck: Yup.bool(),
        plasticPallet: Yup.string().required('Campo Obrigatório'),
        woodenPallet: Yup.string().required('Campo Obrigatório'),
        firstBox: Yup.string().required('Campo Obrigatório'),
        secondBox: Yup.string().required('Campo Obrigatório'),
        thirdBox: Yup.string().required('Campo Obrigatório'),
        driedIce: Yup.string().required('Campo Obrigatório'),
      })
      
      await schema.validate(data,{
        abortEarly:false,
      })      
      next()

    }catch(err){         
      if(err.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }
    }
  },[next])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Combo"/>
      <Container>
        <Title> Etapa 2 de 3 - Adicionais </Title>
        <Description>
          Os equipamentos e materiais necessários deverão ser previamente reservados no seu kit. Sendo assim, façam de forma planejada para não haver problemas. Todos os campos são obrigatórios, exceto aqueles informados como opcionais.
        </Description>
        <Title style={{marginTop:20}}>Selecione abaixo os itens que deseja adicionar para compor o seu Combo:</Title>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmitEtapa2}>
            <h1><strong>Empilhadeira</strong> ({props.prices && numberFormat(props?.prices.['Forklift']?.price)}/hora)</h1>
            <Group>
              <h1>Até 2500kg/vol</h1>
              

              <Input 
                name="forkLift" 
                value={props.forkLift} 
                checked={props.forkLift}
                onChange={()=>props.setForkLift(!props.forkLift)} 
                type="checkbox" 
                />            
                
            </Group>
            <h1><strong>Paleteira</strong> (cortesia)</h1>
            <Group>
              <h1>Até 200kg/vol</h1>
              <Input 
                name="paletTruck" 
                value={props.paletTruck}
                checked={props.paletTruck}
                onChange={()=>props.setPaletTruck(!props.paletTruck)} 
                type="checkbox" 
              />            
            </Group>
            <Title style={{marginTop:24,fontWeight:'bold', fontSize:14}}>Informe as quantidades para cada item desejado.</Title>
              <Title style={{marginTop:30}}> <strong>Pallet</strong> (envelopado e cintado)</Title>
            <GroupSelect>
              <h1>Plástico ({props.prices && numberFormat(props?.prices.['Plastic Pallet']?.price)}/pallet)</h1>
              <Input 
                name="plasticPallet" 
                placeholder="0" 
                type="number" 
                value={props.plasticPallet} 
                onChange={(e)=>props.setPlasticPallet(e.target.value)} 
                min={0}
                default="0"
              />

              <h1 style={{marginLeft: 40}}>Madeira Fumigada/certificada ({props.prices && numberFormat(props?.prices.['Wood Pallet']?.price)}/pallet)</h1>
              <Input 
                name="woodenPallet" 
                placeholder="0" 
                type="number" 
                value={props.woodenPallet} 
                onChange={(e)=>props.setWoodenPallet(e.target.value)} 
                min={0}
              />
            </GroupSelect>
            <Title style={{marginTop:24,fontWeight:'bold', fontSize:14}}>Caixa de Papelão</Title>
            <Title style={{fontSize:14}}>Escolha o(s) tamanho(s) e a(s) quantidade(s) desejada(s)</Title>
            <GroupSelect>
              <h1>32x32x22cm ({props.prices && numberFormat(props?.prices.['Small Box']?.price)}/unid.)</h1>
              <Input 
                name="firstBox" 
                placeholder="0" 
                type="number" 
                value={props.firstBox} 
                onChange={(e)=>props.setFirstBox(e.target.value)} 
                min={0}
              />

              <h1 style={{marginLeft: 40}}>50x50x40cm ({props.prices && numberFormat(props?.prices.['Medium Box']?.price)}/unid.)</h1>
              <Input 
                name="secondBox" 
                placeholder="0" 
                type="number" 
                value={props.secondBox} 
                onChange={(e)=>props.setSecondBox(e.target.value)} 
                min={0}
              />

              <h1 style={{marginLeft: 40}}>60x60x80cm ({props.prices && numberFormat(props?.prices.['Big Box']?.price)}/unid.)</h1>
              <Input name="thirdBox" 
                placeholder="0" 
                type="number" 
                value={props.thirdBox} 
                onChange={(e)=>props.setThirdBox(e.target.value)} 
                min={0}
              />
            </GroupSelect>
            <Title style={{marginTop:30}}> <strong>Reposição de gelo seco</strong> ({props.prices && numberFormat(props?.prices.['Dry Ice Reposition']?.price)}/kg)</Title>
            <GroupSelect>
              <h1>Quantidade (Kg)</h1>
              <Input name="driedIce" 
                placeholder="0" 
                type="number" 
                value={props.driedIce} 
                onChange={(e)=>props.setDriedIce(e.target.value)} 
                min={0}
              />
            </GroupSelect>
            <GroupB>
                <Individual>

                </Individual>
                <BContainer>
                  <button onClick={(e)=>{e.preventDefault();previous()}}>Voltar</button>
                  <button>Avançar</button>
                </BContainer>
            </GroupB>
          </Form>
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default SecondStep;