import React, { useCallback, useEffect, useRef, useState} from 'react'
import NavBarSolo from '../../../../components/NavBarSolo'
import {TitleBar} from '../../../../components/TitleBar/index'

import { MainWraper, Container, Title, SumBox, TagsTitle, FormContainer, BContainer, Total, Box, BoxTitle, DataContainer, DataContent } from './styles'
import ParaCima from '../../../../assets/icones/EsteLadoParaCima.svg'
import Fragil from '../../../../assets/icones/fragil.svg'
import Temperatura from '../../../../assets/icones/Temperatura.svg'
import Footer from '../../../../components/Footer'
import {format } from 'date-fns'
import { useLocation, useHistory } from 'react-router-dom'
import api from 'services/api'
import { useAuth } from 'hooks/AuthContext'
import { useToast } from 'hooks/ToastContext'
import { confirmAlert } from 'react-confirm-alert'
import DateInput from '../../../../components/DatePicker/index'
import {Form} from '@unform/web'

const DetalheCC = (props) =>{
  const formRef = useRef(null)
  const {token} = useAuth()
  const history = useHistory()
  const {addToast}= useToast()
  const { state: {location} } = useLocation()
  // console.log(location)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDock = (dockType) => {
    switch(dockType){
      case 'Ramp':
        return 'Rampa'
      case 'High':
        return 'Alta'
      default: 
        return null
    }
  }

  const handleVehicle = (dockType) => {
    switch(dockType){
      case 'Truck 3/4':
        return 'Caminhão 3/4'
      case 'Stump truck':
        return 'Caminhão toco'
      case 'Simple cart':
        return 'Caminhão carreta Simples'
      case 'Extended axle cart':
        return 'Caminhão carreta eixo extendido'
      case 'Van trunk':
        return 'Van Baú'
      case 'Utility':
        return 'Veículo utilitário'
      case 'Van':
        return 'Furgão (Van)'
      default: 
        return null
    }
  }
 
  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  const [blockedDate,setBlockedDate] = useState(null)
  const [newDate, setNewDate] = useState(new Date(location.checkInDatetime))

 
  const handleFreeTime = useCallback(async()=> {
    try{
      const {data} = await api.post('/schedule/unavailable',{
        date: newDate ,
        serviceDuration: location.serviceDuration,
        dockType: location.dockType,
      },{
        headers:{ Authorization: token}
      }) 
      const arr = data.map((date)=> new Date(date).setMinutes(0,0,0))
        
      setBlockedDate(arr)
    }catch(err){}
  },[location, token, newDate])

  const handleDateChange = async(item,onClose) =>{
    
    if(item.status ==='Scheduled'){
      try{
        await api.put(`/schedule-table/${item.id}`,
        {checkInDatetime:newDate.toISOString()},
        {headers: {Authorization: token}}) 
        // item.status = 'Cancelled'       
        
        addToast({
          type:'success',
          title:'Horário alterado',
          description: 'Seu horário foi alterado com sucesso.'
        })
        history.push('/historico')
        onClose()        
        setVisible(false)
      }catch(err){
        console.log(err)
      addToast({
        type:'error',
        title:'Erro',
        description:'Não foi possível alterar o horário, entre em contato com a equipe CrossDo'
      })
      onClose()
      setVisible(false)
    }
  }
}
useEffect(()=>{
  handleFreeTime()
},[handleFreeTime])

  const handleAlert = ()=>{
    
    confirmAlert({  
      customUI: ({ onClose }) => {
        
        return (
          <div style={{display:'flex',flex:1, alignItems:'center', flexDirection:'column'}} >
            <h1 style={{fontSize:24}}>Alterar Data/hora</h1>
            <FormContainer>
            <Form ref={formRef} onSubmit={()=>handleDateChange(location,onClose)}  >
            
              <DateInput 
                name="checkinDatatime"                  
                minDate={new Date()}
                selected={newDate} 
                excludeTimes={blockedDate}
                showTimeSelect                
                timeIntervals={60}           
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                onChange={date => setNewDate(date)}              
             />
             
              <BContainer style={{display:'flex', marginTop:30 }}>
              <button onClick={()=>{onClose();setVisible(false)}}>Não</button>
              <button
                onClick={(e) => {  
                }}
              >
              Sim, Alterar!
            </button>
            </BContainer>
            
            </Form>
            </FormContainer>
          </div>
        );
      }
    });
  }
  const [visible,setVisible] = useState(false)


  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Detalhes > Cross Combo"/>
      <Container>
        <Title>Resumo de agendamento</Title>
        {/* <Description>
        Aqui vai algum explicativo, com informações adicionais que julgarem pertinentes. Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
        </Description> */}
        <Total>
          <Title style={{color:'#fff'}}>Valor do pedido</Title>
          <Title style={{color:'#fff'}}>{numberFormat(location.totalPrice)}</Title>
        </Total>
        <Title style={{marginTop:20}}>Descrição dos itens agendados</Title>
        <BoxTitle>
          <Title style={{color:'#fff'}}>Informações gerais</Title>
        </BoxTitle>
        <Box>
        <DataContainer>
            <h1>ID</h1>
            <h1>{location.id}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Referência do cliente</h1>
            <h1>{location.clientReference}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Data de Entrada</h1>
            <h1>{format(new Date(location.checkInDatetime), 'dd/MM/yyyy')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Horário de entrada</h1>
            <h1>{format(new Date(location.checkInDatetime), 'HH:mm')}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Placa do veículo</h1>
            <h1>{location.vehicleLicensePlate}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nome do motorista</h1>
            <h1>{location.driverName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tipo de doca</h1>
            <h1>{handleDock(location.dockType)}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Veículo</h1>
            <h1>{handleVehicle(location.vehicle)}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Tempo Contratado</h1>
            <h1>{location.serviceDuration}</h1>
          </DataContainer>
          <DataContent/>

        </Box>

        <BoxTitle style={{marginTop:50}}>
          <Title style={{color:'#fff'}}>Adicionais</Title>
        </BoxTitle>
        <Box>
          <DataContainer>
            <h1>Empilhadeira</h1>
            <h1>Até 100Kg/vol</h1>
          </DataContainer>
          <DataContainer>
            <h1>Paleteira</h1>
            <h1>Até 100Kg/vol</h1>
          </DataContainer>
          <DataContainer style={{alignItems:'self-start'}}>
            <h1>Pallet (envelopado e citado)</h1>
            <h1 style={{display:'flex', flexDirection:'column',alignItems:'flex-end', lineHeight:2}}>
              Plástico - {location.plasticPalletsAmount} unidades
              <span>Madeira fumigada/certificada - {location.woodPalletsAmount} unidades</span>
              </h1>
          </DataContainer>
          <DataContainer style={{alignItems:'self-start'}}>
            <h1>Caixa de papelão</h1>
            <h1 style={{display:'flex', flexDirection:'column',alignItems:'flex-end', lineHeight:2}}>
              32x32x22cm - {location.smallCardboardBoxAmount} unidades
              <span>50x50x40cm - {location.mediumCardboardBoxAmount} unidades</span>
              <span>60x60x80cm - {location.bigCardboardBoxAmount} unidades</span>
            </h1>
          </DataContainer>
           <DataContainer>
            <h1>Reposição de gelo seco</h1>
            <h1>{location.dryIceRepositionAmount} kg</h1>
          </DataContainer>
          <DataContent/>
        </Box>

        <BoxTitle style={{marginTop:50}}>
          <Title style={{color:'#fff'}}>Etiquetas</Title>
        </BoxTitle>
        <Box>
          <TagsTitle>Manuseio</TagsTitle>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img alt="para cima" src={ParaCima}/>
            <h1 style={{marginBottom:0, marginLeft:8}}>Este lado para cima</h1>
            </div>
            <h1 style={{fontWeight:300}}>{location.upsideTagAmount} unidades</h1>
          </DataContainer>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img alt="para cima" src={Fragil}/>
            <h1 style={{marginBottom:0, marginLeft:8}}>Frágil</h1>
            </div>
            <h1 style={{fontWeight:300}}>{location.fragileTagAmount} unidades</h1>
          </DataContainer>
          <DataContainer>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <img alt="para cima" src={Temperatura}/>
            <h1 style={{marginBottom:0, marginLeft:8}}>Temperatura</h1>
            </div>
            <h1 style={{fontWeight:300}}>{location.temperatureTagAmount} unidades</h1>
          </DataContainer>
          <DataContainer>
            <h1 style={{marginBottom:0, marginLeft:40, marginTop:14}}>AOG</h1>            
            <h1 style={{fontWeight:300}}>{location.AOGTagAmount} unidades</h1>
          </DataContainer>

          <SumBox>
            <h1>Total de etiquetas de manuseio</h1>
            <h1>{parseInt(location.upsideTagAmount) + parseInt(location.fragileTagAmount) + parseInt(location.temperatureTagAmount) + parseInt(location.AOGTagAmount)} etiquetas</h1>
          </SumBox>
          
          <TagsTitle>Shipper/Consignee</TagsTitle>
          <DataContainer>
            <h1>Nome do exportador (shipper)</h1>
            <h1>{location.merchantTag.shipper.name}</h1>
          </DataContainer>
          <DataContainer>
            <h1>CEP (ZIP Code)</h1>
            <h1>{location.merchantTag.shipper.cep}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Endereço (address)</h1>
            <h1>{location.merchantTag.shipper.address}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Cidade (City)</h1>
            <h1>{location.merchantTag.shipper.city}</h1>
          </DataContainer>
          <DataContainer>
            <h1>País (Country)</h1>
            <h1>{location.merchantTag.shipper.country}</h1>
          </DataContainer>
          <DataContainer>
            <h1>UF (State)</h1>
            <h1>{location.merchantTag.shipper.state}</h1>
          </DataContainer>
          <h1 style={{fontSize:14, fontWeight:'bold', marginTop:14, marginBottom:14}}>Contatos do exportador</h1>
          <DataContainer>
            <h1>Nome</h1>
            <h1>{location.merchantTag.shipper.contactName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Telefone</h1>
            <h1>{location.merchantTag.shipper.phone}</h1>
          </DataContainer>
          <DataContainer>
            <h1>E-mail</h1>
            <h1>{location.merchantTag.shipper.email}</h1>
          </DataContainer>
          
          <div style={{marginBottom:40}}/>
          <DataContainer>
            <h1>Nome do importador (consignee)</h1>
            <h1>{location.merchantTag.consignee.name}</h1>
          </DataContainer>
          <DataContainer>
            <h1>CEP (ZIP Code)</h1>
            <h1>{location.merchantTag.consignee.cep}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Endereço (address)</h1>
            <h1>{location.merchantTag.consignee.address}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Cidade (City)</h1>
            <h1>{location.merchantTag.consignee.city}</h1>
          </DataContainer>
          <DataContainer>
            <h1>País (Country)</h1>
            <h1>{location.merchantTag.consignee.country}</h1>
          </DataContainer>
          <DataContainer>
            <h1>UF (State)</h1>
            <h1>{location.merchantTag.consignee.state}</h1>
          </DataContainer>
          <h1 style={{fontSize:14, fontWeight:'bold', marginTop:14, marginBottom:14}}>Contatos do importador</h1>
          <DataContainer>
            <h1>Nome</h1>
            <h1>{location.merchantTag.consignee.contactName}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Telefone</h1>
            <h1>{location.merchantTag.consignee.phone}</h1>
          </DataContainer>
          <DataContainer>
            <h1>E-mail</h1>
            <h1>{location.merchantTag.consignee.email}</h1>
          </DataContainer>
          <SumBox>
            <h1>Total de etiquetas de Shipper/Consignee</h1>
            <h1>{parseInt(location.merchantTag.amount)} etiquetas</h1>
          </SumBox>
          
          <TagsTitle>Master e House</TagsTitle>
          <DataContainer>
            <h1>Nº MAWB</h1>
            <h1>{location.masterAndHouseTag.mawb}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nº HAWB</h1>
            <h1>{location.masterAndHouseTag.hawb}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Aeroporto de origem</h1>
            <h1>{location.masterAndHouseTag.originAirport}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Aeroporto de destino</h1>
            <h1>{location.masterAndHouseTag.destinationAirport}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Quantidade de volume final (em unidades do HAWB)</h1>
            <h1>{location.masterAndHouseTag.hawbVolume}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Peso bruto total (Em Kg do HAWB)</h1>
            <h1>{location.masterAndHouseTag.hawbWeight}</h1>
          </DataContainer>
          <DataContainer>
            <h1>Nº da Invoice - INV (opcional)</h1>
            <h1>{location.masterAndHouseTag.invoice}</h1>
          </DataContainer>
          <SumBox>
            <h1>Total de etiquetas Master e House</h1>
            <h1>{parseInt(location.masterAndHouseTag.amount)} etiquetas</h1>
          </SumBox>
          
          <DataContainer style={{paddingInline:32}}>
            <h1>Total geral de etiquetas</h1>
            <h1 style={{fontWeight:'bold'}}>
              {parseInt(location.merchantTag.amount) 
              + parseInt(location.masterAndHouseTag.amount) 
              + parseInt(location.upsideTagAmount) 
              + parseInt(location.fragileTagAmount) 
              + parseInt(location.temperatureTagAmount)
              + parseInt(location.AOGTagAmount)} etiquetas
            </h1>
          </DataContainer>
          <DataContent/>
          
        </Box> 
        {location.status === 'Scheduled' &&<BContainer>
          <button onClick={()=> {setVisible(true)}}>Alterar Data/Hora</button>
          {visible && handleAlert()}
        </BContainer>}
        <FormContainer>
          
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default DetalheCC;