import React from 'react'
import { NavbarBrand } from 'react-bootstrap'
import NavBar from 'react-bootstrap/Navbar'
import {MainWraper} from './styles'

export const TitleBar = ({title}) =>{ 
  return (
    <MainWraper>
      <NavBar expand="lg" fixed="top" className="navbar navbar-dark ">
        <NavbarBrand>
          {title}
        </NavbarBrand>
      </NavBar>
    </MainWraper>
  )

}