import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image';
import {Link} from 'react-router-dom'
import img1 from './../assets/img/Park.jpg';
import img2 from './../assets/img/Combo.jpg';
import img3 from './../assets/img/Full.jpg';
import img4 from './../assets/img/gruexpress.jpg'

const Section5Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-9);
  margin: 0;
  align-items: center;
`

const SectionContent = styled.section`
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-9);
  margin: 0;
  align-items: center;
  padding: 2.5vh 6rem 0;
  
  @media only screen and (max-width: 992px) {
    padding: 2rem 2rem 1rem;
  }
  .text-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color-10);
    padding: 1rem 3.5%;
    span {
      &.title {
        color: var(--primary-color-7);
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 20px;
        line-height: 1.2;
        @media only screen and (max-width: 768px) {
          font-size: 24px;
        }
      }
      &.subtitle {
        color:  var(--primary-color-11);
        font-size: 16px;
        font-weight: 300;
        max-width: 450px;
        text-align: justify;
        text-justify: inter-word;
        padding-bottom: 12px;
        @media only screen and (max-width: 992px) {
          max-width: 100%;
        }
      }
    }
  }
  .img-content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 457px;
      height: auto;
      @media only screen and (max-width: 992px) {
        width: 100%
      }
    }
    margin-bottom: 1rem;
  }
`
const MainTitle = styled.span`
  width: 100%;
  font-size: 40px;
  color: var(--primary-color-7);
  font-weight: 700;
  text-align: center;
  padding: 7.5vh 1rem 5vh;
  @media only screen and (max-width: 992px) {
    font-size: 32px;
    padding: 2rem 1rem;
    line-height: 1;
  }
`

const ActionArea = styled.div`
  width: 100%;
  text-align: center;
  padding: 5vh 1rem 10vh;
  button {
    background-color: var(--primary-color-5);
    padding: .5rem 3rem;
    border-color: var(--primary-color-5);
    transition: .25s ease-in-out;
    &:hover {
      background-color: var(--primary-color-5);
      color: var(--primary-color-10);
      border-color: var(--primary-color-10);
    }
  }
`

export default function Section5() {

  return (
    <Section5Wrapper data-aos="fade-up">
      <SectionContent className="row">
        <MainTitle>Conheça nossas soluções</MainTitle>
        <div className="col-lg-6 col-sm-12 text-content">
          <span className="title">CROSS PARK</span>
          <span className="subtitle">
            O Cross Park é um dos produtos oferecidos pela plataforma de COWORKING CrossDo.
          </span>
          <span className="subtitle">
            É um produto especialmente pensado e desenvolvido para clientes que precisam apenas executar ações de manuseio de sua carga em uma área segura.
          </span>
          <span className="subtitle">
            Nessa modalidade é oferecido o compartilhamento da estrutura física CrossDo, onde o cliente executa os procedimentos necessários, tais como retirada das mercadorias do veículo de transporte, conferência de volumes, separação de volumes, paletização, cintamento, etiquetagem, entre outros, garantindo a segurança e a integridade da carga.
          </span>
          <span className="subtitle">
            Ao contratar o produto Cross Park, o cliente determinará o tempo em que permanecerá na área de manuseio conforme sua necessidade e pagará apenas por esse período.
          </span>
          <span className="subtitle">
            Serão disponibilizados para contratação 2 tipos de docas: Doca alta, apropriada para caminhões, e rampa, apropriada para veículos utilitários.
          </span>
          <span className="subtitle">
            O cliente conta ainda com a opção de contratação dos serviços de empilhadeira até 2500 kg.
          </span>
        </div>
        <div className="col-lg-6 col-sm-12 img-content">
          <Image src={img1} thumbnail className="mr-auto" />
        </div>
      </SectionContent>



      <SectionContent className="row">
        <div className="col-lg-6 col-sm-12 img-content">
          <Image src={img2} thumbnail className="ml-auto" />
        </div>
        <div className="col-lg-6 col-sm-12 text-content">
          <span className="title">CROSS COMBO</span>
          <span className="subtitle">
            O CrossDo Combo é uma associação do produto PARK acrescido do fornecimento de um kit com material necessário para execução dos serviços, além da área de manuseio.
          </span>
          <span className="subtitle">
            Nessa modalidade o cliente utilizará a estrutura física CrossDo para fazer os procedimentos necessários (retirada do veículo de transporte, etiquetagem, conferência de volumes, separação de volumes) e contará com o fornecimento, conforme sua necessidade/escolha dos materiais para finalização, tais como paletes de plástico ou madeira tratada e certificada, caixas de papelão, reposição de gelo seco, impressão de etiquetas (etiquetas de Manuseio, Shipper e consignee, Master e House), equipamento e cinta para cintamento, equipamento e plástico filme para envelopamento.
          </span>
          <span className="subtitle">
            A contratação é feita de acordo com o tempo e material necessário para boa execução dos serviços pelo cliente, sendo disponibilizados dois tipos de doca, alta e rampa (compatível com o veículo utilizado).
          </span>
          <span className="subtitle">
            O cliente poderá também contratar os serviços de empilhadeira até 2500 kg.
          </span>
        </div>
      </SectionContent>

      <SectionContent className="row">
        <div className="col-lg-6 col-sm-12 text-content">
          <span className="title">CROSS CARE</span>
          <span className="subtitle">
          O Cross Care é o produto VIP oferecido pela plataforma de COWORKING CrossDo. Todo o serviço é executado por uma equipe de profissionais especializados no manuseio de carga e embalagens de exportação, padrão OEA.
          </span>
          <span className="subtitle">
          Nessa modalidade é oferecido o serviço completo de manuseio, etiquetagem, paletização, repesagem, separação, relatórios personalizados com fotos e detalhes do serviço iniciado e finalizado.
          </span>
          <span className="subtitle">
          A nossa equipe ficará responsável desde a recepção da carga até a entrega no Terminal de Exportação para embarque, cuidando do agendamento (dois horários diários garantidos), de acordo com o dead-line da cia aérea informado.
          </span>
          <span className="subtitle">
          A contratação é feita diretamente na plataforma CrossDo com o agendamento da entrega da carga e informações básicas da operação. O sistema irá enviar um e-mail automático para a equipe CrossDo que fará contato visando detalhamento dos serviços contratados.
          </span>
        </div>
        <div className="col-lg-6 col-sm-12 img-content">
          <Image src={img3} thumbnail className="mr-auto" />
        </div>
      </SectionContent>

      {/* <SectionContent className="row">
        <div className="col-lg-6 col-sm-12 img-content">
          <Image src={img4} thumbnail className="ml-auto" />
        </div>
        <div className="col-lg-6 col-sm-12 text-content">
          <span className="title">Gru Express</span>
          <span className="subtitle">
            O GRU Express é um serviço que permite, através da CrossDo. que imprevistos com sua exportação sejam solucionados.
          </span>
          <span className="subtitle">
          Uma linha direta ligada ao TECA Exportação para empresas que precisam de presença de carga urgente e cumprimento do dead line das Cias Aéreas em tempo do fluxo normal de sua operação.
          </span>
          
        </div>
      </SectionContent> */}


      <ActionArea>
        <Link to="/planos">
          <Button variant="outline-light">Faça sua cotação</Button>
        </Link>
      </ActionArea>

    </Section5Wrapper>
  )
}
