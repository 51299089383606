import React, { useEffect, useState } from 'react'
import InfoBar from 'components/InfoBar'
import NavBarComp from 'components/NavBar'
import styled from 'styled-components'

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  .hiddenArea {
    height: 40px;
    transition: height .75s;
    &.onHide {
      height: 0;
    }
  }
`

export default function NavbarPattern() {
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 40) setIsHidden(true)
      else setIsHidden(false)
    };
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, []);

  return (
    <NavbarWrapper data-aos="fade-down">
      <div className={`hiddenArea ${isHidden ? "onHide" : ""}`}>
        <InfoBar />
      </div>
      <NavBarComp />
    </NavbarWrapper>
  )
}
