import React, { useEffect, useRef } from 'react'
import {useField} from '@unform/core'

import {Container, Error} from './styles'

const Input = ({name ,...rest}) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, error, registerField } = useField(name)

  useEffect(()=>{
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  return (
    <Container>
      <input defaultValue={defaultValue} ref={inputRef} {...rest}/>
      <Error>{error}</Error>
    </Container>
  )
}

export default Input;