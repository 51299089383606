import styled from 'styled-components'
import { shade } from 'polished'


export const MainWraper = styled.div`
  height: 100vh;
  /* min-width: 100vw; */
  margin: 0;
  padding: 0;  
`
export const Container = styled.div`
  display:flex; 
  flex-direction: column;
  flex:1;
  /* width: 100vw; */
  padding: 10.5rem 10.5rem 5.5rem 10.5rem;
  @media only screen and (max-width: 922px) {
      padding: 10.5rem 1rem;     
  }
`
export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color:black;
  margin-bottom: 20px;
`
export const Description = styled.h3`  
  line-height: 16px;
  font-weight: 300;  
  font-size: 14px;
  flex-direction:column;
`
export const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;    
  }
`
export const Individual = styled.div`
  display: flex;
  flex-direction: column;  
`
export const BContainer = styled.div`
  display: flex;
  flex-direction: row;
  & button{
      padding: 8px 50px 8px 50px;
      border:none;
      color: #1E75BA;
      font-size: 14px;
      background-color:#fff;
      border-radius: 4px;
      margin-left: 20px ;

      &:hover{
      background:${shade(0.2,'#1E75BA' )};
      color: #fff;
    }
    
    & + button{
      color: #fff;
      background-color:#1E75BA;
    }

    }
`
export const InputBox = styled.div`
  display: flex;
  align-self:baseline;  
`

export const GroupBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  @media only screen and (max-width: 1200px) {
        flex-direction: column;  
      }
`
export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  
  h1{
    font-size: 12px;
    font-weight: 300;
    margin:0 0 0 14px;
    
  }
  input{      
      width: 80px;
      height: 32px;
      padding: 14px;
      border: 2px solid #dcdcdc;
      border-radius: 5px;
      margin-left: 14px;
      &::-webkit-input-placeholder {
        color: #a2a2a2;
        font-size: 14px;      
      }
      &:focus{
        outline-color: #F05035;
      }
  }
`

export const GroupB = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;    
  }
`
export const ShipperForm = styled.div`
  display: flex;
  flex-direction: column;  
  margin-top: 0px;
  margin-bottom: 40px;

  h1{
    font-size: 14px;
  }

  input{      
      width: 400px;
      height: 40px;
      padding: 14px;
      border: 2px solid #dcdcdc;
      border-radius: 5px;
      
      &::-webkit-input-placeholder {
        color: #a2a2a2;
        font-size: 14px;      
      }
      &:focus{
        outline-color: #F05035;
      }
      
  }
  @media only screen and (max-width: 1200px) {
          input{
            width: 75vw;
          } 
      }

`
export const IndividualInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 52px;

  .css-2b097c-container{
    display: inline-block;    
    height: 48px;             
    margin-bottom: 10px;
    width: 400px;
    
    @media only screen and (max-width: 600px) {
      width:75vw ;
    }
    .react-select__control{
      /* width: 450px; */
      height: 48px;      
      border: 2px solid #dcdcdc;
      border-radius: 5px;           
      margin-bottom: 20px;
      
      
    }
    .react-select__value-container{
      /* width: 450px; */
      height: 48px;  
      

      .react-select__single-value{
        margin-top: 5px;
      }
      
      .react-select__placeholder{
        /* margin-top: 4px; */
        border:none;
      }
    }
    .react-select__indicators{
      margin-top: -6px;
    }
    &:focus{
        outline-color: #F05035;
      }
  }
  @media only screen and (max-width: 1200px) {
          input{
            width: 75vw;
          } 
      }

`

export const DoubleInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
        flex-direction: column;    
      }

`

export const FormContainer = styled.div`
  margin-top: 40px; 

  form{
    .ant-collapse-header{
      font-weight: bold;
    }
    .ant-collapse-content{
      border-top: none;
      padding-right: 10px;
      
    }

  }

    /* h1{
      font-size: 14px;
      font-weight: 300;
    } */
    strong{
      font-weight: bold;
    }
    
`

export const HAWBForm = styled.div`
  display: flex;
  flex-direction: column;  
  margin-top: 0px;
  margin-bottom: 40px;

  h1{
    font-size: 14px;
  }

  input{      
      width: 400px;
      height: 48px;
      padding: 14px;
      border: 2px solid #dcdcdc;
      border-radius: 5px;
      
      &::-webkit-input-placeholder {
        color: #a2a2a2;
        font-size: 14px;      
      }
      &:focus{
        outline-color: #F05035;
      }
      
  }
  @media only screen and (max-width: 1200px) {
          input{
            width: 75vw;
          } 
      }
      

`
