/* eslint-disable */
import React, {useState, useRef, useCallback, useEffect} from 'react'
import NavBarSolo from '../../../components/NavBarSolo'
import {TitleBar} from '../../../components/TitleBar/index'
import {Form} from '@unform/web'
import Input from '../../../components/Input'
import DateInput from '../../../components/DatePicker/index'

import SelectInput from '../../../components/SelectInput/index'
import { MainWraper, Container, Title, Description, FormContainer, Group, Individual, BContainer } from './styles'
import Footer from '../../../components/Footer'
import getValidationErros from '../../../utils/getValidationErrors'
import {useHistory} from 'react-router-dom'
import { carPlateMask } from '../../../utils/cpfmask'
import * as Yup from 'yup'
import api from 'services/api'
import { useAuth } from 'hooks/AuthContext'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
// import FormItem from '../../../components/FormItem'


const FirstStep = (props) =>{
  const history = useHistory()
  const formRef = useRef(null)
  const theDate = new Date()
  theDate.setDate(new Date().getDate())
  const {token} = useAuth()
  const [blockDate, setBlockDate] = useState()
  const [isDisabledDate, setIsDisabledDate] = useState(true)
  const [isDisabledTime, setIsDisabledTime] = useState(true)
  const [isDisabledHt, setIsDisabledHt] = useState(true)
  const tooltip = ()=> {
    return(
      <span>
      Agendamento antecipado: <br/>
      1 hora = {`${props.prices && numberFormat(props.prices?.['Cross Combo'].price)}`} <br/>
      2 horas = {`${props.prices && (numberFormat(Number(props.prices?.['Cross Combo'].price) + Number(props.prices?.['Cross Park'].additionalPrice) ))}`} <br/>
      3 horas = {`${props.prices && (numberFormat(Number(props.prices?.['Cross Combo'].price) + Number(props.prices?.['Cross Park'].additionalPrice)*2  ))}`}
      </span>
      )
  }
  const handleDock = (dockType) => {
    switch(dockType){
      case 'Rampa':
        return 'Ramp'
      case 'Alta':
        return 'High'
      default: 
        return null
    }
  }

  const handleFreeTime = async(date, selector, dockType, ht) => {
    try{
      
      if(date && props.hiredTime && selector === 'date' ){

        const {data} = await api.post('/schedule/unavailable',{
          date: date.toISOString() ,
          serviceDuration: props.hiredTime,
          dockType: handleDock(dockType),
        },{
          headers:{ Authorization: token}
        }) 
        const arr = data.map((date)=> new Date(date).setMinutes(0,0,0))
        setBlockDate(arr)
        setIsDisabledTime(false)
      }
      // console.log( ht, date, selector, dockType)
      if(ht && date && selector === 'time' ){

        const {data} = await api.post('/schedule/unavailable',{
          date: date.toISOString() ,
          serviceDuration: ht,
          dockType: handleDock(dockType),
        },{
          headers:{ Authorization: token}
        }) 
        const arr = data.map((date)=> new Date(date).setMinutes(0,0,0))
        setBlockDate(arr)
        setIsDisabledTime(false)
        // console.log('data',data, 'arr', arr, 'wtf')
      }
    }catch(err){}
  }

  const dockOptions = [
    { value: 'Ramp', label: 'Rampa' },
    { value: 'High', label: 'Alta' },
    
  ]
  const vehicleOptionsRamp = [
    { value: 'Utility', label: 'Veículo utilitário' },
    { value: 'Van', label: 'Furgão (Van)' },    
  ]
  const vehicleOptionsHigh = [
    { value: 'Truck 3/4', label: 'Caminhão 3/4' },
    { value: 'Stump truck', label: 'Caminhão toco' },
    { value: 'Simple cart', label: 'Caminhão carreta Simples' },
    { value: 'Extended axle cart', label: 'Caminhão carreta eixo extendido' },
    { value: 'Van trunk', label: 'Van Baú' },    
  ]

  const handleOption = (e) =>{
    
    if( e === 'Ramp' ){
      return vehicleOptionsRamp
    }
    if( e === 'High'){
      return vehicleOptionsHigh
    }
    return null
  }
  const { next } = props.navigation;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmitEtapa1 = useCallback(async(data) =>{    
    try{ 
      const schema = Yup.object().shape({
        reference: Yup.string().required('Referência obrigatória'),
        date: Yup.string().required('Data obrigatória'),
        time: Yup.string().required('Tempo obrigatório'),
        carPlate: Yup.string().required('Placa obrigatória').matches(/[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}|[A-Z]{3}[0-9]{4}/,'Placa fora do padrão'),
        driverName: Yup.string().required('Nome do motorista obigatório'),
        dock: Yup.string().required('Tipo de doca obrigatória'),
        vehicle: Yup.string().required('Tipo de veículo obrigatório'),
        hiredTime: Yup.string().required('Tempo de contratação obrigatório')
      })

      await schema.validate(data,{
        abortEarly:false,
      })
      next()

    }catch(err){   
      // console.log(err)
      if(err?.name=== 'ValidationError'){
        const errors = getValidationErros(err)
        formRef.current?.setErrors(errors)
      }      
    }
  },[history])

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return (
    <MainWraper>
      <NavBarSolo/>
      <TitleBar title="Cross Combo"/>
      <Container>
        <Title> Etapa 1 de 2 - Informações gerais </Title>
        <Description>
          Selecione a data e horário de sua conveniência. Caso precise alterar por algum imprevisto, você poderá fazer aqui mesmo. Basta entrar no histórico e buscar horário disponível. Todos os campos marcados com (*) são obrigatórios, exceto aqueles informados como opcionais.
        </Description>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmitEtapa1}>
            <h1><strong>Referência do cliente *</strong></h1>
            <Input name="reference" value={props.reference} onChange={(e)=> props.setReference(e.target.value)}/>
            {/* <FormItem name="reference" value={reference} onChange={setFormData} /> */}
            <Group>
              <Individual>
                <h1><strong>Tipo de doca *</strong></h1>
                <SelectInput name="dock" placeholder="Escolher" value={props.dock} options={dockOptions} onChange={(e)=> {props.setDock({value: e.value, label: e.label}); setIsDisabledHt(false)}}/>
              </Individual>
              <Individual>
                <h1><strong>Veículo *</strong></h1>
                <SelectInput name="vehicle" placeholder="Escolher" value={props.vehicle} options={handleOption(props.dock.value)} onChange={(e)=> props.setVehicle({value: e.value, label: e.label})}/>
              </Individual>
            </Group> 
            <Group>
              <Individual>
                <h1><strong>Placa do veículo *</strong></h1>
                <Input name="carPlate" placeholder="AAA0A00" value={props.carPlate} maxlength={8} onChange={(e)=> props.setCarPlate(e.target.value)}/>
              </Individual>
              <Individual>
                <h1><strong>Nome do motorista *</strong></h1>
                <Input name="driverName" value={props.driverName} onChange={(e)=> props.setDiverName(e.target.value)}/>
              </Individual>
            </Group>
            <Group style={{marginTop:26}}>
              <Individual>
                <h1><strong>Tempo contratado (em horas) *</strong></h1>
                <Input 
                  name="hiredTime" 
                  min={1} 
                  max={8}                   
                  type="number" 
                  value={props.hiredTime} 
                  disabled={isDisabledHt}
                  onChange={(e)=> {
                    props.setHiredTime(e.target.value.replace(/\D/,''));
                    setIsDisabledDate(false); 
                    handleFreeTime(props.startDate, 'time' , props.dock.label, e.target.value)
                  }}/>
              </Individual>
              <Individual style={{justifyContent:'center'}}>
                <div style={{width:450}}>
                  <h1><strong>Valor por hora - {props.prices && numberFormat(props?.prices.['Cross Combo']?.price)}</strong></h1>
                  <h1><strong>Mínimo de 1 hora - {props.prices && numberFormat(props?.prices.['Cross Combo']?.price)}</strong></h1>
                  <h1><strong>Hora Adicional - {props.prices && numberFormat(props?.prices.['Cross Combo']?.additionalPrice)} (antecipado)</strong></h1>
                  <Tooltip placement="top" title={tooltip}>
                   <strong>Saiba Mais</strong> <FontAwesomeIcon style={{height:16, width:16}} icon={faQuestionCircle}/>
                  </Tooltip>
                </div>                
              </Individual>
            </Group>
            <Group>
              <Individual>
                <h1><strong>Data de entrada *</strong></h1>                
                <DateInput 
                  name="date"                  
                  minDate={theDate}
                  selected={props.startDate}
                  dateFormat="dd/MM/yyyy"                  
                  onChange={date => {props.setStartDate(date); handleFreeTime(date, 'date', props.dock.label)}}   
                  disabled={isDisabledDate}  
                           
                />
              </Individual>
              <Individual>
                <h1><strong>Horário de entrada *</strong></h1>
                <DateInput
                  name="time"                   
                  dateFormat="HH:mm"               
                  timeFormat="HH:mm"
                  selected={props.startTime}             
                  onChange={date => props.setStartTime(date)}
                  excludeTimes={blockDate}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Horário"
                  disabled={isDisabledTime}
                  
                />
              </Individual>
            </Group>
            
                       
              <Group>
                <div >
                  <h1><strong>Importante:</strong></h1>
                  <h1 style={{fontWeight:500}}>Os agendamentos terão uma carência de 10 minutos, podendo ser realizada uma nova cobrança caso esta tolerância seja ultrapassada.</h1>
                </div>
              </Group>
              <Group>
                <Individual>

                </Individual>
                <BContainer>
                  <button>Avançar</button>
                </BContainer>
              </Group>
          </Form>
        </FormContainer>
      </Container>
      <Footer/>
    </MainWraper>
  )
}
export default FirstStep;