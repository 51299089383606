/*eslint-disable */
import React, {useEffect, useState} from 'react'
import { useStep } from 'react-hooks-helper'

import FirstStep from './FirstStep/index'
import SecondStep from './SecondStep'
import Review from './Review'
import SchedulingConfirmation from './SchedulingConfirmation'
// import api from 'services/cepApi'
import api from 'services/api'
import { object } from 'yup'

const steps = [
  { id: 'CPFirstStep'},
  { id: 'CPSecondStep'},
  { id: 'CPReview'},
  { id: 'CPConfirmation'}  
]

// const defaultData = {
//   reference,
//   date,
//   time,
//   carPlate,
//   driverName,
//   dock,
//   vehicle,
//   hiredTime,
//   forkLift,
//   paletTruck,
// }

const CParkMultiStep = ({images}) =>{
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate()+1)

  const [prices, setPrices] = useState()

  // function roundToHour(date) {
  //   const p = 60 * 60 * 1000; // milliseconds in an hour
  //   return new Date(Math.round(date.getTime() / p ) * p);
  // }

  useEffect(()=>{
    async function fetchPrices(){
      try{

        const {data:{data}} = await api.get('/prices')
        let a = data.reduce((obj, item) => ({...obj, [Object.keys(item)[1]]: item.[Object.keys(item)[1]]}), {})
        console.log(a)
        if(data)
        setPrices(a)
        
      }catch(err){}
    }
    fetchPrices()
  },[])
  
  const [reference, setReference] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [carPlate, setCarPlate] = useState('')
  const [driverName, setDiverName] = useState('')
  const [hiredTime, setHiredTime] = useState('')
  const [dock, setDock] = useState('')
  const [vehicle, setVehicle] = useState('')

  const [forkLift, setForkLift] = useState(false)
  const [paletTruck, setPaletTruck] = useState(false)

  const [transaction, setTransaction] = useState({})

  const {step, navigation} = useStep({ initialStep: 0, steps })
  const {id} = step
  
  const props = {  
    navigation,
    prices,
    reference, setReference,
    startDate, setStartDate,
    startTime, setStartTime,
    carPlate, setCarPlate,
    driverName, setDiverName,
    hiredTime, setHiredTime,
    vehicle, setVehicle,
    dock, setDock,
    forkLift, setForkLift,
    paletTruck, setPaletTruck,
    transaction, setTransaction
  }

  switch(id){
    case 'CPFirstStep':
      return <FirstStep {...props}/>
    case 'CPSecondStep':
      return <SecondStep {...props}/>
    case 'CPReview':
      return <Review {...props} />
    case 'CPConfirmation':
      return <SchedulingConfirmation {...props} />
    default:
      return null
  }

}
export default CParkMultiStep