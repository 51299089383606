import React from 'react'
import styled from 'styled-components'
import wpImg from './../assets/img/Banner-solucoes-img.jpg';

const Section7Wrapper = styled.section`
  height: 480px;
  margin: 0;
  background: ${props => `url(${props.background})`};
  background-position: top;
  background-size: cover;
  transform: scaleX(-1);
  @media only screen and (max-width: 992px) {
    height: 30vh;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(17,39,74,0.3);
    @media only screen and (max-width: 992px) {
      background: rgba(17,39,74,0.7);  
    }
  }

  .left-content {
    position: absolute;
    right: 6rem;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--primary-color-10);
    padding: 1rem 18px 1rem 60px;
    transform: scaleX(-1);
    text-align: center;
    span {
      font-weight: 600;
      font-size: 80px;
      line-height: 1;
      @media only screen and (max-width: 992px) {
        font-size: 48px;
        width: 100%;
        text-align: center;
      }
    }
    @media only screen and (max-width: 992px) {
      right: 2rem;
      max-width: calc(100vw - 4rem);
    }
    @media only screen and (max-width: 600px) {
      right: 0;
    }
  }
`

export default function Section7() {
  return (
    <Section7Wrapper className="row" background={wpImg}>
      <div className="col-lg-5 col-sm-12 left-content">
        <span>Soluções pensadas para você</span>
      </div>
    </Section7Wrapper>
  )
}
